/*jslint eqeq: true*/
import React, { Component} from 'react';
import InquiryService from '../service/InquiryService';
import CompanyService from '../service/CompanyService';
import {formatDate} from '../components/Utils';
import Address from '../components/Address.js';
import InquiriesDetailsIdentificationRegistrations from './InquiriesDetailsIdentificationRegistrations.js'
import InquiriesDetailsIdentificationRegistrationChange from './InquiriesDetailsIdentificationRegistrationChange.js'
import InquiriesDetailsIdentificationContactDetails from './InquiriesDetailsIdentificationContactDetails.js'
import DateComponent from '../components/DateComponent';
import { Translation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import FormattedNumberComponent from '../components/FormattedNumberComponent';
import { Toast } from 'primereact/toast';

class InquiriesDetailsBusinesssIdentification extends Component {
    constructor(props){
        super(props);
        this.state = {
           registrationInfo : this.props.registration_info,
           registrationChanges : this.props.registration_changes,
           companyInfo: this.props.company_info,
           contactInfo: this.props.contact_info,
           business: this.props.business,
           countries : [],
           regLegalForms: [],
           addressProvided: null,
           report: this.props.report,
           toast: this.props.toast,
           hideNameComments: this.props.report.name_same_as_order == true,
           hideAddressComments: this.props.report.address_same_as_order == true,
           orderInfo: this.props.orderInfo
        }
        this.inquiryService = new InquiryService();
        this.companyService = new CompanyService();
        this.inquiryService.getRegLegalForms().then(data=>{
          this.setState({regLegalForms: data})
        })
        this.inquiryService.getOrder(this.props.orderInfo.id).then(data=>{
            this.setState({addressProvided : this.readAddress(data.address), orderInfo : data });
        })



        //this.handleRegChange = this.handleRegChange.bind(this);
        //this.handleAddRegistrationData = this.handleAddRegistrationData.bind(this);


        this.handleRegInfoChange = this.handleRegInfoChange.bind(this);
        this.handleAddBranch = this.handleAddBranch.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.handleBranchesCommentsChange = this.handleBranchesCommentsChange.bind(this);
        this.handleReportInfoChange = this.handleReportInfoChange.bind(this);
        this.loadLegalAddress = this.loadLegalAddress.bind(this);
        this.loadHeadQuarters = this.loadHeadQuarters.bind(this);
        this.handleDeleteBranch = this.handleDeleteBranch.bind(this);
        this.initFieldtoEmpty = this.initFieldtoEmpty.bind(this);
    }

    initFieldtoEmpty(val){
      return (!val) ? "" : val
    }
    readAddress(address){
      if(!address){
        return '';
      }
      let str = '';
      if(address.full_address){
        str += address.full_address + '\n';
      } else {
        str += address.street + '\n';
        str += address.city +  "," + address.state + " " + address.postal_code + '\n';
      }
      str += "Country : " + address.country.name + '\n';
      str += 'Comments On Address ' +  (  address.comments ? ': '+ address.comments : '');
      return str;
    }

  

    loadLegalAddress(field){
      let registrations = sessionStorage.getItem('ses_registration_info_' + this.props.report.id)
      if (registrations === null){
        return;
      }
      let registrationsJSON = JSON.parse(registrations)

      if(registrationsJSON.registrations.length == 0){
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please add a legal address in the registration area"});
        return;
      }
      let companyInfo = this.state.companyInfo
      let domesticReg = registrationsJSON.registrations.find(reg => reg.type == true)
      if (!domesticReg){
        this.state.toast.current.show({severity:'error', summary:'Aborted !', detail: "No Domestic Registration Found. Please Add One and Try Again"});
        return;
      }
      //companyInfo[field] = registrationsJSON.registrations[0].address
      companyInfo[field] = domesticReg.address
      this.setState({companyInfo: companyInfo}, function(){
        sessionStorage.setItem('ses_company_info_' + this.props.report.id, JSON.stringify(companyInfo));
        this.state.toast.current.show({severity:'info', summary:'Loaded !', detail: "Address Information Loaded"});
      })

    }
    loadHeadQuarters(field){
      let headquarter = sessionStorage.getItem('ses_company_info_' + this.props.report.id)
      if (headquarter === null){
        return;
      }
      let headquarterJSON = JSON.parse(headquarter)
      console.log("JS",headquarterJSON)

      if(headquarterJSON.headquarters == null){
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please add a headquarter address in the locations area"});
        return;
      }
      let companyInfo = this.state.companyInfo
      companyInfo[field] = headquarterJSON.headquarters
   
      this.setState({companyInfo: companyInfo}, function(){
        sessionStorage.setItem('ses_company_info_' + this.props.report.id, JSON.stringify(companyInfo));
        this.state.toast.current.show({severity:'info', summary:'Loaded !', detail: "Address Information Loaded"});
      }) 
    }

    handleReportInfoChange(field, event){
      if(field == "name_same_as_order"){
        this.setState({hideNameComments: event.target.value})
      }
      if(field == "address_same_as_order"){
        this.setState({hideAddressComments: event.target.value})
      }
      let report = this.state.report;
      report[field] = event.target.value;
      this.setState({report: report})
    }
    componentDidMount(){
      if(!sessionStorage.getItem('ses_countries')){
        this.inquiryService.getCountries().then(data =>{
          console.log("Loaded country from api" , data)
          this.setState({countries: data})
          sessionStorage.setItem('ses_countries',JSON.stringify(data))
        });
      }else{
        this.setState({countries : JSON.parse(sessionStorage.getItem('ses_countries')) })  
      }


      const registrationInfo = sessionStorage.getItem('ses_registration_info_' + this.props.report.id);
      if (registrationInfo === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_registration_info_' + this.props.report.id, JSON.stringify(this.state.registrationInfo));
      } else{
          this.setState({registrationInfo : JSON.parse(registrationInfo)})
      }
      const registrationChanges = sessionStorage.getItem('ses_registration_changes_' + this.props.report.id);
      if (registrationChanges === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_registration_changes_' + this.props.report.id, JSON.stringify(this.state.registrationChanges));
      } else{
          this.setState({registrationChanges : JSON.parse(registrationChanges)})
      }
      const contactInfo = sessionStorage.getItem('ses_contact_info_' + this.props.report.id);
      if (contactInfo === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_contact_info_' + this.props.report.id, JSON.stringify(this.state.contactInfo));
      } else{
          this.setState({contactInfo : JSON.parse(contactInfo)})
      }

      const companyInfo = sessionStorage.getItem('ses_company_info_' + this.props.report.id);
      if (companyInfo === null) {
          // banks not set, so we set them from the state of the component
          sessionStorage.setItem('ses_company_info_' + this.props.report.id, JSON.stringify(this.state.companyInfo));
      } else{
          this.setState({companyInfo : JSON.parse(companyInfo)})
      }
    }


    handleRegInfoChange(field,e){
        const registration_info_arr = this.state.registrationInfo;
        registration_info_arr[field] = e.target.value;
        const registrationInfo = sessionStorage.getItem('ses_registration_info_' + this.props.report.id);
        if (registrationInfo != null) {
            // banks not set, so we set them from the state of the component
            let registrationInfoJSON = JSON.parse(registrationInfo)
            registration_info_arr.registrations = registrationInfoJSON.registrations
        }

        this.setState({registrationInfo : registration_info_arr});
        sessionStorage.setItem('ses_registration_info_'+this.props.orderInfo.report, JSON.stringify(registration_info_arr));
    }


    handleAddBranch(e){
        e.preventDefault();
        let business = this.state.business
        const branch_arr = business.branches;
       
        if(branch_arr.length == 0 || branch_arr[branch_arr.length - 1].address.full_address){
          business.branches.push({"address": {"country": {"name": "United States"}}});
          this.setState({business: business}, function(){
            sessionStorage.setItem('ses_business_' + this.props.report.id, JSON.stringify(business));
          });
        } else {
            this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter full branch address"});            
        }


    }


    handleBranchChange(field, i, e){
      let business = this.state.business
      let branches = business.branches
      branches[i]["address"][field] = e.target.value
      this.setState({business: business}, function(){
        sessionStorage.setItem('ses_business_' + this.props.report.id, JSON.stringify(business));
      });
    }

    handleBranchesCommentsChange(e){
      let business = this.state.business
      business.branches_comments = e.target.value;
      this.setState({business: business}, function(){
        sessionStorage.setItem('ses_business_' + this.props.report.id, JSON.stringify(business));
      });
    }

    handleDeleteBranch(indx, event){
      let business = this.state.business
      let branches = business.branches
      branches.splice(indx,  1);
      business.branches = branches
      this.setState({business: business }, function(){
        sessionStorage.setItem('ses_business_' + this.props.report.id, JSON.stringify(business));
      })
    }

    handleCompanyInfoChange(parentField, childField, event){
      let companyInfo = this.state.companyInfo
      if(childField){
        if(!companyInfo[parentField]){
          companyInfo[parentField] = {}
        }
        companyInfo[parentField][childField] = event.target.value
      } else {
        companyInfo[parentField] = event.target.value
      }
      this.setState({companyInfo: companyInfo}, function(){
        sessionStorage.setItem('ses_company_info_' + this.props.report.id, JSON.stringify(this.state.companyInfo));
      });
    }

    render(){
        const options = [
          {label: 'YES', value: true},
          {label: 'NO', value: false},
          {label: 'Unconfirmed', value: null}
        ];
        const properties = [
          {label: 'OWNED', value: 'OWNED'},
          {label: 'RENTED', value: 'RENTED'},
          {label: 'LEASED', value: 'LEASED'},
          {label: 'NA', value: 'NA'}
        ]
        const telephones  = this.state.contact_info?.telephones?.split(",");
        const faxes = this.state.contact_info?.faxes?.split(",");
        const emails = this.state.contact_info?.emails?.split(",");
        const websites = this.state.contact_info?.websites?.split(",");
        const social_media = this.state.contact_info?.social_media?.split(",");
        return(
          <div className="row">
            <Toast ref={this.state.toast} />
            <div className="col-12">
                <div  className="row">
                    <div className="col-8">
                            <div className="form-group">
                                <span className="form-title">{<Translation>{ t => <span>{t('NAMES')} </span>}</Translation>}</span>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <label className="form-field-bold">{<Translation>{ t => <span>{t('NAME_IN_THE_ORDER')} </span>}</Translation>}</label>
                                    </div>
                                    <div className="col-sm-9">
                                        {this.props.orderInfo.company_name}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row" >
                                    <div className="col-sm-3">
                                        <label className="form-field-bold">{<Translation>{ t => <span>{t('REGISTERED_NAME')} </span>}</Translation>}</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="row">
                                                <div className="col-sm-6" >
                                                    <input  type="text" onChange={(e) => this.handleCompanyInfoChange("registred_name", null, e)} value={this.initFieldtoEmpty(this.state.companyInfo.registred_name)} name="registred_name" className="form-control form-control-sm"/>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row" >
                                    <div className="col-sm-3">
                                        <label className="form-field-bold">{<Translation>{ t => <span>{t('TRADE_NAMES')} </span>}</Translation>}</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="row">
                                                <div className="col-sm-6" >
                                                    <input onChange={(e) => this.handleCompanyInfoChange("trade_names", null, e)}  type="text" name="trade_names" value={this.initFieldtoEmpty(this.state.companyInfo.trade_names)} className="form-control form-control-sm"/>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row" >
                                    <div className="col-sm-3">
                                        <label className="form-field-bold">{<Translation>{ t => <span>{t('PREVIOUS_NAMES')} </span>}</Translation>}</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="row">
                                                <div className="col-sm-6" >
                                                    <input  type="text" onChange={(e) => this.handleCompanyInfoChange("previous_names", null, e)} name="trade_names" value={this.initFieldtoEmpty(this.state.companyInfo.previous_names)} className="form-control form-control-sm"/>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                    <div className="col-4">
                        <div className="form-group">
                                <div className="row" >
                                    <div className="col-sm-6">
                                        <label className="form-field-bold">{<Translation>{ t => t('TRADENAME_PROVIDED')}</Translation>}</label>
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-sm-11">
                                        <input disabled type = "text" className="form-control form-control-sm" value = {this.initFieldtoEmpty(this.state.orderInfo.trade_name)} />
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-sm-6">
                                        <label className="form-field-bold">{<Translation>{ t => t('REGISTRATION_NUMBER_PROVIDED')}</Translation>}</label>
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-sm-11">
                                        <input disabled type = "text" className="form-control form-control-sm" value = {this.initFieldtoEmpty(this.state.orderInfo.registration_number)} />
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-sm-6">
                                        <label className="form-field-bold">{<Translation>{ t => t('NAME_SAME_AS_ORDER')}</Translation>}</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="row">
                                            <div className="col-sm-12">
                                              <SelectButton value={this.state.report.name_same_as_order} options={options} onChange={(e) => this.handleReportInfoChange("name_same_as_order", e)}></SelectButton>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-11">
                                      <textarea hidden={this.state.hideNameComments} className="form-control form-text-comments" rows="2"
                                        value = {this.initFieldtoEmpty(this.state.report.name_comments)} onChange={(e) => this.handleReportInfoChange("name_comments", e)}></textarea>
                                    </div>
                                </div>

                        </div>
                    </div>
                </div>
              </div>



              <div className="col-12">
                <div className="form-hr"></div>
                <InquiriesDetailsIdentificationRegistrations order_legal_name = {this.state.orderInfo?.ordered_by.user.company.legal_name} registrations = {this.state.registrationInfo.registrations} report_id = {this.props.report.id}
                            last_annual_report = {this.state.registrationInfo.last_annual_report} comments = {this.state.registrationInfo.comments} toast = {this.state.toast} />
              </div>

              <div className="col-12">
                <div className="form-hr-sm"></div>
                <div className="form-group">
                    <div className="row" >
                         <div className="col-sm-1">
                            <label className="form-field-bold">{<Translation>{ t => <span>{t('START_DATE')} </span>}</Translation>}</label>
                        </div>
                        <div className="col-sm-2">
                            <DateComponent date = {this.state.registrationInfo.start_date} update_date = {(e) => this.handleRegInfoChange("start_date", e)}/>
                        </div>
                    </div>  
                </div>
                <div className="form-group">
                    <div className="row" >
                         <div className="col-sm-1">
                            <label className="form-field-bold">{<Translation>{ t => <span>{t('LEGAL_FORM')} </span>}</Translation>}</label>
                        </div>
                        <div className="col-sm-9">
                            <div className="col-sm-3">
                            <Dropdown value = {this.state.registrationInfo.legal_form} options={this.state.regLegalForms}
                                    onChange={(e) => this.handleRegInfoChange("legal_form",e)} optionLabel="name" placeholder="Select a Form" filter filterBy="name" style = {{ "width" : "14rem"}}/>
                            </div>
                        </div>
                    </div>
                </div>
              </div>

              <div className="col-12">
                <InquiriesDetailsIdentificationRegistrationChange registration_changes = {this.state.registrationChanges.changes} report_id = {this.props.report.id} toast = {this.state.toast} />
              </div>

              <div className="col-12">
                <div className="form-hr"></div>
                <div className="row">
                    <div className = "col-8">
                      <div className="form-group">
                        <span className="form-title">{<Translation>{ t => <span>{t('LOCATIONS')} </span>}</Translation>}</span>
                      </div>
                      <br></br>
                      <div className="form-group">
                        <div className = "col-10">
                            <div className="row" >
                                 <div className="col-sm-2">
                                    <label className="form-field-bold"><Translation>{ t => <span>{t('HEADQUARTERS')} </span>}</Translation></label>
                                </div>
                                <div className="col-sm-5">
                                    <Button style={{height:'75%'}}  className="p-button-raised p-button-rounded" onClick={(e) => this.loadLegalAddress("headquarters")}>
                                      <label className="form-field-bold"><Translation>{ t => <span>{t('SAMES_AS_LEGAL_ADDRESS')} </span>}</Translation></label>
                                    </Button>
                               </div>
                            </div>
                            <div className="row" >
                                 <div className="col-sm-3">
                                    <label className="form-field-bold"><Translation>{ t => <span>{t('STREET')} </span>}</Translation></label>
                                </div>
                                <div className="col-sm-3">
                                   <textarea  rows="2" onChange={(e) => this.handleCompanyInfoChange("headquarters", "street", e)} name="street" value={this.state.companyInfo.headquarters?.street} className="form-control form-control-sm"/>
                               </div>
                            </div>
                            <div className="row" >
                                 <div className="col-sm-3">
                                    <label className="form-field-bold"><Translation>{ t => <span>{t('POSTCODE') + "/"+ t('PO_BOX')} </span>}</Translation></label>
                                </div>
                                <div className="col-sm-3">
                                   <input  type="text" onChange={(e) => this.handleCompanyInfoChange("headquarters", "postal_code", e)} name="zip" value={this.state.companyInfo.headquarters?.postal_code} className="form-control form-control-sm"/>
                               </div>
                            </div>
                            <div className="row" >
                                 <div className="col-sm-3">
                                    <label className="form-field-bold"><Translation>{ t => <span>{t('CITY')} </span>}</Translation></label>
                                </div>
                                <div className="col-sm-3">
                                   <input  type="text" onChange={(e) => this.handleCompanyInfoChange("headquarters", "city", e)} name="city" value={this.state.companyInfo.headquarters?.city} className="form-control form-control-sm"/>
                               </div>
                            </div>
                            <div className="row" >
                                 <div className="col-sm-3">
                                    <label className="form-field-bold"><Translation>{ t => <span>{t('STATE') + "/" + t('REGION')} </span>}</Translation></label>
                                </div>
                                <div className="col-sm-3">
                                   <input  type="text" onChange={(e) => this.handleCompanyInfoChange("headquarters", "state", e)} name="state" value={this.initFieldtoEmpty(this.state.companyInfo.headquarters?.state)} className="form-control form-control-sm"/>
                               </div>
                            </div>
                            <div className="row" >
                                 <div className="col-sm-3">
                                    <label className="form-field-bold"><Translation>{ t => <span>{t('COUNTRY')} </span>}</Translation></label>
                                </div>
                                <div className="col-sm-3">{this.props.countries}
                                    <Dropdown value = {this.state.companyInfo.headquarters?.country} options={this.state.countries} onChange={(e)=>this.handleCompanyInfoChange("headquarters", "country", e)} optionLabel="name" placeholder="Select a Country" filter filterBy="name" />
                                  
                               </div>
                            </div>
                            <div className="row" >
                                 <div className="col-sm-3">
                                    <label className="form-field-bold"><Translation>{ t => <span>{t('SURFACEAREA')} </span>}</Translation></label>
                                </div>
                                <div className="col-sm-3">
                                  <div className = "row">
                                    <div className="col-4">
                                      <FormattedNumberComponent name = "value" type='input' update_value={(e)=>this.handleCompanyInfoChange("headquarters", "surface_area_of_hq", e)}
                                        value={this.initFieldtoEmpty(this.state.companyInfo.headquarters?.surface_area_of_hq)} />
                                    </div>
                                    <div className="col-8">
                                      <SelectButton value={this.state.companyInfo.headquarters?.surface_area_unit_of_hq} options={['FT2', 'M2']}
                                        onChange={(e) => this.handleCompanyInfoChange("headquarters", "surface_area_unit_of_hq", e)} style = {{ "fontSize": "2px"}}/>
                                    </div>
                                  </div>


                               </div>
                            </div>
                            <div className="row" >
                                 <div className="col-sm-3">
                                    <label className="form-field-bold"><Translation>{ t => <span>{t('PROPERTY')} </span>}</Translation></label>
                                </div>
                                <div className="col-sm-3">
                                   <Dropdown value = {this.state.companyInfo.headquarters?.property} options = {properties} onChange={(e) => this.handleCompanyInfoChange("headquarters", "property", e)}  optionLabel="label" showClear />
                               </div>
                            </div>
                            <div className="row" >
                                 <div className="col-sm-3">
                                    <label className="form-field-bold"><Translation>{ t => <span>{t('PREMISES_COMPRISE_OF')} </span>}</Translation></label>
                                </div>
                                <div className="col-sm-3">
                                   <textarea rows="2"  type="text" name="premises" onChange={(e) => this.handleCompanyInfoChange("headquarters", "premises_comprise_of", e)} value = {this.initFieldtoEmpty(this.state.companyInfo.headquarters?.premises_comprise_of)}  className="form-control form-control-sm"/>
                               </div>
                            </div>
                            <div className="row" >
                                <div className="col-sm-6">
                                   <textarea  type="text" name="comments" onChange={(e) => this.handleCompanyInfoChange("headquarters", "comments", e)}   className="form-control form-control-sm" row = "3" placeholder = "comments" value = {this.initFieldtoEmpty(this.state.companyInfo.headquarters?.comments)}/>
                               </div>
                            </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className = "col-10">
                          <div className="row" >
                               <div className="col-sm-2">
                                  <label className="form-field-bold"><Translation>{ t => <span>{t('MAILING')} </span>}</Translation></label>
                              </div>
                              <div className="col-sm-5">
                                  <Button style={{height:'75%'}}  className="p-button-raised p-button-rounded" onClick={(e) => this.loadLegalAddress("mailing_address")}>
                                    <label className="form-field-bold"><Translation>{ t => <span>{t('SAMES_AS_LEGAL_ADDRESS')} </span>}</Translation></label>
                                  </Button>&nbsp;&nbsp;
                                  <Button style={{height:'75%'}}  className="p-button-raised p-button-rounded" onClick={(e) => this.loadHeadQuarters("mailing_address")}>
                                    <label className="form-field-bold"><Translation>{ t => <span>{t('SAME_AS_HEADQUARTERS')} </span>}</Translation></label>
                                  </Button>
                             </div>
                          </div>
                          <div className="row" >
                               <div className="col-sm-3">
                                  <label className="form-field-bold"><Translation>{ t => <span>{t('STREET')} </span>}</Translation></label>
                              </div>
                              <div className="col-sm-3">
                                 <textarea  rows="2" onChange={(e) => this.handleCompanyInfoChange("mailing_address", "street", e)} name="street" value={this.initFieldtoEmpty(this.state.companyInfo.mailing_address?.street)} className="form-control form-control-sm"/>
                             </div>
                          </div>
                          <div className="row" >
                               <div className="col-sm-3">
                                  <label className="form-field-bold"><Translation>{ t => <span>{t('POSTCODE') + "/"+ t('PO_BOX')} </span>}</Translation></label>
                              </div>
                              <div className="col-sm-3">
                                 <input  type="text" onChange={(e) => this.handleCompanyInfoChange("mailing_address", "postal_code", e)} name="zip" value={this.initFieldtoEmpty(this.state.companyInfo.mailing_address?.postal_code)} className="form-control form-control-sm"/>
                             </div>
                          </div>
                          <div className="row" >
                               <div className="col-sm-3">
                                  <label className="form-field-bold"><Translation>{ t => <span>{t('CITY')} </span>}</Translation></label>
                              </div>
                              <div className="col-sm-3">
                                 <input  type="text" onChange={(e) => this.handleCompanyInfoChange("mailing_address", "city", e)} name="city" value={this.state.companyInfo.mailing_address?.city} className="form-control form-control-sm"/>
                             </div>
                          </div>
                          <div className="row" >
                               <div className="col-sm-3">
                                  <label className="form-field-bold"><Translation>{ t => <span>{t('STATE') + "/"+ t('REGION')} </span>}</Translation></label>
                              </div>
                              <div className="col-sm-3">
                                 <input  type="text" onChange={(e) => this.handleCompanyInfoChange("mailing_address", "state", e)} name="state" value={this.state.companyInfo.mailing_address?.state} className="form-control form-control-sm"/>
                             </div>
                          </div>
                          <div className="row" >
                               <div className="col-sm-3">
                                  <label className="form-field-bold"><Translation>{ t => <span>{t('COUNTRY')} </span>}</Translation></label>
                              </div>
                              <div className="col-sm-2">
                                  <Dropdown value = {this.state.companyInfo.mailing_address?.country} options={this.state.countries} onChange={(e)=>this.handleCompanyInfoChange("mailing_address", "country", e)} optionLabel="name" placeholder="Select a Country" filter filterBy="name" />
                             </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                                <div className="row" >
                                    <div className="col-sm-6">
                                        <label className="form-field-bold">{<Translation>{ t => t('ADDRESS_PROVIDED')}</Translation>}</label>
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-sm-11">
                                        <textarea rows="3" disabled type = "text" className="form-control form-control-sm" value = {this.state.addressProvided} />
                                    </div>
                                </div><br/>
                                <div className="row" >
                                    <div className="col-sm-6">
                                        <label className="form-field-bold">{<Translation>{ t => t('ADDRESS_SAME_AS_ORDER')}</Translation>}</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="row">
                                            <div className="col-sm-12">
                                              <SelectButton value={this.state.report.address_same_as_order} options={options} onChange={(e) => this.handleReportInfoChange("address_same_as_order", e)}></SelectButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                  <div className="col-11">
                                    <textarea hidden={this.state.hideAddressComments} className="form-control form-text-comments" rows="3"
                                      value = {this.state.report.address_comments} onChange={(e) => this.handleReportInfoChange("address_comments", e)}></textarea>
                                  </div>
                                </div>
                        </div>
                    </div>
                    </div>
              <div className="form-group">
                <div className="row" >
                     <div className="col-sm-2">
                        <label className="form-title"><Translation>{ t => <span>{t('BRANCHES') } </span>}</Translation></label>
                    </div>
                    <div className = "col-sm-2">
                      <Button icon="pi pi-plus" onClick={this.handleAddBranch} className="p-button-square p-button-secondary" />
                    </div>
                </div>
                <div className="row" >
                    {
                      this.state.business.branches.map((branch, i) => (
                        <div className="col-sm-2">
                           <textarea row = "2" className="form-control form-control-sm" placeholder = {"Branch Address " + (i + 1)}
                            value = {branch.address.full_address}  onChange={(e)=>this.handleBranchChange("full_address", i, e)}/>
                           {/*<Dropdown value = {branch.address.country} options={this.state.countries}
                              onChange={(e)=>this.handleBranchChange("country", i, e)}
                                optionLabel="name" placeholder="Select a Country" filter filterBy="name" />*/}
                                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
                                  onClick = {(e) => this.handleDeleteBranch(i, e)} value = {null}  />
                       </div>
                      ))
                    }
                </div>
                <br></br>
                <div className = "row">
                  <div className="col-sm-6">
                    <textarea row = "3" className="form-control form-control-sm" placeholder = "comments"
                      value = {this.state.business.branches_comments} onChange = {this.handleBranchesCommentsChange} style = {{'resize': 'both'}}/>
                  </div>
                </div>
              </div>
              <div className="form-hr"></div>
              <InquiriesDetailsIdentificationContactDetails report = {this.props.report} telephones = {this.state.contactInfo.telephones} contact_info = {this.state.contactInfo} report_id = {this.props.report.id} order_info = {this.state.orderInfo} toast={this.props.toast} />

            </div>
          </div>
        )
    }

}
export default InquiriesDetailsBusinesssIdentification
