import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import InquiryService from '../service/InquiryService';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { SplitButton } from 'primereact/splitbutton';
//import { Dialog } from 'primereact/dialog';
import {dataTable} from '../components/Utils';
import FormattedNumberComponent from '../components/FormattedNumberComponent';
class InquiriesDetailsPaymentBehavior extends Component {
  constructor(props){
    super(props);
    var max = new Date().getFullYear()
    var min = max - 50
    let years = []
    for (var i = max; i >= min; i--) {
        years.push(i.toString())
    }
    this.addPayButtons = [
      {
          label: 'Add Monthly',
          icon: 'pi pi-plus',
          command: () => {
              this.handleAddPayment(true);
          }
      },
      {
          label: 'Add Quarterly',
          icon: 'pi pi-plus',
          command: () => {
             this.handleAddPayment(false);
          }
      },
    ];
    this.state = {
      paymentBehavior: this.props.payment_behavior,
      ActiveCreditBurea : false,
      currencies: [],
      years: years,
      months: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      paymentTrendPreview: false,
      MonthlyTrends : "",
      QuarterlyTrends : ""
    }
    this.inquiryService = new InquiryService();
    this.toggleCreditBurea = this.toggleCreditBurea.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.handleCreditBureauChange = this.handleCreditBureauChange.bind(this);
    this.handleAddPayment = this.handleAddPayment.bind(this);
    this.monthBodyTemplate = this.monthBodyTemplate.bind(this);
    this.yearBodyTemplate = this.yearBodyTemplate.bind(this);
    this.threeMonthsBodyTemplate = this.threeMonthsBodyTemplate.bind(this);
    this.twoMonthsBodyTemplate = this.twoMonthsBodyTemplate.bind(this);
    this.moreThanThreeMonthsBodyTemplate = this.moreThanThreeMonthsBodyTemplate.bind(this);
    this.oneMonthBodyTemplate = this.oneMonthBodyTemplate.bind(this);
    this.onTimeBodyTemplate = this.onTimeBodyTemplate.bind(this);
    this.dbtBodyTemplate = this.dbtBodyTemplate.bind(this);
    this.balanceBodyTemplate = this.balanceBodyTemplate.bind(this);
    this.balanceCurrencyBodyTemplate = this.balanceCurrencyBodyTemplate.bind(this);
    this.handlePaymentBehaviorChange = this.handlePaymentBehaviorChange.bind(this);
    this.handleDeletePayment = this.handleDeletePayment.bind(this);
    this.DeleteBodyTemplate = this.DeleteBodyTemplate.bind(this);
    this.viewExperian = this.viewExperian.bind(this);
    this.inquiryService.getCurrencies().then(data => {
      this.setState({currencies: data})
    })
  }
  componentDidMount(){
    const paymentBehavior = JSON.parse(sessionStorage.getItem("ses_payment_behavior_"+ this.props.report_id));
    this.setState({paymentBehavior : paymentBehavior});
  }
  viewExperian(event){
    this.setState({paymentTrendPreview: true});
    let ses_trades = JSON.parse(sessionStorage.getItem("ExperianData_trades_"+this.props.report_id));
    
  
   const monthly_columns = [
    {field: 'date', header: 'Date'},
    {field: 'totalAccountBalance.amount', header: 'Balance'},
    {field: 'currentPercentage', header: 'Current %'},
    {field: 'dbt', header: 'DBT'},
    {field: 'dbt30', header: '1-30'},
    {field: 'dbt60', header: '31-60'},
    {field: 'dbt90', header: '61-90'},
    {field: 'dbt91Plus', header: '91+'},
  ];

    let MonthlyTrends =  dataTable("Monthly Trends", monthly_columns, ses_trades.tradePaymentTrends.monthlyTrends);
    let QuarterlyTrends =  dataTable("Quarterly Trends", monthly_columns, ses_trades.tradePaymentTrends.quarterlyTrends );
 
    this.setState({MonthlyTrends : MonthlyTrends});
    this.setState({QuarterlyTrends : QuarterlyTrends});

  }
  toggleCreditBurea(){
      const currentState = this.state.ActiveCreditBurea;
      this.setState({ ActiveCreditBurea: !currentState });
  }

  dataRefresh(){
    sessionStorage.setItem('ses_payment_behavior_' + this.props.report_id, JSON.stringify(this.state.paymentBehavior));
  }

  handleCreditBureauChange(e){
    let paymentBehavior = this.state.paymentBehavior
    paymentBehavior["credit_bureau_information"] = e.target.value
    this.setState({paymentBehavior: paymentBehavior}, function(){
      this.dataRefresh();
    })
  }

  handlePaymentBehaviorChange(field, e){
    let paymentBehavior = this.state.paymentBehavior
    paymentBehavior[field] = e.target.value
    this.setState({paymentBehavior: paymentBehavior}, function(){
      this.dataRefresh();
    })
  } 
  handlePaymentChange(i, field, e , is_monthly){
    let paymentBehavior = this.state.paymentBehavior
    let monthlyTrends = Array.isArray(paymentBehavior.payments) ? paymentBehavior.payments.filter(monthly=> monthly.is_monthly == 1) : [];
    let quarterlyTrends = Array.isArray(paymentBehavior.payments) ? paymentBehavior.payments.filter(monthly=> monthly.is_monthly == 0) : [];
    if(is_monthly){
      monthlyTrends[i][field] = e.target.value
    } else {
      quarterlyTrends[i][field] = e.target.value
    }
    paymentBehavior.payments = monthlyTrends.concat(quarterlyTrends);
    this.setState({paymentBehavior: paymentBehavior}, function(){
       this.dataRefresh();
    })
  }
  handleDeletePayment(indx, event){
    let paymentBehavior = this.state.paymentBehavior
    paymentBehavior.payments.splice(indx,  1);
    this.setState({paymentBehavior: paymentBehavior }, function(){
      this.dataRefresh();
    })
  }
  handleAddPayment(isMonthly = true){
    let paymentBehavior = this.state.paymentBehavior
    paymentBehavior.payments.push({"month": "01", "year": "2000", "is_monthly" : isMonthly})
    this.setState({paymentBehavior: paymentBehavior}, function(){
      this.dataRefresh();
    })
    console.log(paymentBehavior);
  }

  monthBodyTemplate(rowData, props){
    return (
        <React.Fragment>
          <Dropdown value = {rowData.month} options={this.state.months} name="month"
            onChange={(e) => this.handlePaymentChange(props.rowIndex, "month", e , rowData.is_monthly)} placeholder="Select a Month"/>
        </React.Fragment>
    );
  }

  yearBodyTemplate(rowData, props){
    return (
        <React.Fragment>
          <Dropdown value = {rowData.year} options={this.state.years} name="year"
            onChange={(e) => this.handlePaymentChange(props.rowIndex, "year", e , rowData.is_monthly)} placeholder="Select a Year"/>
        </React.Fragment>
    );
  }

  moreThanThreeMonthsBodyTemplate(rowData, props){
    return (
        <React.Fragment>
          <input type="number" min = "0" value = {rowData.more_than_three_months_late}
          onChange ={(e) => this.handlePaymentChange(props.rowIndex, "more_than_three_months_late", e , rowData.is_monthly)} style={{'width': '30%', 'marginRight': '10px'}} />
          <i className = "pi pi-percentage" style={{'fontSize': '15px'}}></i>
        </React.Fragment>
    );
  }

  DeleteBodyTemplate(rowData, props){
    return (
        <React.Fragment>
          <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text"
            onClick = {(e) => this.handleDeletePayment(props.rowIndex, e)}  />
        </React.Fragment>
    );
  }

  threeMonthsBodyTemplate(rowData, props){
    return (
        <React.Fragment>
          <input type="number" min = "0" value = {rowData.three_months_late}
          onChange ={(e) => this.handlePaymentChange(props.rowIndex, "three_months_late", e , rowData.is_monthly)} style={{'width': '30%', 'marginRight': '10px'}} />
          <i className = "pi pi-percentage" style={{'fontSize': '15px'}}></i>
        </React.Fragment>
    );
  }

  twoMonthsBodyTemplate(rowData, props){
    return (
        <React.Fragment>
          <input type="number" min = "0" value = {rowData.two_months_late}
          onChange ={(e) => this.handlePaymentChange(props.rowIndex, "two_months_late", e , rowData.is_monthly)} style={{'width': '30%', 'marginRight': '10px'}} />
          <i className = "pi pi-percentage" style={{'fontSize': '15px'}}></i>
        </React.Fragment>
    );
  }

  oneMonthBodyTemplate(rowData, props){
    return (
        <React.Fragment>
          <input type="number" min = "0" value = {rowData.one_month_late}
          onChange ={(e) => this.handlePaymentChange(props.rowIndex, "one_month_late", e , rowData.is_monthly)} style={{'width': '30%', 'marginRight': '10px'}} />
          <i className = "pi pi-percentage" style={{'fontSize': '15px'}}></i>
        </React.Fragment>
    );
  }

  onTimeBodyTemplate(rowData, props){
    return (
        <React.Fragment>
          <input type="number" min = "0" value = {rowData.on_time}
          onChange ={(e) => this.handlePaymentChange(props.rowIndex, "on_time", e , rowData.is_monthly)} style={{'width': '30%', 'marginRight': '10px'}} />
          <i className = "pi pi-percentage" style={{'fontSize': '15px'}}></i>
        </React.Fragment>
    );
  }

  dbtBodyTemplate(rowData, props){
    return (
      <React.Fragment>
        <input type="number" style={{'width': '70px'}} value = {rowData.dbt} onChange ={(e) => this.handlePaymentChange(props.rowIndex, "dbt", e , rowData.is_monthly)} />
      </React.Fragment>
    )
  }

  balanceCurrencyBodyTemplate(rowData, props){
    return (
      <React.Fragment>
        <Dropdown value = {rowData.balance_currency} options={this.state.currencies} name="balance_currency" optionLabel="code"
          onChange={(e) => this.handlePaymentChange(props.rowIndex, "balance_currency", e , rowData.is_monthly)} placeholder="Select a Currency" filter/>
      </React.Fragment>
    )
  }

  balanceBodyTemplate(rowData, props){
    return (
      <React.Fragment>
         <FormattedNumberComponent update_value={(e) => this.handlePaymentChange(props.rowIndex, "balance", e , rowData.is_monthly)} value = {rowData.balance}/>
      </React.Fragment>
    )
  }
  balanceBody2Template(rowData, props){
    return (
      <React.Fragment>
         <FormattedNumberComponent update_value={(e) => this.handlePaymentChange(props.rowIndex, "balance", e , rowData.is_monthly)} value = {rowData.balance}/>
      </React.Fragment>
    )
  }
  onHidePreview(){
    this.setState({paymentTrendPreview : false});
  }

  renderPayments(){
    let paymentBehaviorArray = this.state.paymentBehavior;  
    if(this.state.paymentBehavior.payments.length === 0){
      paymentBehaviorArray = JSON.parse(sessionStorage.getItem("ses_payment_behavior_"+this.props.report_id));
    }
    let monthlyTrends = Array.isArray(paymentBehaviorArray.payments) ? paymentBehaviorArray.payments.filter(monthly=> monthly.is_monthly == 1) : [];
    //console.log("MT",monthlyTrends);
    let quarterlyTrends = Array.isArray(paymentBehaviorArray.payments) ? paymentBehaviorArray.payments.filter(monthly=> monthly.is_monthly == 0) : [];
   // console.log("QT",quarterlyTrends);
    return (
      <div>
      <div className="col-12" id="dataTable">
        <h6><Translation>{ t => <span>{t('MONTHLY_PAYMENT_TRENDS')} </span>}</Translation></h6>
        <DataTable ref={(el) => this.dt = el}
            value={monthlyTrends}
            className="p-datatable-striped"
            dataKey="id"
            rowHover
            //  selection={this.state.selectedName}
            // onSelectionChange={e => this.setState({selectedName: e.value})}
            paginator rows={10}
            emptyMessage="No Payments found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10,25,50]}
            globalFilter={this.state.globalFilter}>
            <Column header={<Translation>{ t => <span>{t('MONTH')} </span>}</Translation>} body={this.monthBodyTemplate}/>
            <Column header={<Translation>{ t => <span>{t('YEAR')} </span>}</Translation>} body={this.yearBodyTemplate}/>
            <Column header={<Translation>{ t => <span>{t('DBT')} </span>}</Translation>} body={this.dbtBodyTemplate}/>
            <Column header={<Translation>{ t => <span>{t('BALANCE')} </span>}</Translation>} body={this.balanceBodyTemplate}/>
            <Column header={<Translation>{ t => <span>{t('CURRENCY')} </span>}</Translation>} body={this.balanceCurrencyBodyTemplate}/>
            <Column header={<Translation>{ t => <span>{t('ON_TIME')} </span>}</Translation>} body={this.onTimeBodyTemplate}/>
            <Column header="1-30" body={this.oneMonthBodyTemplate}/>
            <Column header="31-60" body={this.twoMonthsBodyTemplate}/>
            <Column header="61-90" body={this.threeMonthsBodyTemplate}/>
            <Column header="91+" body={this.moreThanThreeMonthsBodyTemplate}/>
            <Column header="" body={this.DeleteBodyTemplate}/>
        </DataTable>
      </div>
      <div className="col-12" id="dataTable2">
      <h6><Translation>{ t => <span>{t('QUARTERLY_PAYMENT_TRENDS')} </span>}</Translation></h6>
      <DataTable ref={(el) => this.dt = el}
          value={quarterlyTrends}
          className="p-datatable-striped"
          dataKey="id2"
          rowHover
          //  selection={this.state.selectedName}
          // onSelectionChange={e => this.setState({selectedName: e.value})}
          paginator rows={10}
          emptyMessage={<Translation>{ t => <span>{t('NO_PAYMENTS_FOUND')}</span>}</Translation>}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10,25,50]}
          globalFilter={this.state.globalFilter}>
          <Column header={<Translation>{ t => <span>{t('MONTH')}</span>}</Translation>} body={this.monthBodyTemplate}/>
          <Column header={<Translation>{ t => <span>{t('YEAR')}</span>}</Translation>} body={this.yearBodyTemplate}/>
          <Column header={<Translation>{ t => <span>{t('DBT')}</span>}</Translation>} body={this.dbtBodyTemplate}/>
          <Column header={<Translation>{ t => <span>{t('BALANCE')}</span>}</Translation>} body={this.balanceBodyTemplate}/>
          <Column header={<Translation>{ t => <span>{t('CURRENCY')}</span>}</Translation>} body={this.balanceCurrencyBodyTemplate}/>
          <Column header={<Translation>{ t => <span>{t('ON_TIME')}</span>}</Translation>} body={this.onTimeBodyTemplate}/>
          <Column header="1-30" body={this.oneMonthBodyTemplate}/>
          <Column header="31-60" body={this.twoMonthsBodyTemplate}/>
          <Column header="61-90" body={this.threeMonthsBodyTemplate}/>
          <Column header="91+" body={this.moreThanThreeMonthsBodyTemplate}/>
          <Column header="" body={this.DeleteBodyTemplate}/>
      </DataTable>
      </div>
      </div>
    )
  }

  render(){
    const options = [{value : true, label: <Translation>{ t =>t('YES')}</Translation>}, {value : false, label: <Translation>{ t =>t('NO')}</Translation>}];
    return (
      <div>
      <div className="form-group">
          <div className="row" >
              <div className="col-sm-2">
                  <label className="form-field-bold text-blue">{<Translation>{ t => <span>{t('PAYMENT_BEHAVIOUR')} </span>}</Translation>}</label>
                  <SplitButton className='p-button-square p-button-secondary float-right' label="Add" icon="pi" model={this.addPayButtons}></SplitButton>
              </div>
              <div className='col-sm-7'></div>
              <div className='col-sm-3' style={{"textAlign" : "right","marginLeft":"-13px"}}>
                  {/*<Button icon="pi pi-eye" label = "View Experian Payment Trends"  onClick={(e) => this.viewExperian(e)} />
                  <Dialog  header = "Payment Trends" visible={this.state.paymentTrendPreview}   onHide={() => this.onHidePreview() }  breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '60vw'}}>
                      {this.state.MonthlyTrends}<br/>
                      {this.state.QuarterlyTrends}
    </Dialog> */}
              </div>
          </div>
      </div>
      <div className="spacer"/>
      {this.renderPayments()}
      <div className="spacer"/>
      <div className="form-group">
        <div className="row">
            <div className="col-sm-11">
                <label className="form-field-bold text-blue">{<Translation>{ t => <span>{t('PAYMENTDETAILS')} </span>}</Translation>}</label>
            </div>
        </div>
        <div className="spacer"/>
        <div className = "row">
          <div className = "col-2">
            <label className="form-field-bold">{<Translation>{ t => <span>{t('CASHPAYMENTS')} </span>}</Translation>}</label>
          </div>
          <div className = "col-2">
            <SelectButton value={this.state.paymentBehavior.cash_payment} options={options}  onChange={(e) => this.handlePaymentBehaviorChange("cash_payment", e)}
                    optionLabel="label" style = {{ "marginLeft" : "10px", "fontSize": "7px"}}/>
          </div>
        </div>
        <div className = "row">
          <div className = "col-2">
            <label className="form-field-bold">{<Translation>{ t => <span>{t('CREDIT_PAYMENTS')} </span>}</Translation>}</label>
          </div>
          <div className = "col-2">
          <SelectButton value = {this.state.paymentBehavior.credit_payments} options={options} onChange = {(e) => {this.handlePaymentBehaviorChange("credit_payments", e)}}
                  optionLabel="label" style = {{ "marginLeft" : "10px", "fontSize": "7px"}}/>
          </div>
        </div>
        <div className = "row">
          <div className = "col-2">
            <label className="form-field-bold">{<Translation>{ t => <span>{t('PAYMENTTERMS')} </span>}</Translation>}</label>
          </div>
          <div className = "col-2">
            <input type = "text" className="form-control form-control-sm" value = {this.state.paymentBehavior.payment_terms}
              onChange = {(e) => {this.handlePaymentBehaviorChange("payment_terms", e)}}  />
          </div>
        </div>
        <div className = "row">
          <div className = "col-2">
            <label className="form-field-bold">{<Translation>{ t => <span>{t('PAYMENT_MEANS')} </span>}</Translation>}</label>
          </div>
          <div className = "col-2">
            <input type = "text" className="form-control form-control-sm" value = {this.state.paymentBehavior.payment_means}
              onChange = {(e) => {this.handlePaymentBehaviorChange("payment_means", e)}} />
          </div>
        </div>
        <div className = "row">
          <div className = "col-2">
            <label className="form-field-bold">{<Translation>{ t => <span>{t('MAXCREDITLINE')} </span>}</Translation>}</label>
          </div>
          <div className = "col-2">
            <input type = "text" className="form-control form-control-sm" value = {this.state.paymentBehavior.maximum_credit_line}
                onChange = {(e) => {this.handlePaymentBehaviorChange("maximum_credit_line", e)}} />
          </div>
        </div>
      </div>
      <div className="form-group">
          <div className="row" >
              <div className="col-sm-12">
                  <div className="row">
                      <div className="col-sm-11">
                          <label className="form-field-bold">{<Translation>{ t => <span>{t('CREDIT_BUREA')} </span>}</Translation>}</label>
                      </div>
                      <div className="col-sm-1">
                          <Button icon="pi pi-angle-down" className='p-button-square p-button-secondary float-right' onClick={this.toggleCreditBurea}  />
                      </div>
                  </div>
              </div>

          </div>
          <div className="row">
              <div className="col-sm-12">
                  <textarea autoFocus onChange={this.handleCreditBureauChange}
                    className={this.state.ActiveCreditBurea ? 'form-text-comments form-control lg-text-area': "form-control form-text-comments"}
                      name={"credit_bureau_information"} value={this.state.paymentBehavior.credit_bureau_information}></textarea>
              </div>
          </div>
          </div>
      </div>
    )
  }
}

export default InquiriesDetailsPaymentBehavior;
