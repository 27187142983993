import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Translation } from 'react-i18next';
import {SelectButton} from 'primereact/selectbutton';

class InquiriesDetailsIdentificationContactDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      contactInfo: this.props.contact_info,
      telephones: this.props.contact_info.telephones,
      faxes: this.props.contact_info.faxes,
      emails: [],
      websites: [],
      socialMedias: [],
      orderInfo: this.props.order_info,
      hideContactInfoComments: this.props.report.contact_info_same_as_order == true,
      report: this.props.report,
      toast: this.props.toast,
    }
    if(this.state.contactInfo.emails){
      this.state.emails = this.state.contactInfo.emails.split(",")
    }
    if(this.state.contactInfo.websites){
      this.state.websites = this.state.contactInfo.websites.split(",")
    }
    if(this.state.contactInfo.social_media){
      this.state.socialMedias = this.state.contactInfo.social_media.split(",")
    }
    this.handleAddPhone = this.handleAddPhone.bind(this);
    this.handleAddFax = this.handleAddFax.bind(this);
    this.handleAddEmail = this.handleAddEmail.bind(this);
    this.handleAddWebsite = this.handleAddWebsite.bind(this);
    this.handleAddSocialMedia = this.handleAddSocialMedia.bind(this);
    this.handleContactInfoChange = this.handleContactInfoChange.bind(this);
    this.dataRefresh = this.dataRefresh.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleFaxChange = this.handleFaxChange.bind(this);
    this.deleteWebsite = this.deleteWebsite.bind(this);
    this.deleteSocialMedia = this.deleteSocialMedia.bind(this);
    this.deletePhone = this.deletePhone.bind(this);
    this.deleteFax = this.deleteFax.bind(this);
    this.handleContactInfoCommentChange = this.handleContactInfoCommentChange.bind(this);
  }

  deletePhone(indx, event){
    let telephones = this.state.telephones;
    telephones.splice(indx,  1);
    this.setState({telephones: telephones}, function(){
      this.dataRefresh();
    })
  }

  deleteFax(indx, event){
    let faxes = this.state.faxes;
    faxes.splice(indx,  1);
    this.setState({faxes: faxes}, function(){
      this.dataRefresh();
    })
  }

  deleteWebsite(indx, event){
    let websites = this.state.websites;
    websites.splice(indx,  1);
    this.setState({websites: websites}, function(){
      this.dataRefresh();
    })
  }
  deleteSocialMedia(indx, event){
    let socialMedias = this.state.socialMedias;
    socialMedias.splice(indx,  1);
    this.setState({socialMedias: socialMedias}, function(){
      this.dataRefresh();
    })
  }
  deleteEmails(indx, event){
    let emails = this.state.emails;
    emails.splice(indx,  1);
    this.setState({emails: emails}, function(){
      this.dataRefresh();
    })
  }

  handleContactInfoCommentChange(event){
    let contactInfo = this.state.contactInfo
    contactInfo.comments = event.target.value
    this.setState({contactInfo: contactInfo}, function(){
      this.dataRefresh();
    })
  }

  dataRefresh(){
    // function that reads the state and updates the session storage
    const contactInfo = sessionStorage.getItem('ses_contact_info_' + this.props.report_id);
    const contactInfoJSON = JSON.parse(contactInfo)
    contactInfoJSON.telephones = this.state.telephones
    contactInfoJSON.faxes = this.state.faxes
    contactInfoJSON.emails  = this.state.emails.join(",")
    contactInfoJSON.websites  = this.state.websites.join(",")
    contactInfoJSON.social_media  = this.state.socialMedias.join(",")
    contactInfoJSON.comments = this.state.contactInfo.comments
    sessionStorage.setItem('ses_contact_info_' + this.props.report_id, JSON.stringify(contactInfoJSON))
  }

  handleReportInfoChange(field, event){
    if(field == "contact_info_same_as_order"){
      this.setState({hideContactInfoComments: event.target.value})
    }
    let report = this.state.report;
    report[field] = event.target.value;
    this.setState({report: report})
  }

  handleContactInfoChange(event){
    let value = event.target.value
    let name = event.target.name
    if (value.includes(",")){
      alert("Please avoid using commas `,` in the " + name )
      return
    }
    if(name == "contact_info_same_as_order"){
      this.setState({hideContactInfoComments: value})
    }
    let contactInfoList = this.state[name]
    contactInfoList[event.target.id] = value;
    this.setState({[name]: contactInfoList}, function(){
      this.dataRefresh();
    })
  }
  handleAddPhone(e){
    e.preventDefault();
    let telephones = this.state.telephones

    if(telephones.length == 0 || telephones[telephones.length - 1].number){
      telephones.push({})
    this.setState({telephones:telephones})
    } else {
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter phone number"});            
    }
  }
  handleAddFax(event){
    let faxes = this.state.faxes

    if(faxes.length == 0 || faxes[faxes.length - 1].number){
      faxes.push({})
      this.setState({faxes:faxes})
    } else {
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter fax number"});            
    }
  }
  handleAddEmail(event){
    let emails = this.state.emails
    
    if(emails.length == 0 || emails[emails.length - 1]){
      emails.push('')
      this.setState({emails:emails})
    } else {
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter email"});            
    }

   
  }
  handleAddWebsite(event){
    let websites = this.state.websites

    if(websites.length == 0 || websites[websites.length - 1]){
      websites.push('')
      this.setState({websites:websites})
    } else {
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter website url"});            
    }

  }
  handleAddSocialMedia(event){
    let socialMedias = this.state.socialMedias
  
    if(socialMedias.length == 0 || socialMedias[socialMedias.length - 1]){
      socialMedias.push('')
      this.setState({socialMedias:socialMedias})
    } else {
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter social media links"});            
    }



  }

  handlePhoneChange(event){
    let telephones = this.state.telephones
    let contactInfo = this.state.contactInfo
    telephones[event.target.id][event.target.name] = event.target.value
    contactInfo.telephones = telephones
    this.setState({telephones:telephones, contactInfo: contactInfo}, function(){
      this.dataRefresh();
    })
  }

  handleFaxChange(event){
    let faxes = this.state.faxes
    let contactInfo = this.state.contactInfo
    faxes[event.target.id][event.target.name] = event.target.value
    contactInfo.faxes = faxes
    this.setState({faxes:faxes, contactInfo: contactInfo}, function(){
      this.dataRefresh();
    })
  }


  renderPhonesAndFaxes(){
    return(
      <div>
        <div className="row" style = {{margin:"10px"}}>
          <div className = "col-2">
            <span className="form-field-bold">{<Translation>{ t => <span>{t('PHONE_NUMBERS')} </span>}</Translation>}</span>
          </div>
          <div className = "col-1">
            <Button style = {{marginLeft:"10px"}} icon="pi pi-plus" onClick={this.handleAddPhone} className="p-button-square p-button-secondary" />
          </div>
          {
            this.state.telephones.map((telephone, i) =>(
              <div className = "col-2" style = {{marginLeft:"5px", marginRight: "5px"}}>
                <div className = "row phone">
                  <div className = "col-3">
                    <input autoComplete="something" type = "number" id = {i} name = "country_code"
                        className="form-control form-control-sm" placeholder = "Country Code" onChange={this.handlePhoneChange} value ={telephone?.country_code}/>
                  </div>
                  <div className = "col-8">
                    <input autoComplete="something" type = "number" className="form-control form-control-sm"  id = {i} name = "number"
                      placeholder = "N" onChange={this.handlePhoneChange} value ={telephone?.number} />
                  </div>
                  <div className = "col-1">
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick = {(e) => this.deletePhone(i, e)} value = {null} style={{'top': '-7px'}}  />
                  </div>
                </div>

              </div>
            ))
          }
        </div>
        <div className="row" style = {{margin:"10px"}}>
          <div className = "col-2">
            <span className="form-field-bold">{<Translation>{ t => <span>{t('FAX_NUMBERS')} </span>}</Translation>}</span>
          </div>
          <div className = "col-sm-1">
            <Button style = {{marginLeft:"10px"}} icon="pi pi-plus" onClick={this.handleAddFax} className="p-button-square p-button-secondary" />
          </div>
          {
            this.state.faxes.map((fax, i) =>(
              <div className = "col-2" style = {{marginLeft:"5px", marginRight: "5px"}}>
                <div className = "row phone">
                  <div className = "col-3">
                    <input autoComplete="something" type = "number" id = {i} name = "country_code"
                        className="form-control form-control-sm" placeholder = "Country Code" onChange={this.handleFaxChange} value ={fax.country_code}/>
                  </div>
                  <div className = "col-8">
                    <input autoComplete="something" type = "number" className="form-control form-control-sm"  id = {i} name = "number"
                      placeholder = "N" onChange={this.handleFaxChange} value ={fax.number} />
                  </div>
                  <div className = "col-1">
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick = {(e) => this.deleteFax(i, e)} value = {null} style={{'top': '-7px'}}  />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
  render(){
    const options = [
      {label: 'YES', value: true},
      {label: 'NO', value: false},
      {label: 'Unconfirmed', value: null}
    ];
    return(
      <div id="inquiry_contat_info" className="form-group">
        <span className="form-title">{<Translation>{ t => <span>{t('CONTACT_DETAILS')} </span>}</Translation>}</span>
        <br></br>
        <div className = "row">
          <div className="col-8">
            {this.renderPhonesAndFaxes()}
            <div className="row" style = {{margin:"5px", marginRight: "5px"}}>
              <div className = "col-2">
                <span className="form-field-bold">{<Translation>{ t => <span>{t('EMAILS')} </span>}</Translation>}</span>
              </div>
              <div className = "col-1">
                <Button style = {{marginLeft:"10px"}} icon="pi pi-plus" onClick={this.handleAddEmail} className="p-button-square p-button-secondary" />
              </div>
              {
                this.state.emails.map((email, i) =>(
                  <div className = "col-2">
                  <div className = "row">
                      <div className = "col-10">
                        <input  autoComplete="something" className="form-control form-control-sm"  id = {i} name= "emails" type = "text" onChange={this.handleContactInfoChange} value ={email} />
                      </div>
                      <div className = "col-1">
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick = {(e) => this.deleteEmails(i, e)} value = {null} style={{'top': '-7px'}}  />
                      </div>
                  </div>
                </div>
                ))
              }
            </div>
            <div className="row" style = {{margin:"5px", marginRight: "5px"}}>
              <div className = "col-2">
                <span className="form-field-bold">{<Translation>{ t => <span>{t('WEBSITES')} </span>}</Translation>}</span>
              </div>
              <div className = "col-1">
                <Button style = {{marginLeft:"10px"}} icon="pi pi-plus" onClick={this.handleAddWebsite} className="p-button-square p-button-secondary" />
              </div>
              {
                this.state.websites.map((website, i) =>(
                  <div className = "col-2">
                    <div className = "row">
                        <div className = "col-10">
                          <input autoComplete="something" className="form-control form-control-sm"  id = {i} name= "websites" type = "text" onChange={this.handleContactInfoChange} value ={website} />
                        </div>
                        <div className = "col-1">
                          <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick = {(e) => this.deleteWebsite(i, e)} value = {null} style={{'top': '-7px'}}  />
                        </div>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="row" style = {{margin:"5px", marginRight: "5px"}}>
              <div className = "col-2">
                <span className="form-field-bold">{<Translation>{ t => <span>{t('SOCIAL_MEDIA_LINKS')} </span>}</Translation>}</span>
              </div>
              <div className = "col-1">
                <Button style = {{marginLeft:"10px"}} icon="pi pi-plus" onClick={this.handleAddSocialMedia} className="p-button-square p-button-secondary" />

              </div>
              {
                this.state.socialMedias.map((socielMedia, i) =>(
                  <div className = "col-2">
                    <div className = "row">
                        <div className = "col-10">
                          <input autoComplete="something" className="form-control form-control-sm"  id = {i} name= "socialMedias" type = "text" onChange={this.handleContactInfoChange} value ={socielMedia} />
                        </div>
                        <div className = "col-1">
                          <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick = {(e) => this.deleteSocialMedia(i, e)} value = {null} style={{'top': '-7px'}}  />
                        </div>
                    </div>
                  </div>
                ))
              }
            </div>
            <br />
            <div className = "row">
              <div className = "col-2">
                <span className="form-field-bold">{<Translation>{ t => <span>{t('CONTACT_INFO_COMMENTS')} </span>}</Translation>}</span>
              </div>
              <div className = "col-10">
                <textarea className="form-control form-text-comments" rows="3" value = {this.state.contactInfo.comments} onChange={this.handleContactInfoCommentChange} >

                </textarea>
              </div>

            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <div className="row" >
                  <div className="col-sm-6">
                      <label className="form-field-bold">{<Translation>{ t => t('TELEPHONE_PROVIDED')}</Translation>}</label>
                  </div>
              </div>
              <div className="row" >
                  <div className="col-sm-11">
                      <input disabled type = "text" className="form-control form-control-sm" value = {this.state.orderInfo.phone} />
                  </div>
              </div><br/>
            </div>
            <div className="form-group">
              <div className="row" >
                  <div className="col-sm-6">
                      <label className="form-field-bold">{<Translation>{ t => t('EMAIL_PROVIDED')}</Translation>}</label>
                  </div>
              </div>
              <div className="row" >
                  <div className="col-sm-11">
                      <input disabled type = "text" className="form-control form-control-sm" value = {this.state.orderInfo.email} />
                  </div>
              </div><br/>
            </div>
            <div className="form-group">
              <div className="row" >
                  <div className="col-sm-6">
                      <label className="form-field-bold">{<Translation>{ t => t('WEBSITE_PROVIDED')}</Translation>}</label>
                  </div>
              </div>
              <div className="row" >
                  <div className="col-sm-11">
                      <input disabled type = "text" className="form-control form-control-sm" value = {this.state.orderInfo.website} />
                  </div>
              </div>
              <div className="row" >
                  <div className="col-sm-6">
                      <label className="form-field-bold">{<Translation>{ t => t('CONTACT_INFO_SAME_AS_ORDER')}</Translation>}</label>
                  </div>
                  <div className="col-sm-6">
                      <div className="row">
                          <div className="col-sm-12">
                            <SelectButton value={this.state.report.contact_info_same_as_order} options={options} onChange={(e) => this.handleReportInfoChange("contact_info_same_as_order", e)}></SelectButton>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row">
                <div className="col-11">
                  <textarea hidden={this.state.hideContactInfoComments} className="form-control form-text-comments" rows="3"
                    value = {this.state.report.contact_info_comments} onChange={(e) => this.handleReportInfoChange("contact_info_comments", e)}></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
export default InquiriesDetailsIdentificationContactDetails;
