import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import InquiryService from '../service/InquiryService';
import { Translation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import {SelectButton} from 'primereact/selectbutton';
import FormattedNumberComponent from '../components/FormattedNumberComponent';

class InquiriesDetailsFinancialsBanks extends Component {

  constructor(props){
      super(props);

      this.state = {
         bankDiv:  this.props.banks,
         countries: [],
         currencies: [],
         toast : this.props.toast
      }
      this.addNewBank = this.addNewBank.bind(this);
      this.handleBankBlur = this.handleBankBlur.bind(this);
      this.handleBankChange = this.handleBankChange.bind(this);
      this.deleteBank = this.deleteBank.bind(this);
      this.deleteLoan = this.deleteLoan.bind(this);
      this.addNewBankDetails = this.addNewBankDetails.bind(this);
      this.inquiryService = new InquiryService();
  }

  componentDidMount(){
    if(!sessionStorage.getItem('ses_countries')){
      this.inquiryService.getCountries().then(data =>{
        this.setState({countries:data})
        sessionStorage.setItem('ses_countries',JSON.stringify(data))
      });
    }else{  
      this.setState({countries: JSON.parse(sessionStorage.getItem('ses_countries'))});   
    }
    if(!sessionStorage.getItem('ses_currency')){
      this.inquiryService.getCurrencies().then(data =>{
        this.setState({currencies: data })
        sessionStorage.setItem('ses_currency',JSON.stringify(data))
      })
    }else{
        this.setState({currencies : JSON.parse(sessionStorage.getItem('ses_currency')) })  
    }
  }
  dataRefresh(){
    // function that reads the state and updates the session storage
    const banksInfo = sessionStorage.getItem('ses_banks_info_' + this.props.report_id);
    const banksInfoJson = JSON.parse(banksInfo)
    var newBanks = []
    this.state.bankDiv.map((bank,i)=>{
      newBanks.push(bank)
    })
    banksInfoJson.banks = newBanks
    sessionStorage.setItem('ses_banks_info_' + this.props.report_id, JSON.stringify(banksInfoJson));
  }

  deleteBank(bank_idx, event){
      event.preventDefault();
      var bank_arr = this.state.bankDiv;
      bank_arr.splice(bank_idx,  1);
      this.setState({bankDiv: bank_arr }, function(){
        this.dataRefresh();
      })

  }

  deleteLoan(bank_indx, loan_indx, event){
    event.preventDefault();
    var bank_arr = this.state.bankDiv;
    var bank = bank_arr[bank_indx];
    let loans = bank.loans_and_creditLines
    loans.splice(loan_indx,  1);
    this.setState({bankDiv: bank_arr }, function(){
      this.dataRefresh();
    })
  }

  addNewBank(event){
    event.preventDefault();
    const bank_arr = this.state.bankDiv;

    if(bank_arr.length == 0 || bank_arr[bank_arr.length - 1].name !="New Bank"){
      this.state.newBanksCount += 1
      bank_arr.push({"name": "", "country":{"name": "United States"}, "loans_and_creditLines":[]});

      this.setState({bankDiv: bank_arr } , function(){
        this.dataRefresh();
      })
    } else {
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter bank name"});            
    }
  }

  handleBankBlur(event){

  }

  handleBankChange(bank_idx, field, event){

    const value = event.target.value
    var bank_arr = this.state.bankDiv;
    bank_arr[bank_idx][field] = value;

    this.setState({bankDiv: bank_arr });
    this.dataRefresh();
  }
  addNewBankDetails(i){
    let loans = {}

    loans.type = ""
    var bank_arr = this.state.bankDiv;
    console.log(bank_arr[i]["loans_and_creditLines"])
    
    if(bank_arr[i]["loans_and_creditLines"].length == 0 || bank_arr[i]["loans_and_creditLines"][bank_arr[i]["loans_and_creditLines"].length - 1].type !=""){
        bank_arr[i]["loans_and_creditLines"].push(loans)
        this.setState({bankDiv: bank_arr }, function(){
          this.dataRefresh();
        })
    } else {
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "Please enter bank detail type"});            
    }

  }

  handleBankDetailChange(bank_indx, loan_indx, field, event){
    var bank_arr = this.state.bankDiv;
    bank_arr[bank_indx]["loans_and_creditLines"][loan_indx][field] = event.target.value
    this.setState({bankDiv: bank_arr }, function(){
      this.dataRefresh();
    })
  }

  renderLoanAndCredits(bank, bank_indx){
    let loans = bank.loans_and_creditLines
    const fields = [
      {label: 'Loan', value: 'LOAN'},
      {label: 'OverDraft', value: 'OVDT'},
      {label: 'Credit', value: 'CRDT'},
      {label: 'Other', value: 'OTHR'}
    ]
    if ( loans.length > 0){
      return(
        <div className="form-group">
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold">{<Translation>{ t => t('TYPE')}</Translation>}</label>
              </div>
              {
                loans.map((loan, i) => (
                  <div className="col-sm-1" key={i}>
                      <Dropdown value={loan.type} options={fields} onChange={(e) => this.handleBankDetailChange(bank_indx, i, "type", e)} optionLabel="label" placeholder="Select a Type"/>
                  </div>
                ))
              }

          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold">{<Translation>{ t => t('CURRENCY')}</Translation>}</label>
              </div>
              {
                loans.map((loan, i) => (
                  <div className = "col-sm-1" key={i}>
                    <Dropdown value = {loan.currency} options={this.state.currencies} onChange={(e)=>this.handleBankDetailChange(bank_indx, i, "currency", e)} name = "currency" optionLabel="code" placeholder="Select a Currency" filter/>
                  </div>
                ))
              }

          </div>




          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold">{<Translation>{ t => t('AMOUNT_OF_LOAN')}</Translation>}</label>
              </div>
              {
                loans.map((loan, i) => (
                  <div className="col-sm-1" key={i}>
                      <FormattedNumberComponent
                        update_value={(e) => this.handleBankDetailChange(bank_indx, i, "amount", e)}
                        value={loan.amount} />
                  </div>
                ))
              }
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold">{<Translation>{ t => t('LOAN_TERMS')}</Translation>}</label>
              </div>
              {
                loans.map((loan, i) => (
                  <div className="col-sm-1" key={i}>
                      <input type="text" value = {loan.term} onChange={(e) => this.handleBankDetailChange(bank_indx, i, "term", e)} className="form-control form-control-sm"/>
                  </div>
                ))
              }
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold">{<Translation>{ t => t('PAY_OFF_AMOUNT')}</Translation>}</label>
              </div>
              {
                loans.map((loan, i) => (
                  <div className="col-sm-1" key={i}>
                    <FormattedNumberComponent
                      update_value={(e) => this.handleBankDetailChange(bank_indx, i, "pay_off_amount", e)}
                      value={loan.pay_off_amount} />
                  </div>
                ))
              }
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold">{<Translation>{ t => t('PAY_OFF_YEAR')}</Translation>}</label>
              </div>
              {
                loans.map((loan, i) => (
                  <div className="col-sm-1" key={i}>
                      <input type="text" value = {loan.pay_off_year} onChange={(e) => this.handleBankDetailChange(bank_indx, i, "pay_off_year", e)} className="form-control form-control-sm"/>
                  </div>
                ))
              }
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold">{<Translation>{ t => t('OVERDRAFT_LIMIT')}</Translation>}</label>
              </div>
              {
                loans.map((loan, i) => (
                  <div className="col-sm-1" key={i}>
                      <FormattedNumberComponent
                        update_value={(e) => this.handleBankDetailChange(bank_indx, i, "overdraft_limit", e)}
                        value={loan.overdraft_limit} />
                  </div>
                ))
              }
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold">{<Translation>{ t => t('COMMENTS')}</Translation>}</label>
              </div>
              {
                loans.map((loan, i) => (
                  <div className="col-sm-1" key={i}>
                      <textarea rows="2" type="text" value = {loan.comments} onChange={(e) => this.handleBankDetailChange(bank_indx, i, "comments", e)} className="form-control form-control-sm"/>
                  </div>
                ))
              }
          </div>
          <div className="row">
              <div className="col-sm-2">
                  <label className="form-field-bold"></label>
              </div>
              {
                loans.map((loan, i) => (
                  <div className="col-sm-1" key={i}>
                      <Button   onClick={(e) => this.deleteLoan(bank_indx, i, e)} className="p-button-danger p-button-text p-button-rounded p-c pi pi-trash" />
                  </div>
                ))
              }
          </div>
        </div>
      )
    }
    }


  render() {
    const options = [
      {label: 'YES', value: true},
      {label: 'NO', value: false},
      {label: 'Unconfirmed', value: null}
    ];
    console.log("BankDiv", this.state.bankDiv);
    return (
      <div>
      <div className="row">
          <div className="col-12">
              <div className="form-group">
                  <span className="form-title">{<Translation>{ t => <span>{t('ADD')} </span>}</Translation>}</span> <Button icon="pi pi-plus"  onClick={this.addNewBank} className="p-button-square p-button-secondary" />
              </div>
          </div>
      </div>
      <div className="row">
          <div className="col-12 nested-accordion" ><br/>

              <Accordion id="bank_info" ref="bank_info">
                      {

                          this.state.bankDiv.map((bank,i) => (
                            <AccordionTab key={i} header={<React.Fragment> <input placeholder='Bank Name' ref="name" autoFocus onBlur={this.handleBankBlur} onChange={(e) => this.handleBankChange(i, "name", e)} className="form-control form-control-sm" name={'name_' + bank.id} type="text" value={bank.name}/></React.Fragment>} id={"bank_form" + bank.id}>                            
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-sm-1">
                                      <label className="form-field-bold">{<Translation>{ t => <span>{t('NAME')} </span>}</Translation>}</label>
                                  </div>
                                  <div className="col-sm-3">
                                    <input ref="name" placeholder='Bank Name' autoFocus onBlur={this.handleBankBlur} onChange={(e) => this.handleBankChange(i, "name", e)} className="form-control form-control-sm" name={'name_' + bank.id} type="text" value={bank.name}/>
                                  </div>

                                  <div className="col-sm-1">
                                      <label className="form-field-bold"><Translation>{ t => <span>{t('ACCOUNT_NUMBER')} </span>}</Translation></label>
                                  </div>
                                  <div className="col-sm-4">
                                      <input name={'bankaccountnumber_' + bank.id} onChange={(e) => this.handleBankChange(i, "account_number", e)}  onBlur={this.handleBankBlur}  className="form-control form-control-sm" type="text" value={bank.account_number}/>
                                  </div>

                              </div>
                              <div className="row">
                                  <div className="col-sm-1">
                                          <label className="form-field-bold"><Translation>{ t => <span>{t('COUNTRY')} </span>}</Translation> </label>

                                  </div>
                                  <div className="col-sm-3">
                                      <Dropdown value = {bank.country} options={this.state.countries} onChange={(e)=>this.handleBankChange(i, "country", e)} optionLabel="name" placeholder="Select a Country" filter filterBy="name" />
                                  </div>

                                  <div className="col-sm-1">
                                      <label className="form-field-bold"><Translation>{ t => <span>{t('COMMENTS')} </span>}</Translation> </label>
                                  </div>
                                  <div className="col-sm-4">
                                      <textarea name={'contactname_' + bank.id} onChange={(e) => this.handleBankChange(i, "comments", e)}  className="form-control form-control-sm" row="3" value={bank.comments}></textarea>
                                  </div>

                              </div>
                              <div className="form-hr"/>
                              <div className="row">
                                  <div className="col-12">
                                      <div className="form-group">
                                          <span className="form-title">{<Translation>{ t => t('DETAILS')}</Translation>}</span> <Button icon="pi pi-plus"  onClick={() => this.addNewBankDetails(i)} className="p-button-square p-button-secondary" />
                                      </div>
                                  </div>
                              </div>
                              <br></br>
                              <div className="row">
                                  <div className="col-1">
                                          <label className="form-field-bold">{<Translation>{ t => t('LOAN')}</Translation>}</label>
                                  </div>
                                  <div className="col-2">
                                      <SelectButton value={bank.has_loan}  options={options} onChange={(e) => this.handleBankChange(i, "has_loan", e)}></SelectButton>
                                  </div>
                                  <div className="col-1">
                                          <label className="form-field-bold">{<Translation>{ t => t('OVERDRAFT')}</Translation>}</label>
                                  </div>
                                  <div className="col-2">
                                      <SelectButton value={bank.has_overdraft}  options={options} onChange={(e) => this.handleBankChange(i, "has_overdraft", e)}></SelectButton>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-1">
                                          <label className="form-field-bold">{<Translation>{ t => t('CREDIT')}</Translation>}</label>
                                  </div>
                                  <div className="col-2">
                                      <SelectButton value={bank.has_credit}  options={options} onChange={(e) => this.handleBankChange(i, "has_credit", e)}></SelectButton>
                                  </div>
                                  <div className="col-1">
                                          <label className="form-field-bold">{<Translation>{ t => t('OTHER')}</Translation>}</label>
                                  </div>
                                  <div className="col-2">
                                      <SelectButton value={bank.has_other} options={options} onChange={(e) => this.handleBankChange(i, "has_other", e)}></SelectButton>
                                  </div>
                              </div>
                              <br></br>
                              {this.renderLoanAndCredits(bank, i)}
                              <br></br>
                              <div className = "row">
                                <div className="col-sm-6">
                                    <Button   onClick={(e) => this.deleteBank(i, e)} className="p-button-danger p-c pi pi-trash" />
                                </div>
                              </div>

                          </div>

                          </AccordionTab>

                          ))

                       }
              </Accordion>

          </div>
      </div>
      </div>
    )
  }
}

export default InquiriesDetailsFinancialsBanks
