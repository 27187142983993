import React, { Component } from 'react';
import { Button } from 'primereact/button';
import UserService from '../service/UserService';
import CompanyService from '../service/CompanyService';
import Address from '../components/Address.js';
import { SelectButton } from 'primereact/selectbutton';
import {Password} from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

class UserDetails extends Component {
  constructor(props){
    super(props);
    let userInfo = {...this.props.user}
    let companyId = 0
    let groupId = 0
    if(userInfo.is_staff) {
      groupId = userInfo.user.company_group ? userInfo.user.company_group.id : 0
    } else {
      companyId = userInfo.user.company ? userInfo.user.company.id : 0
    }
    this.state = {
      userInfo : userInfo,
      customers: [],
      groups: [],
      companyIdToAssign: companyId,
      groupIdToAssign: groupId,
      toast: this.props.toast
    }

    this.renderUserProfile = this.renderUserProfile.bind(this);
    this.newUserProfile = this.newUserProfile.bind(this);
    this.handleUserProfileChange = this.handleUserProfileChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.renderCompanySubHeader = this.renderCompanySubHeader.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.renderUserAssignment = this.renderUserAssignment.bind(this);
    this.handleAssignedCompanyChange = this.handleAssignedCompanyChange.bind(this);
    this.assign = this.assign.bind(this);
    this.goToEditCompany = this.goToEditCompany.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.assignGroup = this.assignGroup.bind(this);
    this.handleAssignGroupChange = this.handleAssignGroupChange.bind(this);
    this.companyService = new CompanyService();
    this.userService = new UserService();
    this.removeSessionItems = this.removeSessionItems.bind(this);
    console.log(this.state.userInfo)
    this.companyService.getCustomersSummary().then(data=>{
      this.setState({customers:data})
    })
    this.userService.getCCPCompanyGroups().then(data =>{
      this.setState({groups: data})
    })
  }
  delete(event){
      this.userService.deleteUser(this.state.userInfo.id).then(
        response => {  
           this.state.toast.current.show({severity:'success', summary:'Deleted !', detail: "User Has Been Deleted !"});
           
           setTimeout(() => {
            this.props.closeTab(this.state.userInfo.user_name+"_"+this.props.tabCount)
            this.props.onTabChange({index : this.props.activeTabIndex})
          }, 1000);
           
        }
      ). catch(
        err => {
          console.log(err);
          this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err)});
        }
      )
  }
  save(){
    let user_data = {...this.state.userInfo}
    if (!user_data.is_staff){
      if(!user_data.user.company){
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "A Company Needs to be assigned"});
        return;
      }
      user_data.user.company = user_data.user.company.id
    } else {
      if(!user_data.user.company_group){
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "A Group Needs to be assigned"});
        return;
      }
    }
    if ("id" in user_data){
      // update
      this.userService.updateUser(this.state.userInfo.id, user_data).then(data=>{
        this.state.toast.current.show({severity:'success', summary:'Saved !', detail: "User Saved !"});
        this.removeSessionItems()
      }).catch(err=>{
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
      })
    } else {
      // create
      this.userService.createUser(user_data).then(data=>{
        this.state.toast.current.show({severity:'success', summary:'Created !', detail: "User Created !"});
        this.removeSessionItems()
      }).catch(err=>{
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
      })
    }


  }

  removeSessionItems(){
    sessionStorage.removeItem('AdminUsers');
    sessionStorage.removeItem('UsersTotalRecords');
  }
  assign(){
    this.userService.assignUserToCustomer(this.state.userInfo.id, this.state.companyIdToAssign).then(data =>{
        this.state.toast.current.show({severity:'success', summary:'Assigned !', detail: "User Succefullly Assigned to company"});
        this.setState({userInfo:data})
    }).catch(err => {
      this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
    })

  }

  assignGroup(){
    this.userService.assignStaffToGroup(this.state.userInfo.id, this.state.groupIdToAssign).then(data =>{
        this.state.toast.current.show({severity:'success', summary:'Assigned !', detail: "User Succefullly Assigned to Group"});
        this.setState({userInfo:data})
    }).catch(err => {
      this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
    })
  }

  handleAssignGroupChange(event){
    let user = this.state.userInfo
    user.user.company_group = event.target.value
    this.setState({groupIdToAssign: event.target.value.id, userInfo: user})
  }

  handleAssignedCompanyChange(event){
    let user = this.state.userInfo
    user.user.company = event.target.value
    this.setState({companyIdToAssign: event.target.value.id, userInfo: user})
  }

  renderCompanySubHeader(){
    if(!this.state.userInfo.user?.company){
      return <h5>No Company Assigned</h5>
    } else{
      return <h5>Assigned to : {this.state.userInfo.user.company.legal_name}</h5>
    }
  }

  handleAddressChange(field, address_data){
    let userInfo = this.state.userInfo
    userInfo.user[field] = address_data
    this.setState({userInfo:userInfo})
  }
  handleUserProfileChange(field, event){
    let userInfo = this.state.userInfo
    userInfo.user[field] = event.target.value
    this.setState({userInfo:userInfo})
  }
  handleUserChange(field, event){
    let userInfo = this.state.userInfo
    if (field === "is_staff" && event.target.value === true){
      userInfo.user.company = null
    }
    if (field === "is_staff" && event.target.value === false){
      userInfo.is_admin = false
    }
    userInfo[field] = event.target.value
    this.setState({userInfo:userInfo})
  }
  newUserProfile () {
    let userProfile = {}
    let userInfo = this.state.userInfo
    userInfo.user = userProfile
    this.setState({userInfo:userInfo})
  }

  renderUserProfile() {
    if(!this.state.userInfo.user){
      return(
        <div className="col-sm-9" style={{'marginLeft': '10px'}}>
          <Button onClick={this.newUserProfile} label="Add User Profile" className="p-button-sm" icon="pi pi-plus" />
        </div>
      )
    } else{
      return(
        <div>
          <div className="col-sm-6">
              <label className="form-field-bold">User Profile</label>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Email</label>
            </div>
            <div className="col-sm-6">
                <input type="text" onChange={(e)=>this.handleUserProfileChange("email", e)} name="email" value={this.state.userInfo.user.email} class="form-control form-control-sm"/>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Email CC</label>
            </div>
            <div className="col-sm-6">
                <input type="text" onChange={(e)=>this.handleUserProfileChange("email_ccs", e)} name="email_ccs" value={this.state.userInfo.user.email_ccs} class="form-control form-control-sm"/>
                <i style={{fontSize : '10px'}}>if there are more than one email address please separate with comma(;)</i>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">First Name</label>
            </div>
            <div className="col-sm-6">
                <input type="text" onChange={(e)=>this.handleUserProfileChange("first_name", e)} name="first_name" value={this.state.userInfo.user.first_name} class="form-control form-control-sm"/>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-4">
                <label className="form-field-bold">Last Name</label>
            </div>
            <div className="col-sm-6">
                <input type="text" onChange={(e)=>this.handleUserProfileChange("last_name", e)} name="last_name" value={this.state.userInfo.user.last_name} class="form-control form-control-sm"/>
            </div>
          </div>
          <div className = "col-sm-12">
            <Address AddressChange={this.handleAddressChange} field_name = "address" label = {"Address"} address = {this.state.userInfo.user.address}/>
          </div>
        </div>
      )
    }
  }
  renderUserAssignment(){
    if(this.state.userInfo.is_staff){
      return (
        <div>
          <h2>Assign To Group</h2>
          <div className = "row">
            <div className="col-sm-12">
              <div className = "col-sm-6">
                <Dropdown value = {this.state.userInfo.user?.company_group} options={this.state.groups} name="groups"
                  onChange={this.handleAssignGroupChange} placeholder="Select a Group" optionLabel = "name" filter/>
              </div>
              <div className = "col-sm-4">
                <Button hidden onClick={this.assignGroup} label="Assign" className="p-button-sm" icon="pi pi-chevron-right" style={{'fontSize': '2px', 'margin':'10px'}}/>
              </div>
              </div>
          </div>
        </div>
      )
    }else{
      let company = {}
      if(this.state.userInfo.user && this.state.userInfo.user.company){
        company["id"] = this.state.userInfo.user.company.id
        company["legal_name"] = this.state.userInfo.user.company.legal_name
      }
      return(
        <div>
          <h2>Assign To Company</h2>
          {this.renderCompanySubHeader()}
          <div className = "row">
            <div className="col-sm-12">
              <div className = "col-sm-6">
                <Dropdown value = {company} options={this.state.customers} name="customers"
                  onChange={this.handleAssignedCompanyChange} placeholder="Select a Customer" optionLabel = "legal_name" filter/>
              </div>
              <div className = "col-sm-4">
                <Button hidden onClick={this.assign} label="Assign" className="p-button-sm" icon="pi pi-chevron-right" style={{'fontSize': '2px', 'margin':'10px'}}/>
              </div>
              </div>
          </div>
        </div>
      )

    }

  }

  goToEditCompany(){
    if (!this.state.userInfo.user.company){
      this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "No Company Selected"});
      return;
    }
    this.props.addCompanyTab(this.state.userInfo.user.company)
  }

  renderCompanyName(){
    if(this.state.userInfo.is_staff || !this.state.userInfo.id){
      return (<div></div>)
    }
    return (
      <div className="row">
        <div className="col-4">
            <label className="form-field-bold">Company Name</label>
        </div>
        <div className="col-4">
            <input type="text" name="name" value={this.state.userInfo.user?.company?.legal_name} class="form-control form-control-sm"/>
        </div>
        <div className="col-sm-1">
            <Button onClick={this.goToEditCompany}  className="p-button-sm" icon="pi pi-external-link" style={{'fontSize': '5px'}} />
        </div>
      </div>
    )
  }
  render(){
    const options = [{value : true, label: "Yes"}, {value : false, label: "No"}];
    return(
      <div className = "row">
        <Toast ref={this.state.toast} />
        <div className = "col-4">
          <div className="form-group">
            {this.renderCompanyName()}
            <br></br>
            <div className = "row">
              <div className="col-4">
                  <label className="form-field-bold">Username</label>
              </div>
              <div className="col-sm-4">
                  <input type="text" name="username" value={this.state.userInfo.username} onChange={(e) => this.handleUserChange("username", e)}  className="form-control form-control-sm"/>
              </div>
            </div>
            <br></br>
            <div className = "row">
              <div className="col-4">
                  <label className="form-field-bold">Is Staff?</label>
              </div>
              <div className="col-4">
                <SelectButton value={this.state.userInfo.is_staff} options={options}
                    onChange={(e) => this.handleUserChange("is_staff", e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
              </div>
            </div>
            <div className = "row">
              <div className="col-4">
                  <label className="form-field-bold">Is Admin?</label>
              </div>
              <div className="col-4">
                <SelectButton value={this.state.userInfo.is_admin} options={options}
                    onChange={(e) => this.handleUserChange("is_admin", e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
              </div>
            </div>
            <br></br>
            <div className = "row">
              <div className="col-4">
                  <label className="form-field-bold">Password</label>
              </div>
              <div className="col-7">
                  <Password value={this.state.userInfo.password} onChange={(e) => this.handleUserChange("password", e)} toggleMask />
              </div>
            </div>

          </div>
        </div>
        <div className = "col-4">
          {this.renderUserProfile()}
        </div>
        <div className = "col-4">
          {this.renderUserAssignment()}
        </div>
        <div className="col-sm-9" style={{'marginLeft': '10px'}}>
          <Button onClick={this.save} label="Save" className="p-button-sm" icon="pi pi-check" />
          <Button onClick={this.delete} hidden={!this.state.userInfo.id} label="Delete" className="p-button-sm" icon="pi pi-trash" />
        </div>
      </div>
    )
  }
}

export default UserDetails;
