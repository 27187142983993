import axios from 'axios';
import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Translation } from 'react-i18next';
import { SelectButton } from 'primereact/selectbutton';
import InquiryService from '../service/InquiryService';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import SourcesComponent from '../components/SourcesComponent';
import FormattedNumberComponent from '../components/FormattedNumberComponent';
import UploadedFilesComponent from '../components/UploadedFilesComponent';
import { Toast } from 'primereact/toast';

class InquiriesDetailsFinancialsInformation extends Component {

  constructor(props){
      super(props);
      var max = new Date().getFullYear()
      var min = max - 50
      let years = []
      for (var i = max; i >= min; i--) {
          years.push(i.toString())
      }
      this.state = {
         financials: this.props.financials,
         financialFiles: this.props.financials.financial_report_files,
         groupFinancialFiles: this.props.financials.group_financial_report_files,
         currencies: [],
         years: years,
         toast: this.props.toast,
         fmp_api_key: 'a84612658d9615047a2cb90c79af3cf6',
         pl_fmp_url: 'https://fmpcloud.io/api/v3/income-statement/',
         bs_fmp_url: 'https://fmpcloud.io/api/v3/balance-sheet-statement/',
         pl_fields: ["date","revenue", "cost_of_revenue", "gross_profit", "research_and_development_expenses", "selling_general_and_admin_expenses", "other_expenses", "total_operating_expenses",
                    "interest_income", "interest_expense", "operating_income", "other_income", "income_before_tax", "net_income",
                    "earnings_per_share", "diluted_earnings_per_share"],

         bs_fields: ["cash_and_bank", "shortterm_investment", "net_receivables", "inventory", "other_current_assets", "total_current_assets", "property_plant_equipment_net", "goodwill", "intangible_assets",
                    "longterm_investments", "other_non_current_assets", "total_non_current_assets", "other_assets", "total_assets", "account_payables", "shortterm_debt", "tax_payables",
                    "deferred_revenue", "other_current_liabilities", "total_current_liabilities", "longterm_debt", "deferred_revenue_non_current", "deferred_tax_liabilities_non_current", "other_longterm_liabilities",
                    "total_longterm_liabilities", "other_liabilities", "capital_lease_obligations", "total_liabilities", "total_stockholders_equity", "total_liabilities_and_stockholders_equity", "total_equity", "total_liabilities_and_equity",
                    "total_investments", "total_debt"],

         ratio_fields: ["return_on_equity", "return_on_assets", "profit_margin", "gross_margin", "collection_period",
                      "current_ratio", "quick_ratio", "debt_to_asset_ratio", "debt_to_equity_ratio", "sales_growth_rate",
                      "net_profit_growth_rate"],
        company_leg_text: "Company legislation does not mandate for private companies to disclose their financial statements to any third party, and this information is not readily available from any public sources.",
        financials_not_published_text: "Company has not published its financial information.",
        interview_contact_not_release: "The Person contacted has refused to release financial information.",
        interview_cant_contact: "We could not contact the company to inquire for financial data."
      }

      this.handleFinancialInfoChange = this.handleFinancialInfoChange.bind(this);
      this.refreshFinancialAvailibity = this.refreshFinancialAvailibity.bind(this);
      this.refreshGroupFinancialAvailibity = this.refreshGroupFinancialAvailibity.bind(this);
      this.addNewReport = this.addNewReport.bind(this);
      this.addNewAnnualReport = this.addNewAnnualReport.bind(this);
      this.addNewQuarterlyReport = this.addNewQuarterlyReport.bind(this);
      this.addNewGroupReport = this.addNewGroupReport.bind(this);
      this.handleFinancialGroupReportChange = this.handleFinancialGroupReportChange.bind(this);
      this.handleFinancialYearlyReportChange = this.handleFinancialYearlyReportChange.bind(this);
      this.handleFinancialQuarterlyReportChange = this.handleFinancialQuarterlyReportChange.bind(this);
      this.handleYearlyReportDateChange = this.handleYearlyReportDateChange.bind(this);
      this.handleGroupReportDateChange = this.handleGroupReportDateChange.bind(this);
      this.handleQuarterlyReportDateChange = this.handleQuarterlyReportDateChange.bind(this);
      this.calculateReportRatio = this.calculateReportRatio.bind(this);
      this.handleFileUploadChange = this.handleFileUploadChange.bind(this);
      this.handleGroupFileUploadChange = this.handleGroupFileUploadChange.bind(this);
      this.fetchFinancialReports = this.fetchFinancialReports.bind(this);
      this.updateFinancialReportPLFromApi = this.updateFinancialReportPLFromApi.bind(this);
      this.updateFinancialReportBSFromApi = this.updateFinancialReportBSFromApi.bind(this);
      this.handleCompanyLegislationCheckBox = this.handleCompanyLegislationCheckBox.bind(this);
      this.handleFinancialsNotPublishedCheckBox = this.handleFinancialsNotPublishedCheckBox.bind(this);
      this.handleInterviewContactNotRealsedInfoCheckBox = this.handleInterviewContactNotRealsedInfoCheckBox.bind(this);
      this.handleInterviewNoContactCheckBox = this.handleInterviewNoContactCheckBox.bind(this);
      this.formatDate = this.formatDate.bind(this);
      this.nullifyCapital = this.nullifyCapital.bind(this);
      this.upload = React.createRef();
      this.groupUplaod = React.createRef();
      this.inquiryService = new InquiryService();
      this.inquiryService.getCurrencies().then(data =>{
        if(!this.state.financials.capital || !this.state.financials.currency){
          let default_currency = data.find(currency => currency.country.name === this.props.order_info.country.name)
          /*if(!this.state.financials.capital){
            this.state.financials.capital = {}
            this.state.financials.capital.currency = default_currency
          }*/
          if(!this.state.financials.currency){
            this.state.financials.currency = default_currency
          }
        }

        this.setState({
          currencies: data
        })
      })

  }

  addFmpBsDataToReport(report, fmpReport){
    report["financial_report"]["financial_bs"]["cash_and_bank"] = fmpReport.cashAndCashEquivalents
    report["financial_report"]["financial_bs"]["shortterm_investment"] = fmpReport.shortTermInvestments
    report["financial_report"]["financial_bs"]["net_receivables"] = fmpReport.netReceivables
    report["financial_report"]["financial_bs"]["inventory"] = fmpReport.inventory
    report["financial_report"]["financial_bs"]["other_current_assets"] = fmpReport.otherCurrentAssets
    report["financial_report"]["financial_bs"]["total_current_assets"] = fmpReport.totalCurrentAssets
    report["financial_report"]["financial_bs"]["property_plant_equipment_net"] = fmpReport.propertyPlantEquipmentNet
    report["financial_report"]["financial_bs"]["goodwill"] = fmpReport.goodwill
    report["financial_report"]["financial_bs"]["intangible_assets"] = fmpReport.intangibleAssets
    report["financial_report"]["financial_bs"]["longterm_investments"] = fmpReport.longTermInvestments
    report["financial_report"]["financial_bs"]["other_non_current_assets"] = fmpReport.otherNonCurrentAssets
    report["financial_report"]["financial_bs"]["total_non_current_assets"] = fmpReport.totalNonCurrentAssets
    report["financial_report"]["financial_bs"]["other_assets"] = fmpReport.otherAssets
    report["financial_report"]["financial_bs"]["total_assets"] = fmpReport.totalAssets
    report["financial_report"]["financial_bs"]["account_payables"] = fmpReport.accountPayables
    report["financial_report"]["financial_bs"]["shortterm_debt"] = fmpReport.shortTermDebt
    report["financial_report"]["financial_bs"]["tax_payables"] = fmpReport.taxPayables
    report["financial_report"]["financial_bs"]["deferred_revenue"] = fmpReport.deferredRevenue
    report["financial_report"]["financial_bs"]["other_current_liabilities"] = fmpReport.otherCurrentLiabilities
    report["financial_report"]["financial_bs"]["total_current_liabilities"] = fmpReport.totalCurrentLiabilities
    report["financial_report"]["financial_bs"]["longterm_debt"] = fmpReport.longTermDebt
    report["financial_report"]["financial_bs"]["deferred_revenue_non_current"] = fmpReport.deferredRevenueNonCurrent
    report["financial_report"]["financial_bs"]["deferred_tax_liabilities_non_current"] = fmpReport.deferredTaxLiabilitiesNonCurrent
    report["financial_report"]["financial_bs"]["other_longterm_liabilities"] = fmpReport.otherNonCurrentLiabilities
    report["financial_report"]["financial_bs"]["total_longterm_liabilities"] = fmpReport.totalNonCurrentLiabilities
    report["financial_report"]["financial_bs"]["other_liabilities"] = fmpReport.otherLiabilities
    report["financial_report"]["financial_bs"]["capital_lease_obligations"] = fmpReport.capitalLeaseObligations
    report["financial_report"]["financial_bs"]["total_liabilities"] = fmpReport.totalLiabilities
    report["financial_report"]["financial_bs"]["total_stockholders_equity"] = fmpReport.totalStockholdersEquity
    report["financial_report"]["financial_bs"]["total_liabilities_and_stockholders_equity"] = fmpReport.totalLiabilitiesAndStockholdersEquity
    report["financial_report"]["financial_bs"]["total_equity"] = fmpReport.totalEquity
    report["financial_report"]["financial_bs"]["total_liabilities_and_equity"] = fmpReport.totalLiabilitiesAndTotalEquity
    report["financial_report"]["financial_bs"]["total_investments"] = fmpReport.totalInvestments
    report["financial_report"]["financial_bs"]["total_debt"] = fmpReport.totalDebt


    return report;
  }

  updateFinancialReportBSFromApi(period, report_idx, data){
    let apiReport = data; //data[data.length - 1];
    var financials = this.state.financials;
    if( period === "FY"){
      financials = this.state.financials;
      var yearly_financials = this.state.financials.yearly_financials;
      var report = yearly_financials[report_idx];
     
      // mapping
      //Balance Sheet
      report = this.addFmpBsDataToReport(report, apiReport)

      yearly_financials[report_idx] = report
      financials.yearly_financials = yearly_financials;
      this.setState({financials : financials}, function(){
        this.calculateReportRatio("yearly_financials", report_idx);
        this.dataRefresh();
      });
    } else {
      financials = this.state.financials;
      var quarterly_financials = this.state.financials.quarterly_financials;
      var report = quarterly_financials[report_idx];

      //mapping
      //Balance Sheet
      report = this.addFmpBsDataToReport(report, apiReport)

      quarterly_financials[report_idx] = report
      financials.quarterly_financials = quarterly_financials;

      this.setState({financials : financials}, function(){
        this.calculateReportRatio("quarterly_financials", report_idx);
        this.dataRefresh();
      });
    }
  }
  formatDate(date,date_report){
    let date_arr;

    if(date != undefined){
      date_arr = date.split("-");   
      return date_arr[1] + "/" + date_arr[2]  + "/" +  date_arr[0];
    }else{
      return  date_report.month + "/" + date_report.day  + "/" +  date_report.year
    }
  }
  addFmpPlDataToReport(report, fmpReport){
    
    let date_arr = fmpReport.date.split("-"); 
    report["financial_report"].year = date_arr[0]
    report["financial_report"].month = date_arr[1]
    report["financial_report"].day = date_arr[2]
    
    report["financial_report"]["financial_pl"]["date"] = fmpReport.date
    report["financial_report"]["financial_pl"]["revenue"] = fmpReport.revenue
    report["financial_report"]["financial_pl"]["cost_of_revenue"] = fmpReport.costOfRevenue
    report["financial_report"]["financial_pl"]["gross_profit"] = fmpReport.grossProfit
    report["financial_report"]["financial_pl"]["research_and_development_expenses"] = fmpReport.researchAndDevelopmentExpenses
    report["financial_report"]["financial_pl"]["selling_general_and_admin_expenses"] = fmpReport.sellingGeneralAndAdministrativeExpenses
    report["financial_report"]["financial_pl"]["other_expenses"] = fmpReport.otherExpenses
    report["financial_report"]["financial_pl"]["total_operating_expenses"] = fmpReport.operatingExpenses
    report["financial_report"]["financial_pl"]["interest_income"] = fmpReport.interestIncome
    report["financial_report"]["financial_pl"]["interest_expense"] = fmpReport.interestExpense
    report["financial_report"]["financial_pl"]["operating_income"] = fmpReport.operatingIncome
    report["financial_report"]["financial_pl"]["other_income"] = fmpReport.totalOtherIncomeExpensesNet
    report["financial_report"]["financial_pl"]["income_before_tax"] = fmpReport.incomeBeforeTax
    report["financial_report"]["financial_pl"]["net_income"] = fmpReport.netIncome
    report["financial_report"]["financial_pl"]["earnings_per_share"] = fmpReport.eps
    report["financial_report"]["financial_pl"]["diluted_earnings_per_share"] = fmpReport.epsdiluted
   
    return report
  }

  updateFinancialReportPLFromApi(period, report_idx, data ){
  
    let apiReport = data; //data[data.length - 1];
     //Set the date base api date
   
    if( period == "FY"){
      var financials = this.state.financials;
      var yearly_financials = this.state.financials.yearly_financials;

      var report = yearly_financials[report_idx];
      
      // mapping
      // P&L
      report = this.addFmpPlDataToReport(report, apiReport)

      yearly_financials[report_idx] = report
      financials.yearly_financials = yearly_financials;
      this.setState({financials : financials}, function(){
        this.calculateReportRatio("yearly_financials", report_idx);
        this.dataRefresh();
      });
    } else { //QUARTERLY HERE
      var financials = this.state.financials;
      var quarterly_financials = this.state.financials.quarterly_financials;
      var report = quarterly_financials[report_idx];
      //mapping
      // P&L
      report = this.addFmpPlDataToReport(report, apiReport)
      quarterly_financials[report_idx] = report
      financials.quarterly_financials = quarterly_financials;
      this.setState({financials : financials}, function(){
        this.calculateReportRatio("quarterly_financials", report_idx);
        this.dataRefresh();
      });
    }

  }


  fetchFinancialReports(period, year, quarter, report_idx){
    if(!this.state.financials.ticker || this.state.financials.ticker.trim() == ''){
      this.state.toast.current.show({severity:'error', summary:'Missing Information', detail: "Please Enter a Ticker."});
      return;
    }
    let d = new Date();
    let limit = 100;
    let currentYear = d.getFullYear();
    var currentQuarter = Math.floor((d.getMonth() + 3) / 3);
    if (period == "FY"){
      // yearly financials
     // limit = currentYear - year;
      if(limit == 0){
        this.state.toast.current.show({severity:'error', summary:'Canceled', detail: "Results within the same year are not allowed ."});
        return;
      }
      // p&L
      let pl_url = this.state.pl_fmp_url + this.state.financials.ticker  + '?limit=' + limit + '&apikey=' + this.state.fmp_api_key
      axios.get(pl_url).then(response => {
        if(response.data.length == 0){
          this.state.toast.current.show({severity:'warn', summary:'Loaded', detail: "No P&L Results Found"});
        } else {
          this.state.toast.current.show({severity:'info', summary:'Loaded', detail: "P&L info Loaded"});
          let reportData = response.data.find(yearData => yearData.calendarYear === year);
          this.updateFinancialReportPLFromApi(period, report_idx, reportData)
        }
      }).catch(err => {
        this.state.toast.current.show({severity:'error', summary:'Error', detail: "P&L Loading Failed"});
      })

      // Balance sheet
      let bs_url = this.state.bs_fmp_url + this.state.financials.ticker  + '?limit=' + limit + '&apikey=' + this.state.fmp_api_key
      axios.get(bs_url).then(response => {
        if(response.data.length == 0){
          this.state.toast.current.show({severity:'warn', summary:'Loaded', detail: "No Balance Sheet Results Found"});
        } else {
          this.state.toast.current.show({severity:'info', summary:'Loaded', detail: "Balance Sheet info Loaded"});
          let reportData = response.data.find(yearData => yearData.calendarYear === year);
          this.updateFinancialReportBSFromApi(period, report_idx, reportData)
        }
      }).catch(err => {
        this.state.toast.current.show({severity:'error', summary:'Error', detail: "Balance Sheet Loading Failed"});
      })
    } else {
      // quarterly financials
      if(quarter == null){
        this.state.toast.current.show({severity:'error', summary:'Canceled', detail: "Please select a quarter."});
        return;
      }
      let q_num = quarter
      quarter = quarter.replace("Q", "");
     //let limit = (currentYear - year) * 10 - ( quarter - currentQuarter);
      let limit = 100;
     
    /*  if(currentYear == year)  {
        this.state.toast.current.show({severity:'error', summary:'Canceled', detail: "Results within the same year are not allowed ."});
        return;
      } */
      // p&L
      let pl_url = this.state.pl_fmp_url + this.state.financials.ticker  + '?limit=' + limit + '&apikey=' + this.state.fmp_api_key + '&period=quarter'
      axios.get(pl_url).then(response => {
        if(response.data.length == 0){
          this.state.toast.current.show({severity:'warn', summary:'Loaded', detail: "No Quarterly P&L Results Found"});
        } else {
          
          this.state.toast.current.show({severity:'info', summary:'Loaded', detail: "Quarterly P&L info Loaded"});
          //let data = response.data.filter(d => (d.period == q_num) && (d.calendarYear == year))
          let data = response.data.filter(function(d){
              let date_arr = d.date.split("-");
              return date_arr[0] == year;
          });
          data.reverse();
          this.updateFinancialReportPLFromApi(period, report_idx, data[quarter-1])
        }
      }).catch(err => {
        this.state.toast.current.show({severity:'error', summary:'Error', detail: "Quarterly P&L Loading Failed"});
      })

      // Balance sheet
      let bs_url = this.state.bs_fmp_url + this.state.financials.ticker  + '?limit=' + limit + '&apikey=' + this.state.fmp_api_key + '&period=quarter'
      axios.get(bs_url).then(response => {
        if(response.data.length == 0){
          this.state.toast.current.show({severity:'warn', summary:'Loaded', detail: "No Quarterly Balance Sheet Results Found"});
        } else {
          this.state.toast.current.show({severity:'info', summary:'Loaded', detail: "Quarterly Balance Sheet info Loaded"});
        // let data = response.data.filter(d => (d.period == q_num) && (d.calendarYear == year))

          let data = response.data.filter(function(d){
            let date_arr = d.date.split("-");
            return date_arr[0] == year;
          });
          data.reverse();

          this.updateFinancialReportBSFromApi(period, report_idx, data[quarter-1])
         // this.updateFinancialReportBSFromApi(period, report_idx, response.data)
        }
      }).catch(err => {
        this.state.toast.current.show({severity:'error', summary:'Error', detail: "Quarterly Balance Sheet Loading Failed"});
      })

    }

  }

  parseFileName(fileName){
    let parts = fileName.split("/");
    return parts[parts.length - 1] !== "null" ? parts[parts.length - 1] : "" ;
  }

  handleGroupFileUploadChange(event){
    if (!event.files) {
      return;
    }
    var bodyFormData = new FormData();
    for (let i in event.files){
      bodyFormData.append("file_" + i, event.files[i]);
    }
    this.inquiryService.uploadGroupFinancialReport(this.props.order_info.id, this.props.report_id, bodyFormData)
      .then(response => {
        this.setState({groupFinancialFiles: response}, () => {
          this.state.toast.current.show({severity:'success', summary:'Imported', detail: ""});
          this.groupUplaod.current.clear();
        })

      })
      .catch(err => {
        this.state.toast.current.show({severity:'error', summary:'Failed', detail: JSON.stringify(err.response.data)});
      })
  }

  handleFileUploadChange(event){
    if (!event.files) {
      return;
    }
    var bodyFormData = new FormData();
    for (let i in event.files){
      bodyFormData.append("file_" + i, event.files[i]);
    }
    this.inquiryService.uploadFinancialReport(this.props.order_info.id, this.props.report_id, bodyFormData)
      .then(response => {
        this.setState({financialFiles: response}, () => {
          this.state.toast.current.show({severity:'success', summary:'Imported', detail: ""});
          this.upload.current.clear();
        })

      })
      .catch(err => {
        this.state.toast.current.show({severity:'error', summary:'Failed', detail: JSON.stringify(err.response.data)});
      })
  }

  dataRefresh(){
    let financials = this.state.financials;
    financials.group_financial_report_files = this.state.groupFinancialFiles
    financials.financial_report_files = this.state.financialFiles
    sessionStorage.setItem('ses_financials_' + this.props.report_id, JSON.stringify(financials));
  }

  nullifyCapital(){
    let financials = this.state.financials
    financials["capital"] = null
    this.setState({financials: financials}, function(){
      this.dataRefresh();
    })
  }

  refreshGroupFinancialAvailibity(event){
    var financials = this.state.financials
    if(event.target.value){
      financials.group_availability = event.target.value
    } else {
      financials.group_availability = null
      financials.group_financials = [];
    }

    this.setState({financials : financials}, function(){
      this.dataRefresh();
    })
  }
  refreshFinancialAvailibity(event){
    var financials = this.state.financials
    financials.availability = event.target.value
    if(event.target.value != "NA"){
      financials.not_available_reason = null
      financials.no_interview_reason = null
    }
    this.setState({financials : financials}, function(){
      this.dataRefresh();
    })
  }

  handleQuarterlyReportDateChange(report_idx, event){
    var financials = this.state.financials;
    var value = event.target.value;
    var quarterly_financials = this.state.financials.quarterly_financials;
    var report = quarterly_financials[report_idx];
    if (event.target.name == "year"){
      report["financial_report"]["year"] = value;
    }
    if (event.target.name == "quarter"){
      report["financial_report"]["quarter"] = value;
    }

    quarterly_financials[report_idx] = report
    financials.quarterly_financials = quarterly_financials;

    this.setState({financials : financials}, function(){
      this.dataRefresh();
    });
  }

  handleYearlyReportDateChange(report_idx, event){
    var financials = this.state.financials;
    var yearly_financials = this.state.financials.yearly_financials;
    var report = yearly_financials[report_idx]
    report["financial_report"]["year"] = event.target.value
    report["financial_report"]["month"] = null
    report["financial_report"]["day"] = null
    yearly_financials[report_idx] = report
    financials.yearly_financials = yearly_financials;

    this.setState({financials : financials}, function(){
      this.dataRefresh();
    });
  }

  handleGroupReportDateChange(report_idx, event){
    var d = new Date(event.target.value);
    const month = d.getMonth() + 1;
    const day = d.getDate();
    const year = d.getFullYear();
    var financials = this.state.financials;
    var value = event.target.value;
    var group_financials = this.state.financials.group_financials;
    var report = group_financials[report_idx]
    report["financial_report"]["year"] = year
    report["financial_report"]["month"] = month
    report["financial_report"]["day"] = day
    group_financials[report_idx] = report
    financials.group_financials = group_financials;
    this.setState({financials : financials}, function(){
      this.dataRefresh();
    });
  }

  calculateReportRatio(report_type, report_idx){
    var financials = this.state.financials;
    let report_list = financials[report_type];
    let report = report_list[report_idx];

    //ROE total_shareholder_equity
    let netIncome = report["financial_report"]["financial_pl"]["net_income"];
    let total_shareholder_equity = report["financial_report"]["financial_bs"]["total_stockholders_equity"];
    if(netIncome != null && total_shareholder_equity != null){
      let return_on_equity = netIncome / total_shareholder_equity;
      if(!isNaN(eval(return_on_equity))){
        report['financial_report']["financial_ratio"]["return_on_equity"] = eval(return_on_equity);
      } else {
        report['financial_report']["financial_ratio"]["return_on_equity"] = null
      }

    } else {
      report['financial_report']["financial_ratio"]["return_on_equity"] = null
    }

    //ROA
    let total_assets = report["financial_report"]["financial_bs"]["total_assets"]
    if(netIncome != null && total_assets != null){
      let return_on_assets = netIncome / total_assets;
      if(!isNaN(eval(return_on_assets))){
        report['financial_report']["financial_ratio"]["return_on_assets"] = eval(return_on_assets);
      } else {
        report['financial_report']["financial_ratio"]["return_on_assets"] = null
      }

    } else {
      report['financial_report']["financial_ratio"]["return_on_assets"] = null
    }

    //NetProfit Margin
    let revenue = report["financial_report"]["financial_pl"]["revenue"];
    let cost_of_goods_sold = report["financial_report"]["financial_pl"]["cost_of_revenue"];

    if(revenue != null && cost_of_goods_sold != null){
      let profit_margin = (revenue - cost_of_goods_sold) / revenue;
      if(!isNaN(eval(profit_margin))){
        report['financial_report']["financial_ratio"]["profit_margin"] = eval(profit_margin);
      } else {
        report['financial_report']["financial_ratio"]["profit_margin"] = null
      }

    } else {
      report['financial_report']["financial_ratio"]["profit_margin"] = null
    }

    //Gross Margin
    if(revenue != null && cost_of_goods_sold != null){
      let gross_margin = revenue - cost_of_goods_sold;
      if(!isNaN(eval(gross_margin))){
        report['financial_report']["financial_ratio"]["gross_margin"] = eval(gross_margin);
      } else {
        report['financial_report']["financial_ratio"]["gross_margin"] = null
      }

    } else {
      report['financial_report']["financial_ratio"]["gross_margin"] = null
    }

    // Average collection period
    let account_receivable = report["financial_report"]["financial_bs"]["net_receivables"];
    if(revenue != null && account_receivable != null){
      let collection_period = (account_receivable / cost_of_goods_sold) / 365;
      if(!isNaN(eval(collection_period))){
        report['financial_report']["financial_ratio"]["collection_period"] = eval(collection_period);
      } else {
        report['financial_report']["financial_ratio"]["collection_period"] = null
      }

    } else {
      report['financial_report']["financial_ratio"]["collection_period"] = null
    }

    //Current Ration
    let current_assets = report["financial_report"]["financial_bs"]["total_current_assets"];
    let current_liabilities = report["financial_report"]["financial_bs"]["total_current_liabilities"];
    if(current_assets != null && current_liabilities != null){
      let current_ratio = current_assets / current_liabilities;
      if(!isNaN(eval(current_ratio))){
        report['financial_report']["financial_ratio"]["current_ratio"] = eval(current_ratio);
      } else {
        report['financial_report']["financial_ratio"]["current_ratio"] = null
      }

    } else {
      report['financial_report']["financial_ratio"]["current_ratio"] = null
    }

    // Quick ratio
    let inventory = report["financial_report"]["financial_bs"]["inventory"];
    if(inventory != null && current_liabilities != null && current_assets != null){
      let quick_ration = (current_assets - inventory) / current_liabilities;
      if(!isNaN(eval(quick_ration))){
        report['financial_report']["financial_ratio"]["quick_ratio"] = eval(quick_ration);
      } else {
        report['financial_report']["financial_ratio"]["quick_ratio"] = null
      }

    } else {
      report['financial_report']["financial_ratio"]["quick_ratio"] = null
    }
    // Debt to asset Ratio
    let total_liabilities = report["financial_report"]["financial_bs"]["total_liabilities"];
    if(total_assets != null && total_liabilities != null){
      let debt_to_asset_ratio = total_liabilities / total_assets
      if(!isNaN(eval(debt_to_asset_ratio))){
        report['financial_report']["financial_ratio"]["debt_to_asset_ratio"] = eval(debt_to_asset_ratio);
      } else {
        report['financial_report']["financial_ratio"]["debt_to_asset_ratio"] = null
      }
    }else {
      report['financial_report']["financial_ratio"]["debt_to_asset_ratio"] = null
    }

    // Debt to equiry Ratio
    if(total_assets != null && total_shareholder_equity != null){
      let debt_to_equity_ratio = total_liabilities / total_shareholder_equity
      if(!isNaN(eval(debt_to_equity_ratio))){
        report['financial_report']["financial_ratio"]["debt_to_equity_ratio"] = eval(debt_to_equity_ratio);
      } else {
        report['financial_report']["financial_ratio"]["debt_to_equity_ratio"] = null
      }
    }else {
      report['financial_report']["financial_ratio"]["debt_to_equity_ratio"] = null
    }

    if (report_type == "yearly_financials"){
      let lastYearReport = null
      try {
        lastYearReport = report_list.find(yearlyReport => eval(yearlyReport.financial_report.year) == eval(report.financial_report.year) - 1)
      } catch(err){
        console.log(err)
      }
      if(lastYearReport){
        // Sales Growth Rate
        let lastYearRevenue = lastYearReport["financial_report"]["financial_pl"]["revenue"];
        if(revenue && lastYearRevenue){
          let sales_growth_rate = (revenue - lastYearRevenue) / (lastYearRevenue * 100);
          report['financial_report']["financial_ratio"]["sales_growth_rate"] = sales_growth_rate
        } else {
          report['financial_report']["financial_ratio"]["sales_growth_rate"] = null
        }
        // NetProfit Growth Rate
        let lastYearNetIncome = lastYearReport["financial_report"]["financial_pl"]["net_income"];
        if(netIncome && lastYearNetIncome){
          let net_profit_growth_rate = (revenue - lastYearNetIncome) / (lastYearNetIncome * 100);
          report['financial_report']["financial_ratio"]["net_profit_growth_rate"] = net_profit_growth_rate
        } else {
          report['financial_report']["financial_ratio"]["net_profit_growth_rate"] = null
        }
      }

    }

    report_list[report_idx] = report;
    financials[report_type] = report_list;
    this.setState({financials : financials}, function(){
      this.dataRefresh();
    });
  }


  handleFinancialInfoChange(field, event){
    var financials = this.state.financials
    if (field == "capital"){
      if (event.target.value){
        if (!financials.capital){
          financials.capital = {}
        }
        if (event.target.name == "currency"){
          financials[field]["currency"] = event.target.value
        } else {
          financials[field]["value"] = event.target.value
        }
      } else {
        financials.capital = null
      }
    } else{
      financials[field] = event.target.value
    }

    this.setState({financials : financials}, function(){
        this.dataRefresh();
    })
  }

  handleFinancialYearlyReportChange(report_idx, childObject_idx, field, event){
        var financials = this.state.financials;
        var value = event.target.value;
        var yearly_financials = this.state.financials.yearly_financials;
        var report = yearly_financials[report_idx]
        report["financial_report"][childObject_idx][field] = value
        yearly_financials[report_idx] = report
        financials.yearly_financials = yearly_financials;
        this.setState({financials : financials}, function(){
          this.calculateReportRatio("yearly_financials", report_idx);
          this.dataRefresh();
        });

  }

  handleFinancialQuarterlyReportChange(report_idx, childObject_idx, field, event){
        var financials = this.state.financials;
        var value = event.target.value;
        var quarterly_financials = this.state.financials.quarterly_financials;
        var report = quarterly_financials[report_idx]
        report["financial_report"][childObject_idx][field] = isNaN(eval(value)) ? null : eval(value);
        quarterly_financials[report_idx] = report
        financials.quarterly_financials = quarterly_financials;
        this.setState({financials : financials}, function(){
          this.calculateReportRatio("quarterly_financials", report_idx);
          this.dataRefresh();
        });

  }

  handleFinancialGroupReportChange(report_idx, childObject_idx, field, event){
        var financials = this.state.financials;
        var value = event.target.value;
        var group_financials = this.state.financials.group_financials;
        var report = group_financials[report_idx]
        report["financial_report"][childObject_idx][field] = value
        group_financials[report_idx] = report
        financials.group_financials = group_financials;
        this.setState({financials : financials}, function(){
          this.calculateReportRatio("group_financials", report_idx);
          this.dataRefresh();
        });

  }


  addNewAnnualReport(){
    var financials = this.state.financials;
    var yearly_financials = this.state.financials.yearly_financials;
    let financialReport = {"financial_report" : {"year": "2015", "month": null, "day": null, "quarter": null, "financial_pl": {"revenue": null}, "financial_bs": {}, "financial_ratio": {}}}
    yearly_financials = [financialReport, ...yearly_financials]
    //yearly_financials.push(financialReport)
    financials.yearly_financials = yearly_financials;
    this.setState({financials : financials}, function(){
      this.dataRefresh();
    });
  }

  addNewQuarterlyReport(){
    var financials = this.state.financials;
    var quarterly_financials = this.state.financials.quarterly_financials;
    let financialReport = {"financial_report" : {"year": "2015", "month": null, "day": null, "quarter": "Q1", "financial_pl": {"revenue": null}, "financial_bs": {}, "financial_ratio": {}}}
    quarterly_financials = [financialReport, ...quarterly_financials]
    //yearly_financials.push(financialReport)
    financials.quarterly_financials = quarterly_financials;
    this.setState({financials : financials}, function(){
        this.dataRefresh();
    });
  }

  addNewReport(){
    var financials = this.state.financials;
    var yearly_financials = this.state.financials.yearly_financials;
    let financialReport = {"financial_report" : {"year": "2015", "month": null, "day": null, "quarter": null, "financial_pl": {"revenue": null}, "financial_bs": {}, "financial_ratio": {}}}
    yearly_financials = [financialReport, ...yearly_financials]
    //yearly_financials.push(financialReport)
    financials.yearly_financials = yearly_financials;
    this.setState({financials : financials}, function(){
      this.dataRefresh();
    });

  }

  addNewGroupReport(){
    var financials = this.state.financials;
    var group_financials = this.state.financials.group_financials;
    group_financials.push({"financial_report" : {"year": "2015", "month": "01", "day": "01", "financial_pl": {}, "financial_bs": {}, "financial_ratio": {}}});
    financials.group_financials = group_financials;
    this.setState({financials : financials});
    this.dataRefresh();
  }

  deleteReport(report_idx, type){
    var financials = this.state.financials;
    if (type === "quarter"){
      let reports = financials.quarterly_financials;
      reports.splice(report_idx,  1);
      financials.quarterly_financials = reports;
    } else if (type === "year"){
      let reports = financials.yearly_financials;
      reports.splice(report_idx,  1);
      financials.yearly_financials = reports;
    } else {
      let reports = financials.group_financials;
      reports.splice(report_idx,  1);
      financials.group_financials = reports;
    }

    this.setState({financials: financials }, function(){
      this.dataRefresh();
    })
  }

  renderAnnualReportDate(report, i){
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <Dropdown value = {report.financial_report.year} options={this.state.years} name="year"
              onChange={(e)=>this.handleYearlyReportDateChange(i, e)} placeholder="Select a Year"/>
          </div>

        </div>
        <div className="row">
            <div className="col-2">
              <Button  onClick={() => this.deleteReport(i, "year")} className="p-button-rounded pi pi-trash p-button-text p-button-danger"
                iconPos="left" style={{'width': '15%'}} />
            </div>
            <div className="col-10">
              <Button  className="p-button-rounded pi pi-cloud-download p-button-info"
                iconPos="right" label="Get Financial Report" onClick = {() => this.fetchFinancialReports("FY", report.financial_report.year, null, i)} />
            </div>
        </div>
      </div>
    )
  }

  renderQuarterlyReportDate(report, i){
    return (
      <div>
        <div className="row">
          <div className="col-4">
            <Dropdown value = {report.financial_report.year} options={this.state.years} name="year" style={{maxWidth: 'fit-content'}}
              onChange={(e)=>this.handleQuarterlyReportDateChange(i, e)} placeholder="Select a Year"/>
          </div>
          <div className="col-6">
            <Dropdown value = {report.financial_report.quarter} options={['Q1', 'Q2', 'Q3', 'Q4']} name="quarter" style={{maxWidth: 'fit-content'}}
              onChange={(e)=>this.handleQuarterlyReportDateChange(i, e)} placeholder="Select a Quarter"/>
          </div>

        </div>
        <div className="row">
          <div className="col-2">
            <Button  onClick={() => this.deleteReport(i, "quarter")} className="p-button-rounded pi pi-trash p-button-text p-button-danger"
              iconPos="left" style={{'width': '15%'}} />
          </div>

            <div className="col-10">
              <Button  className="p-button-rounded pi pi-cloud-download p-button-info"
                iconPos="right" label="Get Financial Report" onClick = {() => this.fetchFinancialReports("quarter", report.financial_report.year, report.financial_report.quarter, i)} />
            </div>
        </div>

      </div>
    )
  }

  renderReportDate(report, i){
    const options = [{value: "quarterly", label: "Quarterly"}, {value:"yearly", label : "Yearly"}]
    if (report["financial_report"]["quarter"] === null){
      //year
      return (
        <div>
          <div className="row">
            <div className="col-12">
              <Dropdown value = {report.financial_report.year} options={this.state.years} name="year"
                onChange={(e)=>this.handleYearlyReportDateChange(i, e)} placeholder="Select a Year"/>
            </div>

          </div>
          <div className="row">
            <div className="col-12">
              <Dropdown  options={options} name="year" value = "yearly"
                  onChange={(e)=>this.toggleDate(i, "quarter")} placeholder="Select Type"/>
            </div>
          </div>
          <div className="row">
              <div className="col-2">
                <Button  onClick={() => this.deleteReport(i, "year")} className="p-button-rounded pi pi-trash p-button-text p-button-danger"
                  iconPos="left" style={{'width': '15%'}} />
              </div>
              <div className="col-10">
                <Button  className="p-button-rounded pi pi-cloud-download p-button-info"
                  iconPos="right" label="Get Financial Report" onClick = {() => this.fetchFinancialReports("FY", report.financial_report.year, null, i)} />
              </div>
          </div>
        </div>
      )

    } else {
      //quarter report
      return (
        <div>
          <div className="row">
            <div className="col-4">
              <Dropdown value = {report.financial_report.year} options={this.state.years} name="year" style={{maxWidth: 'fit-content'}}
                onChange={(e)=>this.handleQuarterlyReportDateChange(i, e)} placeholder="Select a Year"/>
            </div>
            <div className="col-6">
              <Dropdown value = {report.financial_report.quarter} options={['Q1', 'Q2', 'Q3', 'Q4']} name="quarter" style={{maxWidth: 'fit-content'}}
                onChange={(e)=>this.handleQuarterlyReportDateChange(i, e)} placeholder="Select a Quarter"/>
            </div>

          </div>
          <div className="row">
            <div className="col-12">
              <Dropdown options={options} name="year" value = "quarterly"
                  onChange={(e)=>this.toggleDate(i, "year")} placeholder="Select Type"/>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <Button  onClick={() => this.deleteReport(i, "quarter")} className="p-button-rounded pi pi-trash p-button-text p-button-danger"
                iconPos="left" style={{'width': '15%'}} />
            </div>

              <div className="col-10">
                <Button  className="p-button-rounded pi pi-cloud-download p-button-info"
                  iconPos="right" label="Get Financial Report" onClick = {() => this.fetchFinancialReports("quarter", report.financial_report.year, report.financial_report.quarter, i)} />
              </div>
          </div>

        </div>
      )
    }


  }

  toggleDate(i, value){
    var financials = this.state.financials
    let yearly_financials = financials.yearly_financials
    let quarterly_financials = financials.quarterly_financials
    if (value === "year"){
      let report = quarterly_financials[i]
      quarterly_financials.splice(i,  1);
      report["financial_report"]["month"] = null
      report["financial_report"]["day"] = null
      report["financial_report"]["quarter"] = null
      report["type"] = "yearly"
      yearly_financials.push(report)
    } else {
      let report = yearly_financials[i]
      yearly_financials.splice(i,  1);
      report["financial_report"]["quarter"] = "Q1"
      report["type"] = "quarterly"
      quarterly_financials.push(report)
    }
    financials.yearly_financials = yearly_financials
    financials.quarterly_financials = quarterly_financials
    this.setState({financials: financials}, function(){
      this.dataRefresh();
    })
  }

  renderQuarterlyReport(){
    let financials = this.state.financials.quarterly_financials
    financials.sort(this.compare)
    return (
      <div className="form-group financial-report-container" style={{margin:"25px"}}>
        <div className = "row">
          <div className = "col-2">
            <span className="form-title" style={{marginRight:"25px"}}>{<Translation>{ t => <span>{t('ADD_QUARTER')} </span>}</Translation>}</span>
            <Button icon="pi pi-plus"  onClick={this.addNewQuarterlyReport} className="p-button-square p-button-secondary" />
          </div>
          {
            financials.map((report, i) =>
              <div className="col-2">
                <span>
                  {this.renderQuarterlyReportDate(report, i)}
                </span>
              </div>
            )
          }
        </div>
        <div className="row">
          <span className="form-title">{<Translation>{ t => <span>{t('PROFIT_&_LOSS')} </span>}</Translation>}</span>
        </div>
        {
          this.state.pl_fields.map((field) =>
            <div className="row" style = {{marginTop:"8px"}}>
              <div className = "col-2">
                <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
              </div>
                {
                  financials.map((report, i) =>
                    <div className="col-2" style = {{marginLeft:"10px"}}>     
                      {
                          (field == "date")?
                            this.formatDate(report.financial_report?.financial_pl.date , report.financial_report)
                          : 
                          <FormattedNumberComponent update_value={(e) => this.handleFinancialQuarterlyReportChange(i, "financial_pl", field, e)}
                        value={report.financial_report?.financial_pl[field] != null ? report.financial_report?.financial_pl[field] : ''} />
                        }
                    
                    </div>)
                  }
            </div>
          )
        }
        <br></br>
        <div className="row">
          <span className="form-title">{<Translation>{ t => <span>{t('BALANCE_SHEET')} </span>}</Translation>}</span>
        </div>
        {
          this.state.bs_fields.map((field) =>
            <div className="row" style = {{marginTop:"8px"}}>
              <div className = "col-2">
                <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
              </div>
                {
                  financials.map((report, i) =>
                    <div className="col-2" style = {{marginLeft:"10px"}}>
                        <FormattedNumberComponent update_value={(e) => this.handleFinancialQuarterlyReportChange(i, "financial_bs", field, e)}
                        value={report.financial_report?.financial_bs[field] != null ? report.financial_report?.financial_bs[field] : ''} />
                    </div>)
                  }
            </div>
          )
        }
        <br></br>
        <div className="row">
          <span className="form-title">{<Translation>{ t => <span>{t('RATIOS')} </span>}</Translation>}</span>
        </div>
        {
          this.state.ratio_fields.map((field) =>
            <div className="row" style = {{marginTop:"8px"}}>
              <div className = "col-2">
                <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
              </div>
                {
                  financials.map((report, i) =>
                    <div className="col-2" style = {{marginLeft:"10px"}}>
                    <input type = "text" disabled className="form-control form-control-sm"
                       value={report.financial_report?.financial_ratio[field] != null && !isNaN(report.financial_report?.financial_ratio[field]) ? parseFloat(report.financial_report?.financial_ratio[field]).toFixed(2) : ''}/>
                    </div>
                  )
                }
            </div>
          )
        }
        <br></br>
        <div className="row">
          <div className="col-sm-2"><label className="form-field-bold">{<Translation>{ t => <span>{t('ADDITIONAL_COMMENTS')} </span>}</Translation>}</label>  </div>
          <div className="col-sm-5"><textarea className="form-control form-control-sm" value={this.state.financials.comments} onChange={(e) => this.handleFinancialInfoChange("comments", e)}>
                </textarea>
          </div>
        </div>
      </div>
    )
  }

  compare( a, b ) {
    if ( a.financial_report.year < b.financial_report.year ){
      return -1;
    }
    if ( a.financial_report.year > b.financial_report.year ){
      return 1;
    }
    if (a.financial_report.quarter && b.financial_report.quarter){
      if ( a.financial_report.quarter < b.financial_report.quarter ){
        return -1;
      }
      if ( a.financial_report.quarter > b.financial_report.quarter ){
        return 1;
      }
    } else {
      return 0;
    }
  }

  renderAnnualReport(){

    let financials = this.state.financials.yearly_financials
    financials.sort(this.compare)
    return (
      <div className="form-group financial-report-container" style={{margin:"25px"}}>
        <div className = "row">
          <div className = "col-2">
            <span className="form-title" style={{marginRight:"25px"}}>{<Translation>{ t => <span>{t('ADD_YEAR')} </span>}</Translation>}</span>
            <Button icon="pi pi-plus"  onClick={this.addNewReport} className="p-button-square p-button-secondary" />
          </div>

          {
            financials.map((report, i) =>
              <div className="col-2">
                <span>
                  {this.renderAnnualReportDate(report, i)}
                
                </span>
              </div>
            )
          }
        </div>
        <div className="row">
          <span className="form-title">{<Translation>{ t => <span>{t('PROFIT_&_LOSS')} </span>}</Translation>}</span>
        </div>
        {
          this.state.pl_fields.map((field) =>
            <div className="row" style = {{marginTop:"8px"}}>

              <div className = "col-2">
                <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
              </div>
              {
                financials.map((report, i) =>{
                  return(
                    <div className="col-2" style = {{marginLeft:"10px"}}>
                        {
                          (field == "date")?
                          this.formatDate(report.financial_report?.financial_pl.date,report.financial_report)
                          : 
                          <FormattedNumberComponent update_value={(e) => this.handleFinancialYearlyReportChange(i, "financial_pl", field, e)}
                        value={report.financial_report?.financial_pl[field] != null ? report.financial_report?.financial_pl[field] : ''} />
                        }
                       
                       
                    </div>
                  )
                })
                }
            </div>
          )
        }
        <br></br>
        <div className="row">
          <span className="form-title">{<Translation>{ t => <span>{t('BALANCE_SHEET')} </span>}</Translation>}</span>
        </div>
        {
          this.state.bs_fields.map((field) =>
            <div className="row" style = {{marginTop:"8px"}}>
              <div className = "col-2">
                <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
              </div>
              {
                financials.map((report, i) => {
                  return (
                    <div className="col-2" style = {{marginLeft:"10px"}}>
                        <FormattedNumberComponent update_value={(e) => this.handleFinancialYearlyReportChange(i, "financial_bs", field, e)}
                        value={report.financial_report?.financial_bs[field] != null ? report.financial_report?.financial_bs[field] : ''} />
                    </div>
                  )
                })
                }
            </div>
          )
        }
        <br></br>
        <div className="row">
          <span className="form-title">{<Translation>{ t => <span>{t('RATIOS')} </span>}</Translation>}</span>
        </div>
        {
          this.state.ratio_fields.map((field) =>
            <div className="row" style = {{marginTop:"8px"}}>
              <div className = "col-2">
                <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
              </div>
              {
                financials.map((report, i) =>
                  <div className="col-2" style = {{marginLeft:"10px"}}>
                  <input type = "text" disabled className="form-control form-control-sm"                   
                    value={report.financial_report?.financial_ratio[field] != null && !isNaN(report.financial_report?.financial_ratio[field]) ? parseFloat(report.financial_report?.financial_ratio[field]).toFixed(2) : ''}/>
                  </div>)
                }
            </div>
          )
        }
        <br></br>
        <div className="row">
          <div className="col-sm-2"><label className="form-field-bold">{<Translation>{ t => <span>{t('ADDITIONAL_COMMENTS')} </span>}</Translation>}</label>  </div>
          <div className="col-sm-5"><textarea className="form-control form-control-sm" value={this.state.financials.comments} onChange={(e) => this.handleFinancialInfoChange("comments", e)}>
                </textarea>
          </div>
        </div>
      </div>
    )
  }

  renderReport(){
    return (
      <div className="form-group financial-report-container" style={{margin:"25px"}}>
        <div className = "row">
          <div className = "col-2">
            <span className="form-title" style={{marginRight:"25px"}}>{<Translation>{ t => <span>{t('ADD_YEAR')} </span>}</Translation>}</span>
            <Button icon="pi pi-plus"  onClick={this.addNewReport} className="p-button-square p-button-secondary" />
          </div>

          {
            this.state.financials.yearly_financials.map((report, i) =>
              <div className="col-2">
                <span>
                  {this.renderReportDate(report, i)}
                </span>
              </div>
            )
          }
          {
            this.state.financials.quarterly_financials.map((report, i) =>
              <div className="col-2">
                <span>
                  {this.renderReportDate(report, i)}
                </span>
              </div>
            )
          }
        </div>
        <div className="row">
          <span className="form-title">{<Translation>{ t => <span>{t('PROFIT_&_LOSS')} </span>}</Translation>}</span>
        </div>
        {
          this.state.pl_fields.map((field) =>
            <div className="row" style = {{marginTop:"8px"}}>
              <div className = "col-2">
                <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
              </div>
              {
                this.state.financials.yearly_financials.map((report, i) =>
                  <div className="col-2" style = {{marginLeft:"10px"}}>
                      <FormattedNumberComponent update_value={(e) => this.handleFinancialYearlyReportChange(i, "financial_pl", field, e)}
                      value={report.financial_report?.financial_pl[field] ? report.financial_report?.financial_pl[field] : ''} />
                  </div>)
                }
                {
                  this.state.financials.quarterly_financials.map((report, i) =>
                    <div className="col-2" style = {{marginLeft:"10px"}}>
                       {
                          (field == "date")?
                          this.formatDate(report.financial_report?.financial_pl.date,report.financial_report)
                          : 
                          <FormattedNumberComponent update_value={(e) => this.handleFinancialQuarterlyReportChange(i, "financial_pl", field, e)}
                        value={report.financial_report?.financial_pl[field] != null ? report.financial_report?.financial_pl[field] : ''} />
                        }
                    </div>)
                  }
            </div>
          )
        }
        <br></br>
        <div className="row">
          <span className="form-title">{<Translation>{ t => <span>{t('BALANCE_SHEET')} </span>}</Translation>}</span>
        </div>
        {
          this.state.bs_fields.map((field) =>
            <div className="row" style = {{marginTop:"8px"}}>
              <div className = "col-2">
                <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
              </div>
              {
                this.state.financials.yearly_financials.map((report, i) =>
                  <div className="col-2" style = {{marginLeft:"10px"}}>
                      <FormattedNumberComponent update_value={(e) => this.handleFinancialYearlyReportChange(i, "financial_bs", field, e)}
                      value={report.financial_report?.financial_bs[field] ? report.financial_report?.financial_bs[field] : ''} />
                  </div>)
                }
                {
                  this.state.financials.quarterly_financials.map((report, i) =>
                    <div className="col-2" style = {{marginLeft:"10px"}}>
                        <FormattedNumberComponent update_value={(e) => this.handleFinancialQuarterlyReportChange(i, "financial_bs", field, e)}
                        value={report.financial_report?.financial_bs[field] ? report.financial_bs?.financial_pl[field] : ''} />
                    </div>)
                  }
            </div>
          )
        }
        <br></br>
        <div className="row">
          <span className="form-title">{<Translation>{ t => <span>{t('RATIOS')} </span>}</Translation>}</span>
        </div>
        {
          this.state.ratio_fields.map((field) =>
            <div className="row" style = {{marginTop:"8px"}}>
              <div className = "col-2">
                <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
              </div>
              {
                this.state.financials.yearly_financials.map((report, i) =>
                  <div className="col-2" style = {{marginLeft:"10px"}}>
                  <input type = "text" disabled className="form-control form-control-sm"
                    value={report.financial_report?.financial_ratio[field] ? report.financial_report?.financial_ratio[field] : ''}/>
                  </div>)
                }
                {
                  this.state.financials.quarterly_financials.map((report, i) =>
                    <div className="col-2" style = {{marginLeft:"10px"}}>
                    <input type = "text" disabled className="form-control form-control-sm"
                      value={report.financial_report?.financial_ratio[field] ? report.financial_report?.financial_ratio[field] : ''}/>
                    </div>
                  )
                }
            </div>
          )
        }
        <br></br>
        <div className="row">
          <div className="col-sm-2"><label className="form-field-bold">{<Translation>{ t => <span>{t('ADDITIONAL_COMMENTS')} </span>}</Translation>}</label>  </div>
          <div className="col-sm-5"><textarea className="form-control form-control-sm" value={this.state.financials.comments} onChange={(e) => this.handleFinancialInfoChange("comments", e)}>
                </textarea>
          </div>
        </div>
      </div>
    )
  }

  renderReport2(){
    return (
      <div className="form-group financial-report-container" style={{margin:"25px"}}>
        <div className = "row">

          <div className="col-2">
            <div className="row">
              <span className="form-title" style={{marginRight:"25px"}}>{<Translation>{ t => <span>{t('ADD_YEAR')} </span>}</Translation>}</span> <Button icon="pi pi-plus"  onClick={this.addNewReport} className="p-button-square p-button-secondary" />
            </div>
            <br/><br/><br/><br/><br/>

            <div className="row">
              <span className="form-title">{<Translation>{ t => <span>{t('PROFIT_&_LOSS')} </span>}</Translation>}</span>
            </div>
            {
              this.state.pl_fields.map((field) =>
                <div className="row" style = {{marginTop:"8px"}}>
                  <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
                </div>
              )
            }
            <br></br>
            <div className="row">
              <span className="form-title">{<Translation>{ t => <span>{t('BALANCE_SHEET')} </span>}</Translation>}</span>
            </div>
            {
              this.state.bs_fields.map((field) =>
                <div className="row" style = {{marginTop:"8px"}}>
                  <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
                </div>
              )
            }
            <br></br>
            <div className="row">
              <span className="form-title">{<Translation>{ t => <span>{t('RATIOS')} </span>}</Translation>}</span>
            </div>
            {
              this.state.ratio_fields.map((field) =>
                <div className="row" style = {{marginTop:"8px"}}>
                  <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
                </div>
              )
            }
          </div>

          {
            this.state.financials.yearly_financials.map((report, i) =>
              <div className="col-sm-2" style = {{marginLeft:"10px"}}>
                <div className="row">
                  <span>
                    {this.renderReportDate(report, i)}
                  </span>
                </div>
                <br/><br/>
                {
                  this.state.pl_fields.map((field) =>

                    <div className = "row" style={{paddingTop: '1px'}}>
                     {report.financial_report?.financial_pl[field] }
                      <FormattedNumberComponent update_value={(e) => this.handleFinancialYearlyReportChange(i, "financial_pl", field, e)}
                        value={report.financial_report?.financial_pl[field] ? report.financial_report?.financial_pl[field] : ''} />
                    </div>
                  )
                }
                <br></br>
                <div className = "row">
                      <p></p>
                </div>
                {
                  this.state.bs_fields.map((field) =>
                    <div className = "row" style={{paddingTop: '1px'}}>
                      <FormattedNumberComponent update_value={(e) => this.handleFinancialYearlyReportChange(i, "financial_bs", field, e)}
                        value={report.financial_report?.financial_bs[field] ? report.financial_report?.financial_bs[field] : ''} />
                    </div>
                  )
                }
                <br></br>
                <div className = "row">
                      <p></p>
                </div>
                {
                  this.state.ratio_fields.map((field) =>
                    <div className = "row" style={{paddingTop: '1px'}}>
                      <input type = "text" disabled className="form-control form-control-sm"
                        value={report.financial_report?.financial_ratio[field] ? report.financial_report?.financial_ratio[field] : '' }/>
                    </div>
                  )
                }

              </div>
            )
          }
          { /*** END HERE */
            this.state.financials.quarterly_financials.map((report, i) =>
              <div className="col-sm-2" style = {{marginLeft:"10px"}}>
                <div className="row">
                <span>
                  {this.renderReportDate(report, i)}

                </span>
                </div>
                <br/><br/>
                {
                  this.state.pl_fields.map((field) =>
                    <div className = "row" style={{paddingTop: '1px'}}>
                      <FormattedNumberComponent update_value={(e) => this.handleFinancialQuarterlyReportChange(i, "financial_pl", field, e)}
                        value={report.financial_report?.financial_pl[field] ? report.financial_report?.financial_pl[field] : ''} />
                    </div>
                  )
                }
                <br></br>
                <div className = "row">
                      <p></p>
                </div>
                {
                  this.state.bs_fields.map((field) =>
                    <div className = "row" style={{paddingTop: '1px'}}>
                      <FormattedNumberComponent update_value={(e) => this.handleFinancialQuarterlyReportChange(i, "financial_bs", field, e)}
                        value={report.financial_report?.financial_bs[field] ? report.financial_report?.financial_bs[field] : ''} />
                    </div>
                  )
                }
                <br></br>
                <div className = "row">
                      <p></p>
                </div>
                {
                  this.state.ratio_fields.map((field) =>
                    <div className = "row" style={{paddingTop: '1px'}}>
                      <input type = "text" disabled className="form-control form-control-sm"
                        value={report.financial_report?.financial_ratio[field] ? report.financial_report?.financial_ratio[field] : ''  }/>
                    </div>
                  )
                }
              </div>
            )
          }

        </div>
        <br></br>
        <div className="row">
          <div className="col-sm-2"><label className="form-field-bold">{<Translation>{ t => <span>{t('ADDITIONAL_COMMENTS')} </span>}</Translation>}</label>  </div>
          <div className="col-sm-5"><textarea className="form-control form-control-sm" value={this.state.financials.comments} onChange={(e) => this.handleFinancialInfoChange("comments", e)}>
                </textarea>
          </div>
        </div>


      </div>
    )
  }

  renderGrouReport(){
    let financials = this.state.financials.group_financials
    return(
      <div className="form-group financial-report-container" style={{margin:"25px"}}>
        <div className = "row">
          <div className="col-2">
              <span className="form-title" style={{marginRight:"25px"}}>{<Translation>{ t => t('ADD_YEAR')}</Translation>}</span>
              <Button icon="pi pi-plus"  onClick={this.addNewGroupReport} className="p-button-square p-button-secondary" />
          </div>
          {
            financials.map((report, i) =>
              <div className="col-2">
                <div className="row">
                  <Calendar  name="award.date_" onChange= {(e) => this.handleGroupReportDateChange(i, e)} placeholder="date" type="text" value={new Date(report.financial_report.month + "/" + report.financial_report.day + "/" + report.financial_report.year)} class="form-control form-control-sm"/>
                  <Button  onClick={() => this.deleteReport(i, "group")} className="p-button-rounded pi pi-trash p-button-text p-button-danger"
                    iconPos="left" style={{'width': '15%'}} />
                </div>
              </div>
            )
          }
      </div>
      <div className="row">
        <span className="form-title">{<Translation>{ t => <span>{t('PROFIT_&_LOSS')} </span>}</Translation>}XX</span>
      </div>
      {
        this.state.pl_fields.map((field) =>
          <div className="row" style = {{marginTop:"8px"}}>
            <div className = "col-2">
              <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
            </div>
            {
              financials.map((report, i) =>
                <div className="col-2" style = {{marginLeft:"10px"}}>
                    <FormattedNumberComponent update_value={(e) => this.handleFinancialGroupReportChange(i, "financial_pl", field, e)}
                    value={report.financial_report?.financial_pl[field] != null ? report.financial_report?.financial_pl[field] : ''} />
                </div>)
              }
          </div>
        )
      }
      <br></br>
      <div className="row">
        <span className="form-title">{<Translation>{ t => <span>{t('BALANCE_SHEET')} </span>}</Translation>}</span>
      </div>
      {
        this.state.bs_fields.map((field) =>
          <div className="row" style = {{marginTop:"8px"}}>
            <div className = "col-2">
              <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
            </div>
            {
              financials.map((report, i) =>
                <div className="col-2" style = {{marginLeft:"10px"}}>
                    <FormattedNumberComponent update_value={(e) => this.handleFinancialGroupReportChange(i, "financial_bs", field, e)}
                    value={report.financial_report?.financial_bs[field] != null ? report.financial_report?.financial_bs[field] : ''} />
                </div>)
              }
          </div>
        )
      }
      <br></br>
      <div className="row">
        <span className="form-title">{<Translation>{ t => <span>{t('RATIOS')} </span>}</Translation>}</span>
      </div>
      {
        this.state.ratio_fields.map((field) =>
          <div className="row" style = {{marginTop:"8px"}}>
            <div className = "col-2">
              <span className="form-field-bold" >{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</span>
            </div>
            {
              financials.map((report, i) =>
                <div className="col-2" style = {{marginLeft:"10px"}}>
                <input type = "text" disabled className="form-control form-control-sm"
                  value={report.financial_report?.financial_ratio[field] != null ? report.financial_report?.financial_ratio[field] : ''}/>
                </div>)
              }
          </div>
        )
      }
      <br></br>
      <div className="row">
        <div className="col-sm-2"><label className="form-field-bold">{<Translation>{ t => <span>{t('ADDITIONAL_COMMENTS')} </span>}</Translation>}</label>  </div>
        <div className="col-sm-5"><textarea className="form-control form-control-sm" value={this.state.financials.group_report_comments} onChange={(e) => this.handleFinancialInfoChange("group_report_comments", e)} > </textarea></div>
      </div>


    </div>
    )
  }
  renderGroupPartialReport(){
    return(
      <div>
        <div className="form-group" style={{margin:"10px"}}>
          <br></br>
          <div className="row">
            <div className="col-sm-1"><label className="form-field-bold">{<Translation>{ t => <span>{t('CURRENCY')} </span>}</Translation>}</label>  </div>
            <div className="col-sm-3">
              <Dropdown value = {this.state.financials.currency} options={this.state.currencies} name="currency"
                  onChange={(e)=>this.handleFinancialInfoChange("currency", e)} optionLabel="code" placeholder={<Translation>{ t => <span>{t('SELECT_CURRENCY')} </span>}</Translation>} filter/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1"><label className="form-field-bold">{<Translation>{ t => <span>{t('UNIT')} </span>}</Translation>}</label>  </div>
            <div className="col-sm-3">
              <Dropdown value = {this.state.financials.unit} options={['Thousands', 'Millions', 'Billions']} name="unit" showClear
                  onChange={(e)=>this.handleFinancialInfoChange("unit", e)}  placeholder={<Translation>{ t => <span>{t('SELECT_UNIT')} </span>}</Translation>} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1"><label className="form-field-bold">{<Translation>{ t => <span>{t('SOURCES')} </span>}</Translation>}</label>  </div>
            <div className="col-sm-3">
              <SourcesComponent sources = {this.state.financials.sources} update_date = {(e) => this.handleFinancialInfoChange("sources", e)}/>
            </div>
          </div>
        </div>
        <div>
          {this.renderGrouReport()}
        </div>
      </div>
    )
  }

  renderGroupReports(){
    if (this.state.financials.group_availability == "FULL"){
      return (
        <div className="form-group" style={{margin:"10px"}}>
          <div className="row">
            <div className="col-sm-2"><label className="form-field-bold">{<Translation>{ t => <span>{t('UPLOAD_FILE')} </span>}</Translation>}</label>  </div>
            <div className="col-sm-5">
              <FileUpload ref={this.groupUplaod} customUpload uploadHandler={this.handleGroupFileUploadChange}
                onRemove = {this.handleGroupFileUploadChange} name="file" maxFileSize="1000000" />
                <i>*Please Click on Upload to add your files to the report.</i>
            </div>
              <div className="col-sm-4">
                  <UploadedFilesComponent download_path = "/wand2/order/report/financials/group/upload/" uploaded_files = {this.state.groupFinancialFiles}
                    toast = {this.state.toast} update_files =  {(e) => {this.setState({groupFinancialFiles: e.value}, function(){this.dataRefresh();})}}/>
              </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-sm-2"><label className="form-field-bold">{<Translation>{ t => <span>{t('ADDITIONAL_COMMENTS')} </span>}</Translation>}</label>  </div>
            <div className="col-sm-5"><textarea className="form-control form-control-sm" value={this.state.financials.group_report_comments} onChange={(e) => this.handleFinancialInfoChange("group_report_comments", e)}> </textarea></div>
          </div>
        </div>
      )
    }else if (this.state.financials.group_availability == "PARTIAL"){
      return this.renderGroupPartialReport()
    }
  }

  renderFinancialsFileUpload(){
    if(this.state.financials.availability === "FULL"){
      return (
        <div className="row">
          <div className="col-sm-1">
            <label className="form-field-bold">
              {<Translation>{ t => <span>{t('UPLOAD_FILE')} </span>}</Translation>}
            </label>
          </div>

          <div  className="col-sm-3">
            <FileUpload ref={this.upload} customUpload uploadHandler={this.handleFileUploadChange} name="file" maxFileSize="1000000" />
            <i>*Please Click on Upload to add your files to the report.</i>
          </div>

          <div className="col-sm-4">
            <UploadedFilesComponent download_path = "/wand2/order/report/financials/upload/" uploaded_files = {this.state.financialFiles}
              toast = {this.state.toast} update_files =  {(e) => {this.setState({financialFiles: e.value}, function(){this.dataRefresh();})}}/>
          </div>
        </div>
      )
    }
  }
  renderAvailableReports(){
      if (this.state.financials.availability === "PARTIAL" || this.state.financials.availability === "FULL" ){
        return (
          <div>
            <div className="form-group" style={{margin:"10px"}}>
              <br></br>
              <div className="row">
                <div className="col-sm-1"><label className="form-field-bold">{<Translation>{ t => <span>{t('CURRENCY')} </span>}</Translation>}</label>  </div>
                <div className="col-sm-3">
                  <Dropdown value = {this.state.financials.currency} options={this.state.currencies} name="currency"
                        onChange={(e)=>this.handleFinancialInfoChange("currency", e)} optionLabel="code" placeholder="Select a Currency" filter/>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-1"><label className="form-field-bold">{<Translation>{ t => <span>{t('UNIT')} </span>}</Translation>}</label>  </div>
                <div className="col-sm-3">
                  <Dropdown value = {this.state.financials.unit} options={['Thousands', 'Millions', 'Billions']} name="unit" showClear
                      onChange={(e)=>this.handleFinancialInfoChange("unit", e)}  placeholder={<Translation>{ t => <span>{t('SELECT_UNIT')} </span>}</Translation>} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-1"><label className="form-field-bold">{<Translation>{ t => <span>{t('SOURCES')} </span>}</Translation>}</label>  </div>
                <div className="col-sm-3">
                  <SourcesComponent sources = {this.state.financials.sources} update_date = {(e) => this.handleFinancialInfoChange("sources", e)}/>
                </div>
              </div>
              {this.renderFinancialsFileUpload()}
            </div>
            <div>
              <label className="form-field-bold">Annual Reports</label>
              {this.renderAnnualReport()}
            </div>
            <div>
              <label className="form-field-bold">Quarterly Reports</label>
              {this.renderQuarterlyReport()}
            </div>
          </div>
        )
      }
  }

  handleCompanyLegislationCheckBox(event){
    let financials = this.state.financials;
    if(event.target.checked == true){
      if(financials.not_available_reason){
        financials.not_available_reason += this.state.company_leg_text
      } else {
        financials.not_available_reason = this.state.company_leg_text
      }

    } else {
      financials.not_available_reason = financials.not_available_reason.replace(this.state.company_leg_text, "")
    }
    this.setState({financials: financials}, function(){
      this.dataRefresh();
    })
  }

  handleFinancialsNotPublishedCheckBox(event){
    let financials = this.state.financials;
    if(event.target.checked == true){
      if(financials.not_available_reason){
        financials.not_available_reason += this.state.financials_not_published_text
      } else {
        financials.not_available_reason = this.state.financials_not_published_text
      }

    } else {
      financials.not_available_reason = financials.not_available_reason.replace(this.state.financials_not_published_text, "")
    }
    this.setState({financials: financials}, function(){
      this.dataRefresh();
    })
  }

  handleInterviewContactNotRealsedInfoCheckBox(event){
    let financials = this.state.financials;
    if(event.target.checked == true){
      financials.no_interview_reason = this.state.interview_contact_not_release
    } else {
      financials.no_interview_reason = null
    }
    this.setState({financials: financials}, function(){
      this.dataRefresh();
    })
  }

  handleInterviewNoContactCheckBox(event){
    let financials = this.state.financials;
    if(event.target.checked == true){
      financials.no_interview_reason = this.state.interview_cant_contact
    } else {
      financials.no_interview_reason = null
    }

    this.setState({financials: financials}, function(){
      this.dataRefresh();
    })
  }

  renderUnavailableReport(){
    if (this.state.financials.availability === "NA"){
      return (

        <div className="form-group" style={{margin:"10px"}}>
          <br></br>
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-1"><input type="checkbox" onChange={this.handleCompanyLegislationCheckBox}
                                              checked = {this.state.financials.not_available_reason?.includes(this.state.company_leg_text)}/></div>
            <div className="col-sm-6">{<Translation>{ t => t('COMPANY_LEGISLATIONS_TEXT')}</Translation>}</div>
          </div>

          <br></br>
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-1"><input type="checkbox" onChange={this.handleFinancialsNotPublishedCheckBox}
                                            checked = {this.state.financials.not_available_reason?.includes(this.state.financials_not_published_text)} /></div>
            <div className="col-sm-6">{<Translation>{ t => t('COMPANY_HAS_NOT_PUBLISH_FIN_RECORD')}</Translation>}</div>
          </div>
          <br></br>
          <br></br>
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-1"></div>
            <div className="col-sm-6"><span className="form-title">{<Translation>{ t => <span>{t('INTERVIEW')} </span>}</Translation>} : </span></div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-1"><input type="checkbox" onChange={this.handleInterviewContactNotRealsedInfoCheckBox}
                                                              checked = {this.state.financials.no_interview_reason == this.state.interview_contact_not_release}/></div>
            <div className="col-sm-6">{<Translation>{ t => t('PERSON_CONTACT_HAS_NOT_RELEASE')}</Translation>}</div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-1"><input type="checkbox" onChange={this.handleInterviewNoContactCheckBox}
                                                              checked = {this.state.financials.no_interview_reason == this.state.interview_cant_contact}/></div>
            <div className="col-sm-6">{<Translation>{ t => t('COULD_NOT_CONTACT')}</Translation>}</div>
          </div>
          <br></br>
          <div className="row">
              <div className="col-sm-3">
                  <label className="form-field-bold">{<Translation>{ t => <span>{t('ADDITIONAL_COMMENTS')} </span>}</Translation>}</label>
              </div>
              <div className="col-sm-7">
                  <textarea autoFocus onBlur={this.handleFinancialChange} onChange={(e) => this.handleFinancialInfoChange("comments", e)} className="form-control form-text-comments" name="comments"
                    value={this.state.financials.comments} />
              </div>
          </div>
        </div>
      )
    }
  }

  render() {
    function handleFinancialChange(e){
          //changeFinancial(e.target.name,e.target.value);

    }

    const options = [{value : true, label: <Translation>{ t => t('YES')}</Translation>}, {value : false, label: <Translation>{ t => t('NO')}</Translation>}];
    const capitalOptions = [{value : "Yes", label: <Translation>{ t => t('YES')}</Translation>}, {value : "No", label: "No"}, {value : "N.A", label: <Translation>{ t => t('NA')}</Translation>}];
    const availableOptions = [{value: "FULL", label: <Translation>{ t => t('FULL_FS')}</Translation>}, {value : "PARTIAL", label: <Translation>{ t => t('PARTIAL_FIGURES')}</Translation>}, {value: "NA", label: <Translation>{ t => t('UNAVAILABLE')}</Translation>}]
    const availableGroupOptions = [
      {value: "FULL", label: <Translation>{ t => t('FULL_FS')}</Translation>},
      {value : "PARTIAL", label: <Translation>{ t => t('PARTIAL_FIGURES')}</Translation>}
    ]
    return (
      <div className="col-12">
          <Toast ref={this.state.toast} />
          <div className="col-sm-6">
                  <div className="form-group">
                      <div className="row">
                          <div className="col-sm-5">
                              <label className="form-field-bold">{<Translation>{ t => <span>{t('CURRENCY_CHANGES')} </span>}</Translation>}</label>
                          </div>
                          <div className="col-sm-7">
                              <input autoFocus onChange={(e) => this.handleFinancialInfoChange("currency_change", e)} name="currency"  value={this.state.financials.currency_change} className="form-control form-control-sm" />
                          </div>
                      </div>
                  </div>
                  <div className="form-group">
                      <div className="row">
                              <div className="col-sm-5">
                                  <label className="form-field-bold">{<Translation>{ t => <span>{t('LISTEDSTOCKEXCHANGE')} </span>}</Translation>}</label>
                              </div>
                              <div className="col-sm-7">
                                  <SelectButton value={this.state.financials.is_stock_listed} options={options}  onChange={(e) => this.handleFinancialInfoChange("is_stock_listed", e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
                              </div>
                      </div>
                  </div>
                  <div className="form-group">
                      <div className="row">
                              <div className="col-sm-5">
                                  <label className="form-field-bold">{<Translation>{ t => <span>{t('STOCK_EXCHANGE')} </span>}</Translation>}</label>
                              </div>
                              <div className="col-sm-7">
                                  <input onChange={(e) => this.handleFinancialInfoChange("stock_exchange", e)} type="text" name="stock_exchange" value={this.state.financials.stock_exchange} class="form-control form-control-sm"/>
                              </div>
                      </div>
                  </div>
                  <div className="form-group">
                      <div className="row">
                              <div className="col-sm-5">
                                  <label className="form-field-bold">{<Translation>{ t => <span>{t('TICKER')} </span>}</Translation>}</label>
                              </div>
                              <div className="col-sm-7">
                                  <input onChange={(e) => this.handleFinancialInfoChange("ticker", e)} type="text" name="ticker" value={this.state.financials.ticker} class="form-control form-control-sm"/>
                              </div>
                      </div>
                  </div>
                  <div className="form-group">
                      <div className="row">
                              <div className="col-sm-5">
                                  <label className="form-field-bold">{<Translation>{ t => <span>{t('CAPITAL_PAIDUP')} </span>}</Translation>}</label>
                              </div>
                              <div className="col-sm-7">
                                  <SelectButton value={this.state.financials.paid_up_capital} options={capitalOptions}  onChange={(e) => this.handleFinancialInfoChange("paid_up_capital", e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
                              </div>
                      </div>
                  </div>
                  <div className="form-group">
                      <div className="row">
                              <div className="col-sm-5">
                                  <label className="form-field-bold">{<Translation>{ t => <span>{t('CAPITAL')} </span>}</Translation>}</label>
                              </div>
                              <div className="col-sm-3">
                                  <FormattedNumberComponent update_value={(e) => this.handleFinancialInfoChange("capital", e, "value")}
                                    value={this.state.financials.capital ? this.state.financials.capital.value : ''} />
                              </div>
                              <div className="col-sm-2">
                                <Dropdown value = {this.state.financials.capital?.currency} options={this.state.currencies} name="currency" onChange={(e)=>this.handleFinancialInfoChange("capital", e)} optionLabel="code" placeholder="Select a Currency" filter/>
                              </div>
                              <div className="col-sm-2">
                                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                                  onClick={this.nullifyCapital} style={{'top': '2px'}} />
                              </div>
                      </div>
                  </div>
                  <div className="form-group">
                      <div className="row">
                              <div className="col-sm-5">
                                  <label className="form-field-bold">{<Translation>{ t => <span>{t('NUMBER_OF_SHARES')} </span>}</Translation>}</label>
                              </div>
                              <div className="col-sm-7">
                                  <FormattedNumberComponent update_value={(e) => this.handleFinancialInfoChange("nbr_of_shares", e)}
                                    value={this.state.financials.nbr_of_shares} />
                              </div>
                      </div>
                  </div>
                  <div className="form-group">
                      <div className="row">
                          <div className="col-sm-5">
                              <label className="form-field-bold">{<Translation>{ t => <span>{t('COMMENTS_ON_CAPITAL')} </span>}</Translation>}</label>
                          </div>
                          <div className="col-sm-7">
                              <textarea onChange={(e) => this.handleFinancialInfoChange("comments_on_Capital", e)}  class="form-control form-text-comments" name="comments_on_Capital"  value={this.state.financials.comments_on_Capital} rows="3"></textarea>
                          </div>
                      </div>
                  </div>

          </div>
          <br></br>
          <div className="form-hr"/>
          <br></br>

          <div className="col-sm-12">
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-2">
                      <span className="form-title">{<Translation>{ t => <span>{t('FINANCIAL_INFO_AVAILABLE')} </span>}</Translation>} :</span>
                    </div>
                    <div className="col-sm-4">
                        <SelectButton value={this.state.financials.availability} options={availableOptions}
                            onChange={(e) => this.refreshFinancialAvailibity(e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
                    </div>
                </div>
            </div>
            <div className="col-sm-7">
              {this.renderUnavailableReport()}
            </div>
            <div className="col-sm-12">
              {this.renderAvailableReports()}
            </div>
          </div>

          <br></br>
          <div className="form-hr"/>
          <br></br>

          <div className="col-sm-6">
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-5">
                        <span className="form-title">{<Translation>{ t => <span>{t('GROUP_FINANCIAL_INFO_AVAILABLE')} </span>}</Translation>}: </span>
                    </div>
                    <div className="col-sm-4">
                        <SelectButton value={this.state.financials.group_availability} options={availableGroupOptions}
                            onChange={(e) => this.refreshGroupFinancialAvailibity(e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
                    </div>
                    <div className="col-sm-2">
                        <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                          onClick = {(e) => this.refreshGroupFinancialAvailibity(e)} value = {null}  />
                    </div>

                </div>
            </div>
          </div>
          <div className="col-sm-12">
            {this.renderGroupReports()}
          </div>
      </div>
    )
  }
}
export default InquiriesDetailsFinancialsInformation
