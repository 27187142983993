import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import CompanyService from '../service/CompanyService';
import Address from '../components/Address.js';
import { SelectButton } from 'primereact/selectbutton';

class Suppliers extends Component {
  constructor(props){
    super(props);
    this.state = {
      suppliers: [],
      loading: true,
      selectedSupplier: {
        id: 0,
        supplier_name: '',
        address: {country:{}}
      },
      toast: this.props.toast,
      address: null
    }
    this.companyService = new CompanyService();
    this.supplierNameBodyTemplate = this.supplierNameBodyTemplate.bind(this);
    this.supplierNameBodyTemplate = this.supplierNameBodyTemplate.bind(this);
    this.saveSupplier = this.saveSupplier.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleSupplierInfoChange = this.handleSupplierInfoChange.bind(this);
    this.handleSelectSupplier = this.handleSelectSupplier.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.companyService.getSuppliers().then(data =>{
      this.setState({
        suppliers: data, loading: false
      })
    })
  }

  cancel(){
    var newSupplier = {
      id: 0,
      supplier_name: '',
      address: {country:{}}
    }
    this.setState({selectedSupplier:newSupplier})
  }

  handleAddressChange(field, address_data){
    let selectedSupplier = this.state.selectedSupplier
    selectedSupplier[field] = address_data
    this.setState({selectedSupplier: selectedSupplier, address: address_data})
  }

  saveSupplier(){
    console.log(!this.state.selectedSupplier.address.country.name)
    if(!this.state.selectedSupplier.address.country.name){
      this.state.toast.current.show({severity:'error', summary:'Failure !', detail: "PLease Select A country"});
      return;
    }
    if(this.state.selectedSupplier.id !== 0){
      // save an existing supplier
      this.companyService.updateSupplier(this.state.selectedSupplier.id, this.state.selectedSupplier).then(data => {
        this.state.toast.current.show({severity:'success', summary:'Updated !', detail: "Supplier Updated !"});
      }).catch(err=>{
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
      })
    } else{
      // create a new supplier

      this.companyService.createSupplier(this.state.selectedSupplier).then(data=>{
        let suppliers = this.state.suppliers
        suppliers.push(data)
        this.setState({suppliers:suppliers, selectedSupplier: {id: 0, supplier_name: '', address: {country:{}}}})
        this.state.toast.current.show({severity:'success', summary:'Created !', detail: "Supplier Created !"});
      }).catch(err=>{
        this.state.toast.current.show({severity:'error', summary:'Failure !', detail: JSON.stringify(err.response.data)});
      })
    }

  }

  handleSupplierInfoChange(field, event){
    let selectedSupplier = this.state.selectedSupplier
    selectedSupplier[field] = event.target.value
    this.setState({selectedSupplier:selectedSupplier})
  }

  handleCountryNameBodyTemplate(rowData){
    return(
      <React.Fragment>
          {rowData.address.country.name}
      </React.Fragment>
    )
  }

  handleSelectSupplier(rowData){
    this.setState({selectedSupplier:rowData, address: rowData.address})
  }
  supplierNameBodyTemplate(rowData){
    return(
      <React.Fragment>
          <a href="#" onClick={() => this.handleSelectSupplier(rowData)}>{rowData.supplier_name}</a>
      </React.Fragment>
    )
  }

  render(){
    const options = [{value : true, label: "Active"}, {value : false, label: "Inactive"}];
    console.log(this.state.selectedSupplier.address)
    return(
      <div className="condiv">
        <div className = "row">
          <div className="col-6" id="dataTable">
            <DataTable ref={(el) => this.dt = el}
                value={this.state.suppliers}
                loading={this.state.loading}
                className="p-datatable-striped"
                dataKey="id"
                rowHover
                //  selection={this.state.selectedName}
                // onSelectionChange={e => this.setState({selectedName: e.value})}
                paginator rows={10}
                emptyMessage="No Suppliers found"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10,25,50]}>
                <Column sortField="supplier_name" filterField="supplier_name" field="supplier_name" header="Name" body={this.supplierNameBodyTemplate} sortable filter filterPlaceholder="Search by username"/>
                <Column sortField="address.country.name" filterField="address.country.name" field="address.country.name" header="Country" body={this.handleCountryNameBodyTemplate} sortable filter filterPlaceholder="Search by last name"/>

            </DataTable>
          </div>
          <div className = "col-6">
            <div className="col-sm-12" style={{'margin': '5px'}}>
              <div className="form-group">
                <div className = "row">
                  <div className="col-sm-2">
                      <label className="form-field-bold">Supplier Name</label>
                  </div>
                  <div className="col-sm-4">
                      <input type="text" onChange={(e)=>this.handleSupplierInfoChange("supplier_name", e)} name="name"
                          value={this.state.selectedSupplier.supplier_name} className="form-control form-control-sm"/>
                  </div>
                </div>
                <div className = "row">
                  <div className="col-sm-2">
                      <label className="form-field-bold">Supplier Status</label>
                  </div>
                  <div className = "col-sm-4" >
                    <SelectButton value={this.state.selectedSupplier.status} options={options}
                        onChange={(e)=>this.handleSupplierInfoChange("status", e)} optionLabel="label" style = {{ "marginLeft" : "10px"}}/>
                  </div>
                </div>
                <div className = "row">
                  <div className="col-sm-2">
                      <label className="form-field-bold">Company Name</label>
                  </div>
                  <div className="col-sm-4">
                      <input type="text" onChange={(e)=>this.handleSupplierInfoChange("company_name", e)} name="name"
                          value={this.state.selectedSupplier.company_name} className="form-control form-control-sm"/>
                  </div>
                </div>
                <div className = "row">
                  <div className="col-sm-2">
                      <label className="form-field-bold">Email</label>
                  </div>
                  <div className="col-sm-4">
                      <input type="email" onChange={(e)=>this.handleSupplierInfoChange("email", e)} name="name"
                          value={this.state.selectedSupplier.email} className="form-control form-control-sm"/>
                  </div>
                </div>
                <div className = "row">
                  <div className="col-sm-2">
                      <label className="form-field-bold">Phone</label>
                  </div>
                  <div className="col-sm-4">
                      <input type="text" onChange={(e)=>this.handleSupplierInfoChange("phone", e)} name="name"
                          value={this.state.selectedSupplier.phone} className="form-control form-control-sm"/>
                  </div>
                </div>
                <div className = "row">
                  <div className="col-sm-2">
                      <label className="form-field-bold">comments</label>
                  </div>
                  <div className="col-sm-4">
                      <textarea onChange={(e)=>this.handleSupplierInfoChange("comments", e)} type="text" name="name"
                          value={this.state.selectedSupplier.comments} className ="form-control form-control-sm"/>
                  </div>
                </div>
                <br></br>
                <div className = "row">
                    <div className="col-sm-1">
                      <label className="form-field-bold">Supplier Address</label>
                    </div>
                    <div className="col-sm-8">
                        <Address AddressChange={this.handleAddressChange} field_name = "address" label = {""}
                            address = {this.state.address}/>
                    </div>

                </div>

              </div>
              <div className="col-sm-9" style={{'marginLeft': '10px'}}>
                <Button onClick={this.saveSupplier} label="Save" className="p-button-sm" icon="pi pi-check" />
                <Button onClick={this.cancel} label="Cancel" className="p-button-sm" icon="pi pi-replay" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Suppliers
