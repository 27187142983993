import React, { Component } from 'react';
import { Translation,withTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import InquiryService from '../service/InquiryService';
import FormattedNumberComponent from '../components/FormattedNumberComponent';

class InquiriesDetailsSummarySummary extends Component{

  constructor(props){
      super(props);
      this.state = {
        creditEvaluation : this.props.credit_evaluation,
        ratingValue: 0,
        currencies: [],
        defaultCurrency: this.props.customer_info.default_currency,
        toggleCreditLabel: 'Set credit to guarantees'
      };
      this.handleCreditEvaluationUpdate = this.handleCreditEvaluationUpdate.bind(this);
      this.nullifyAdvisedCredit = this.nullifyAdvisedCredit.bind(this);
      this.inquiryService = new InquiryService();
      this.inquiryService.getCurrencies().then(data =>{
        this.setState({
          currencies: data
        })
      })

      if (!this.state.creditEvaluation.advised_credit){
        this.state.creditEvaluation.advised_credit = {}
        this.state.creditEvaluation.advised_credit.currency = this.state.defaultCurrency
      }
      const business = sessionStorage.getItem('ses_business_' + this.props.report_id);
      let businessJSON = JSON.parse(business);

      if(!this.state.creditEvaluation.size && businessJSON){
        this.state.creditEvaluation.size = businessJSON.size_range
      }


      const registrationInfo = sessionStorage.getItem('ses_registration_info_' + this.props.report_id);
      let registrationInfoJSON = JSON.parse(registrationInfo);
      if(!this.state.creditEvaluation.experience && registrationInfoJSON){
        if(!registrationInfoJSON.start_date){
          this.state.creditEvaluation.experience = "na"
        } else {
          let startYear = (new Date(registrationInfoJSON.start_date)).getFullYear();
          let thisYear = (new Date()).getFullYear();
          let diff = thisYear - startYear;
          if (diff < 3){
            this.state.creditEvaluation.experience = "young"
          } else if (diff < 10){
            this.state.creditEvaluation.experience = "average"
          } else {
            this.state.creditEvaluation.experience = "good"
          }
        }
      }
  }

  dataRefresh(){
    // function that reads the state and updates the session storage
    sessionStorage.setItem('ses_credit_evaluation_' + this.props.report_id, JSON.stringify(this.state.creditEvaluation));
  }

  nullifyAdvisedCredit(){
    let creditEvaluation = this.state.creditEvaluation
    if (creditEvaluation.advised_credit){
      creditEvaluation["advised_credit"] = null
      this.setState({creditEvaluation: creditEvaluation, toggleCreditLabel: 'Set Credit'}, function(){
        this.dataRefresh();
      })
    } else {
      creditEvaluation["advised_credit"] = {}
      this.setState({creditEvaluation: creditEvaluation, toggleCreditLabel: 'Set Credit to guarantees'}, function(){
        this.dataRefresh();
      })
    }

  }

  handleCreditEvaluationUpdate(field, event){
    let creditEvaluation = this.state.creditEvaluation
    if (field == "advised_credit"){
      if(!creditEvaluation[field]){
        creditEvaluation[field] = {}
      }
      if (!event.target.name){
        event.target.name = "value"
      }
      creditEvaluation[field][event.target.name] = event.target.value
    } else {
      creditEvaluation[field] = event.target.value
    }

    this.setState({creditEvaluation:creditEvaluation}, function(){
      this.dataRefresh();
    })
  }
  updateTextInput(event) {
        this.setState({ratingValue: event.target.value});
  }

  render(){
    const { t, i18n } = this.props;
    const mystyle = {
      color: "black",
      backgroundColor: "grey",
      textAlign: 'center',
      fontWeight: 'bold',
      margin: "20px",
      fontFamily: "Arial"
    }
    const mystyle2 = {
      textAlign: 'center',
      fontWeight: 'bold',
      marginLeft: "20px",
      fontFamily: "Arial"
    }

    const operations = [
      {value: "operating", label: "Operating"},
      {value: "operating-presumed", label : "Operating (presumed)"},      
      {value: "not-confirmed", label: "Could Not be Confirmed"},
      {value: "not-operating", label: "Not Operating"}
     
     
    ]

    const sizeOptions = [
      {value: "NA", label: "N.A"},
      {value: "small", label: "Small - 0 to 49 employees"},
      {value: "medium", label: "Medium - 50 to 499 employees"},
      {value: "large", label: "Large - Over 500 Employees"}
    ]

    const experienceOptions = [
      {value: "good", label: "Good - Over 10 Years"},
      {value: "average", label: "Average - 4 to 9 Years"},
      {value: "young", label: "Young - Less than 3 Years"},
      {value: "na", label: "N/A"}
    ];

    const groupOptions = [
      {value: "international", label: "International"},
      {value: "national", label: "National"},
      {value: "n.a.", label: "N/A"},
      {value: 'none', label: "None"}
    ];

    const tradeReferencesOptions = [
      {value: "average", label: "Average"},
      {value: "good", label: "Good"},
      {value: "negative", label: "Negative"},
      {value: "na", label: "N/A"}
    ];

    const paymentsOptions = [
      {value: "cash", label: "Cash"},
      {value: "long", label: "Long"},
      {value: "regular", label: "Regular"},
      {value: "suspended", label: "Suspended"},
      {value: "to be monitored", label: "To be Monitored"},
      {value: "na", label: "N/A"}
    ];

    const profitabilityOptions = [
      {value: "very good", label: "Very Good"},
      {value: "good", label: "Good"},
      {value: "correct", label: "Correct"},
      {value: "nil", label: "Nil"},
      {value: "medium", label: "Medium"},
      {value: "low", label: "Low"},
      {value: "negative", label: "Negative"},
      {value: "na", label: "N/A"}
    ];

    const indebtnessOptions = [
      {value: "very high", label: "Very High"},
      {value: "high", label: "High"},
      {value: "controlled", label: "Controlled"},
      {value: "nil", label: "Nil"},
      {value: "medium", label: "Medium"},
      {value: "low", label: "Low"},
      {value: "na", label: "N/A"}
    ];

    const legalRecordsOptions = [
      {value: "yes", label: t('YES')},
      {value: "no", label: t('NO')}
    ];

    const generalOverViewOptions = [
      {value: "average", label: "Average"},
      {value: "good", label: "Good"},
      {value: "bad", label: "Bad"},
      {value: "na", label: "N/A"}
    ];

    const cofaceAlaramOptions = [
      {value: "06", label: "POOR LIQUIDITY"},
      {value: "22", label: "COMPANY HAS BEEN IN DIFFICULTIES"},
      {value: "37", label: "THIS COMPANY HAS NO TRADING ACTIVITY"},
      {value: "44", label: "UNABLE TO IDENTIFY THE BUYER WITH THE INFORMATION PROVIDED"},
      {value: "46", label: "BUYER REFUSES TO DISCLOSE INFORMATION"},
      {value: "63", label: "SUBSTANTIAL LOSS RECORDED"},
      {value: "65", label: "DECREASE OF NET WORTH"},
      {value: "66", label: "DECREASE OF SALES"},
      {value: "69", label: "BREACH / FRAUD / EMBEZZLEMENT"},
      {value: "40", label: "DECREASE OF RATING"},
      {value: "77", label: "FIRE/OTHER DISASTER"},
      {value: "83", label: "CRIMINAL INDICATOR"},
      {value: "93", label: "BANKRUPTCY"},
      {value: "20", label: "INSOLVENCY IN COURSE"}
    ]

    const countryRiskOptions = [];
    return (
      <div>
        <div className="form-group">

          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('COMPANY_OPERATIONS')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
            <Dropdown value = {this.state.creditEvaluation.operative_status} showClear options={operations} name="operative_status"
                onChange={(e)=>this.handleCreditEvaluationUpdate("operative_status", e)} optionLabel="label" placeholder="Select an Option"/>
            </div>
          </div>
          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('SIZE')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <Dropdown value = {this.state.creditEvaluation.size} showClear options={sizeOptions} name="sizeOptions"
                onChange={(e)=>this.handleCreditEvaluationUpdate("size", e)} optionLabel="label" placeholder="Select an Option"/>
            </div>
          </div>
          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('EXPERIENCE')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <Dropdown value = {this.state.creditEvaluation.experience} showClear options={experienceOptions} name="experienceOptions"
                onChange={(e)=>this.handleCreditEvaluationUpdate("experience", e)} optionLabel="label" placeholder="Select an Option"/>
            </div>
          </div>
          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('GROUP')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <Dropdown value = {this.state.creditEvaluation.group} showClear options={groupOptions} name="groupOptions"
                onChange={(e)=>this.handleCreditEvaluationUpdate("group", e)} optionLabel="label" placeholder="Select an Option"/>
            </div>
          </div>
          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('TRADE_REFERENCES')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <Dropdown value = {this.state.creditEvaluation.trade_references} showClear options={tradeReferencesOptions} name="tradeReferencesOptions"
                onChange={(e)=>this.handleCreditEvaluationUpdate("trade_references", e)} optionLabel="label" placeholder="Select an Option"/>
            </div>
          </div>
          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('PAYMENTS')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <Dropdown value = {this.state.creditEvaluation.payments} showClear options={paymentsOptions} name="paymentsOptions"
                onChange={(e)=>this.handleCreditEvaluationUpdate("payments", e)} optionLabel="label" placeholder="Select an Option"/>
            </div>
          </div>
          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('PROFITABILITY')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <Dropdown value = {this.state.creditEvaluation.profitability} showClear options={profitabilityOptions} name="profitabilityOptions"
                onChange={(e)=>this.handleCreditEvaluationUpdate("profitability", e)} optionLabel="label" placeholder="Select an Option"/>
            </div>
          </div>
          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('INDEBTEDNESS')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <Dropdown value = {this.state.creditEvaluation.indebtness} showClear options={indebtnessOptions} name="indebtnessOptions"
                onChange={(e)=>this.handleCreditEvaluationUpdate("indebtness", e)} optionLabel="label" placeholder="Select an Option"/>
            </div>
          </div>
          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('Legal_records')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <Dropdown value = {this.state.creditEvaluation.legal_records} showClear options={legalRecordsOptions} name="legalRecordsOptions"
                onChange={(e)=>this.handleCreditEvaluationUpdate("legal_records", e)} optionLabel="label" placeholder="Select an Option"/>
            </div>
          </div>
          <div hidden className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('COUNTRY_RISK')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <Dropdown value = {this.state.creditEvaluation.country_risk} showClear options={countryRiskOptions} name="countryRiskOptions"
                onChange={(e)=>this.handleCreditEvaluationUpdate("country_risk", e)} optionLabel="label" placeholder="Select an Option"/>
            </div>
          </div>
          <div hidden className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('CREDIT_BUREAU_OPINION')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <select id="credit-bureay-opinion-options" name="creditBureauOpinionOptions" value={this.state.creditEvaluation.legal_records} /*onChange={(e)=>this.handleCreditEvaluationUpdate("legal_records", e)}*/>
                  <option disabled selected value> -- Select an Option -- </option>
                  <option value="na">N/A</option>
              </select>
            </div>
          </div>
          <div className="row" >
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('GENERAL_OVERVIEW')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">
              <Dropdown value = {this.state.creditEvaluation.general_overview} showClear options={generalOverViewOptions} name="generalOverViewOptions"
                onChange={(e)=>this.handleCreditEvaluationUpdate("general_overview", e)} optionLabel="label" placeholder="Select an Option"/>
            </div>
          </div>
        </div>

        <br></br>
        <div className="form-hr"/>
        <br></br>

        <div className = "row">
          <div className="col-sm-3">
              <label className="form-field-bold">{<Translation>{ t => <span>{t('CREDIT_RISK_ANALYSIS')} </span>}</Translation>}</label>
          </div>
          <div className="col-sm-9">
              <textarea className="form-control form-text-comments" name="credit-risk-analysis" rows="3" onChange={(e)=>this.handleCreditEvaluationUpdate("credit_risk_analysis", e)} value = {this.state.creditEvaluation.credit_risk_analysis}></textarea>
          </div>
        </div>
        <br></br>
        <div className = "row">
          <div className="col-sm-3">
              <label className="form-field-bold">{<Translation>{ t => <span>{t('COMMENTS_FOR_NEXT_REPORT')} </span>}</Translation>}</label>
          </div>
          <div className="col-sm-9">
              <textarea className="form-control form-text-comments" name="comments" rows="3" onChange={(e)=>this.handleCreditEvaluationUpdate("comments", e)} value = {this.state.creditEvaluation.comments}></textarea>
          </div>
        </div>
        <br></br>
        <div className = "row">
          <div className="col-sm-3" style = {mystyle}>
              <label className="form-field-bold">{<Translation>{ t => <span>{t('REQUIRED_CREDIT')} </span>}</Translation>}</label>
          </div>
          <div className="col-sm-3" style = {mystyle}>
              <label className="form-field-bold">{<Translation>{ t => <span>{t('ADVISED_CREDIT')} </span>}</Translation>}</label>
          </div>
          <div className="col-sm-3" style = {mystyle}>
              <label className="form-field-bold">{<Translation>{ t => <span>{t('RATING')} </span>}</Translation>}</label>
          </div>
        </div>
        <div className = "row">
          <div className="col-sm-3" style = {mystyle2}>
              <input hidden = {this.props.maximum_required_credit} type = "text" id = "required-credit" name="required-credit" className="form-control form-control-sm bold"
                value = {this.props.required_credit?.value  + ' ' + this.props.required_credit?.currency.code} readOnly />
                <input hidden = {!this.props.maximum_required_credit} type = "text" id = "required-credit" name="required-credit" className="form-control form-control-sm bold"
                  value = "MAXIMUM" readOnly />
          </div>
          <div className="col-sm-3" style = {mystyle2}>
            <div className = "row" style = {mystyle2}>
              <div hidden = {!this.state.creditEvaluation.advised_credit} className = "col-sm-6">
                <FormattedNumberComponent name = "value" type='input' update_value={(e) => this.handleCreditEvaluationUpdate("advised_credit", e)}
                  value={this.state.creditEvaluation.advised_credit?.value} />
              </div>
              <div hidden = {!this.state.creditEvaluation.advised_credit} className = "col-sm-6">
                <Dropdown value = {this.state.creditEvaluation.advised_credit?.currency} options={this.state.currencies}
                  onChange={(e)=>this.handleCreditEvaluationUpdate("advised_credit", e)} name = "currency" optionLabel="code" placeholder="Select a Currency" filter/>
              </div>
            </div>
            <div className = "row" style = {mystyle2}>
              <div className = "col-6">
                <Button className="p-button-rounded"
                  onClick={this.nullifyAdvisedCredit} style={{'top': '2px'}} label = {this.state.toggleCreditLabel} />
              </div>

              <div hidden = {this.state.creditEvaluation.advised_credit} className = "col-4">
                <Button disabled label = "Guarantees" className="p-button-rounded p-button-warning" />
              </div>

            </div>

          </div>
          <div className="col-sm-3" style = {mystyle2}>
              <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                onClick={(e) => {this.handleCreditEvaluationUpdate("rating", {target: {value: null, name: 'rating'}})}} style={{'top': '2px'}}  />
              <input type = "range"  id = "rating" value = {this.state.creditEvaluation.rating ? this.state.creditEvaluation.rating : 0} name = "rating" min = "0" max= "20" onChange = {(e) => this.handleCreditEvaluationUpdate("rating", e)} />
              <input type = "text" id = "rating-value" value = {this.state.creditEvaluation.rating ? this.state.creditEvaluation.rating : ''} disabled style={{marginLeft:"10px"}}/>
          </div>
        </div>


        <br></br>
        <br></br>
        <br></br>
        <div className="form-group">
          <div className = "row">
            <div className="col-sm-3">
                <label className="form-field-bold">{<Translation>{ t => <span>{t('COMMENTS_ON_THE_RATING')} </span>}</Translation>}</label>
            </div>
            <div className="col-sm-9">

              <select id="rating-comments-options" value= {this.state.creditEvaluation.comments_on_rating} name="ratingCommentsOption" onChange = {(e) => this.handleCreditEvaluationUpdate("comments_on_rating", e)}>
                  <option disabled selected value> -- Select an Option -- </option>
                  <option value="considered">{t('CREDITLINECONSIDERED')}</option>
                  <option value="caution">{t('SUGGESTCAUTION')}</option>
                  <option value="not-advised">{t('CREDITCORPORATION')}</option>
                  <option value="not-found">{t('COMPANYNOTFOUND')}</option>
              </select>
            </div>
          </div>
          <div className = "row">
            <div className="col-sm-3">
                <label className="form-field-bold">{t('COFACE_ALARM')}</label>
            </div>
            <div className="col-sm-9">
              <Dropdown value = {this.state.creditEvaluation.coface_alarm} options={cofaceAlaramOptions} showClear
                onChange={(e)=>this.handleCreditEvaluationUpdate("coface_alarm", e)} optionLabel="label" placeholder="Select a Coface Alaram" filter/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export default withTranslation()(InquiriesDetailsSummarySummary)
