//import axios  from "axios"; 
import { api } from '../../service/baseService';

const api_url = "https://connect.sandbox.creditsafe.com/v1";

export default class creditSafeService {
  getRequestOption(){
    const tokenJSON = JSON.parse(sessionStorage.getItem("token"))
    const options = {
     headers: {
         'Content-Type' : 'application/json',
         'Accept': '*/*',
         'Authorization': 'JWT ' + tokenJSON.token
     }
   };
   return options
}
initRefreshToken = async()=>{
  let data = await api(process.env.REACT_APP_HOST + '/wand2/creditsafe/token',"get","","").then(res => res.data); 
  return data;
  //sessionStorage.setItem("creditsafe_token" , JSON.stringify(data.token));
}
logApiRequest = async(importData)=>{
  let body = {
    "log_action": "CreditSafe Request",
    "log_details": importData.log_details,   
  }  
  let data = await api(process.env.REACT_APP_HOST + '/wand2/creditsafe/creditsafe_log', "post", JSON.stringify(body) , this.getRequestOption()).then(res => res.data);
  return data;  
}
creditSafeApiReportDownloadsLogsWithPagination = async(limit = 500, offset = 0 , searchParams) =>{  
  return await api(process.env.REACT_APP_HOST + `/wand2/creditsafe/creditsafe_search_log?limit=${limit}&offset=${offset}`, "post", searchParams,this.getRequestOption()).then(res => res.data);
}
searchCreditReport = async(searchData)=>{ 
  const connectId = searchData.safeNo;  
  const searchInfo = {};
  searchInfo.includeIndicators = true;
  searchInfo.template= 'full';
  searchInfo.countries = searchData.country;
  const query = new URLSearchParams(searchInfo).toString();
  
  const resp = await fetch(
    `${api_url}/companies/${connectId}?${query}`,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer '+JSON.parse(sessionStorage.getItem("creditsafe_tokens")).token
      }
    }
  );
  
  const data = JSON.parse(await resp.text()); 
  return data; 
}

getCountries = async()=>{
  const resp = await fetch(`${api_url}/access/countries`,{
      method: 'GET',
      headers: {
        Authorization: 'Bearer '+JSON.parse(sessionStorage.getItem("creditsafe_tokens")).token
      }
    }
  );    
  const data = JSON.parse(await resp.text()); 
  return data; 
}
searchCriteria = async(country)=>{   
  const query =  country.map((country)=>(country.code)).toString(); 
  try{
  const resp = await fetch(`${api_url}/companies/searchcriteria?countries=${query}`,{
      method: 'GET',
      headers: {
        Authorization: 'Bearer '+JSON.parse(sessionStorage.getItem("creditsafe_tokens")).token
      }
    });
    const data = JSON.parse(await resp.text()); 
    return data; 
  } catch(err){
    return "failed";
  }
} 
searchCompany = async(searchData)=>{ 
 // console.log(searchData, JSON.parse(searchData))
   // searchData = JSON.stringify(searchData);
  //  console.log("SS",JSON.stringify(searchData));
    const query = new URLSearchParams(searchData).toString();
    console.log(query);
    /*const query2 = new URLSearchParams({
      page: '1',
      pageSize: '0',
      countries: 'US',
     // safeNo: 'string',
     // regNo: 'string',
     // vatNo: 'string',
      name: 'string',
      tradeName: 'string',
      acronym: 'string',
      exact: 'true',
      address: 'string',
      houseNo: 'string',
      city: 'string',
      postCode: 'string',
      province: 'string',
      officeType: 'registered',
      phoneNo: 'string',
      status: 'Active',
      type: 'NotSet',
      website: 'string',
      customData: 'string'
    }).toString();
    console.log(query); */

    try{
      const resp = await fetch(`${api_url}/companies?${query}`,{
          method: 'GET',
          headers: {
            Authorization: 'Bearer '+JSON.parse(sessionStorage.getItem("creditsafe_tokens")).token
          }
        }
      );    
      const data = JSON.parse(await resp.text());
      return data;
    }catch(err){
        //console.log("===>",err)
        //this.initRefreshToken()
        return "failed";
    }
    
    
   
}
/*
searchExperianBusiness = async(searchData)=>{
  let getExperianUrls = this.getExperianUrls(); 
  let body = {
     "name": searchData.name,
     "city": searchData.city, 
     "state": searchData.state,
     "subcode" : this.experianSubcode(),
     "geo" : true,
     //"api_url" : getExperianUrls.main_hosts + "?targeturl="+getExperianUrls.business_url+'/v1/search',
     "api_url" : getExperianUrls.business_url+'/v1/search',
     "token" : this.getToken()
   }
  console.log("fetching business" , body);
  let data =  await axios.post(process.env.REACT_APP_HOST + '/wand2/experian/experian_business', JSON.stringify(body),this.getRequestOption(),{mode: "no-cors"}).then(res => res.data);
  return data;
}
fetchExperianLegalRecords = async(type, searchData) =>{
  let getExperianUrls = this.getExperianUrls();
  searchData.subcode = this.experianSubcode();
  searchData.token = this.getToken();
  searchData.api_url = getExperianUrls.business_url+'/v1/'+type;
  let data =  await axios.post(process.env.REACT_APP_HOST + '/wand2/experian/experian_business', JSON.stringify(searchData),this.getRequestOption(),{mode: "no-cors"}).then(res => res.data);
  return data;
}
fetchHMTL = async(searchData)=>{
  let getExperianUrls = this.getExperianUrls();
  let body = {
    "bin": searchData.bin,
    "subcode": this.experianSubcode(),
    "token": this.getToken(),
    "name": searchData.businessName,
    "api_url" : getExperianUrls.business_url+'/v1/reports/premierprofiles/html'
  }

  let data =  await axios.post(process.env.REACT_APP_HOST + '/wand2/experian/experian_business', JSON.stringify(body),this.getRequestOption(),{mode: "no-cors"}).then(res => res.data);
  return data;
}*/
}