import React, { Component}  from 'react';
import MainHeader from '../components/MainHeader'
import InquiriesDetails from '../contents/InquiriesDetails'
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { TabView,TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import {formatDate} from '../components/Utils';
import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Translation } from 'react-i18next';
import i18next from "i18next";
import OrderDetails from './OrderDetails.js';
import CompanyDetails from './CompanyDetails.js';
import InquiryService from '../service/InquiryService';
import UserService from '../service/UserService';
import CompanyService from '../service/CompanyService';
import OrderSearch from '../components/OrderSearch.js';
import Parser from 'html-react-parser';

class Inquiries extends Component {
    constructor(props){
        super(props)
        this.orderSearch = React.createRef();
        this.state = {
          orderList: null,
          //Datatables
          globalFilter:  null,
          selectedName: null,
          orderDateFilter: null,
          selectedStatus: 'all',
          selectedInCharge: 'all',
          selectedCountry : 'all',
          selectedClient : 'all',
          selectedSupplier : 'all',
          orderDateFrom: null,
          orderDateTo: null,
          dueDateFrom: null,
          dueDateTo:null,
          deliveryDateFrom: null,
          deliveyrDateTo:null,
          tabs:[],
          inquiries : null,
          filteredInquiries: [],
          activeIndex: 0,
          inCharge: [],
          statuses : [],
          companies : [],
          selectedCompany : '',
          selectedDelay : '',
          delays:[],
          suppliers : [],
          countries: [],
          clients : [],
          filters: [],
          activeFilters: {},
          activeLang : "en",
          users: [],
          groups: [],
          selectedGroup: '',
          qsMyReports: true,
          qsPriorities: false,
          qsLateReports: false,
          toast: this.props.toast,
          selectedCountries: [],
          selectedAnalysts: [],
          selectedClients: [],
          selectedSuppliers: [],
          selectedStatuses: [],
          selectedDelays: [],
          searchloading: false,
          lazyParams: {
                first: 0,
                rows: 50,
                page: 1
            }
        }


        this.inquiryService = new InquiryService();
        this.userService = new UserService();
        this.companyService = new CompanyService();

        //Data Table
        this.orderNameBodyTemplate = this.orderNameBodyTemplate.bind(this);
        this.companyNameBodyTemplate = this.companyNameBodyTemplate.bind(this);
        this.addressBodyTemplate = this.addressBodyTemplate.bind(this);
        this.countryBodyTemplate = this.countryBodyTemplate.bind(this);
        this.clientBodyTemplate = this.clientBodyTemplate.bind(this);
        this.referenceBodyTemplate = this.referenceBodyTemplate.bind(this);
        this.delayBodyTemplate = this.delayBodyTemplate.bind(this);
        this.orderDateBodyTemplate = this.orderDateBodyTemplate.bind(this);
        this.dueDateBodyTemplate = this.dueDateBodyTemplate.bind(this);
        this.deliveryDateBodyTemplate = this.deliveryDateBodyTemplate.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.inChargeBodyTemplate = this.inChargeBodyTemplate.bind(this);
        this.onGlobalSearch = this.onGlobalSearch.bind(this);
        this.handleAddTab = this.handleAddTab.bind(this);
        this.handleCloseTab = this.handleCloseTab.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
        this.updatePriority = this.updatePriority.bind(this);
        this.prioritiesBodyTemplate = this.prioritiesBodyTemplate.bind(this);
        this.updateInCharge = this.updateInCharge.bind(this);
        this.handleAddOrderEditTab = this.handleAddOrderEditTab.bind(this);
        this.updateDetailedSearchFilter = this.updateDetailedSearchFilter.bind(this);
        this.addNewCompanyTab = this.addNewCompanyTab.bind(this);
        this.addNewExperianTab = this.addNewExperianTab.bind(this);
        this.handleNewCompanySave = this.handleNewCompanySave.bind(this);
        this.handleSearchResults = this.handleSearchResults.bind(this);
        this.renderOrdertDetailsTab = this.renderOrdertDetailsTab.bind(this);
        //filters

        this.onOrderDateFilterChange = this.onOrderDateFilterChange.bind(this);


        this.filterDate = this.filterDate.bind(this);       //custom filter function
        this.statusItemTemplate = this.statusItemTemplate.bind(this);
        this.onStatusFilterChange = this.onStatusFilterChange.bind(this);
        this.onTabChange = this.onTabChange.bind(this);

        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);

        this.userService.getStaffSummary().then(data => {
          let admin = data.filter(user => user.user == null)
          let staff = data.filter(user=> user.user != null).filter(user=> user.user['user_deleted'] == 0)
        
          let admin_staff = admin.concat(staff)
          let users_staff = []
          admin_staff.forEach(user => {
            users_staff.push({'id' : user.id , 'username' : user.username})
          })
          this.setState({
           users: users_staff.sort()
          })
        }); 
      
        let ses_token = JSON.parse(sessionStorage.getItem('token'));
        this.userService.getCCPCompanyGroups().then(data =>{
          let new_data = [];
          if(ses_token.user.group === "Standard"){
            data.map((row,i)=> (
                (row.name == ses_token.user.ccp_group) ? new_data[0] = row  : null
            ));
            this.setState({
              selectedGroup: new_data
            })
            this.setState({
              groups: new_data
            })
          }else{
            this.setState({
              groups: data
            })
          }
        });

       /*this.companyService.getCustomersSummary().then(data =>{
          this.setState({
            clients: data
          })
        })
		
		

        this.companyService.getSuppliers().then(data =>{
          this.setState({
            suppliers: data
          })
        })*/
        
      /*  if(!sessionStorage.getItem('ses_delays')){
          this.inquiryService.getDelays().then(data =>{
            this.setState({ delays: data})
            sessionStorage.setItem('ses_delays',JSON.stringify(data))
          })
        } else {
          this.setState({delays : JSON.parse(sessionStorage.getItem('ses_delays')) })  
        }
        */
       // if(!sessionStorage.getItem('ses_countries')){
          this.inquiryService.getCountries().then(data =>{
            this.setState({countries:data})
          //  sessionStorage.setItem('ses_countries',JSON.stringify(data))
          });
       // }else{
       //   this.setState({countries : JSON.parse(sessionStorage.getItem('ses_countries')) })  
       // }

        
        this.inquiryService.getOrderStatusList().then(data =>{
          data.map(field => {
            this.state.statuses.push({label: field[1], value: field[0]})
          })
        })
        /*this.state.statuses = [
          {label: 'PENDING', value: 'PND'},
          {label: 'NEW', value: 'NEW'},
          {label: 'INPROGRESS', value: 'INP'},
          {label: 'EMAIL SENT', value: 'EAS'},
          {label: 'KAR', value: 'KAR'},
          {label: 'COMPLETED', value: 'CMP'},
          {label: 'TO REVIEW', value: 'TRW'},
          {label: 'REVIEWED', value: 'RWD'},
          {label: 'TO CORRECT', value: 'TCT'},
          {label: 'REOPENED', value: 'ROP'},
        ]*/
    }


    onPage(event) {
      this.setState({searchloading: true});
      let lazyParams = { ...this.state.lazyParams, ...event };
      this.setState({ lazyParams },function(){
        this.orderSearch.current.loadLazyData(lazyParams);
      });
    }

    onSort(event) {
      this.setState({searchloading: true});
        let lazyParams = { ...this.state.lazyParams, ...event };
        this.setState({ lazyParams },function(){
          this.orderSearch.current.loadLazyData(lazyParams);
        });
    }

    handleSearchResults(orders, total_records){
      this.setState({inquiries: orders, inquiriesLazy: orders, totalRecords: total_records, searchloading: false});
    }
    componentDidMount() {
      const tab_init = {
          id : 0,
          title: <React.Fragment>
                          <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> Inquiry Search
                  </React.Fragment>,
          content: this.renderTabContent()
      };

      const tabs_arr = this.state.tabs;
      tabs_arr.push(tab_init);
      this.setState({tabs : tabs_arr});

  }

    changeLanguage(lang,e){
        let elem = document.getElementsByClassName('current');

        [].forEach.call(elem, function(el) {
            el.classList.remove("current");
        });

        e.target.classList.add('current');
        i18next.changeLanguage(lang);

    }
    renderLangSelector(){
        return(<span></span>)
    }
    renderQuickSearch(){
        return(
            <div className="component-box dark-blue-bg">
                <div className="row"> <div className="col-sm box-title">Quick<Translation>{ t => <span>{t('SEARCH')} </span>}</Translation></div></div>
                <div className="row">
                    <div className="col-sm">{<Translation>{ t => <span>{t('MYREPORTS')} </span>}</Translation>}</div>
                    <div className="col-sm"><input checked = {this.state.qsMyReports} onChange={() => {this.setState({qsMyReports: !this.state.qsMyReports})}} type="checkbox" name=""/></div>
                </div>
                <div className="row">
                    <div className="col-sm">{<Translation>{ t => <span>{t('PRIORITIES')} </span>}</Translation>} </div>
                    <div className="col-sm"><input checked = {this.state.qsPriorities} onChange={() => {this.setState({qsPriorities: !this.state.qsPriorities})}} type="checkbox" name=""/></div>
                </div>
                <div className="row">
                    <div className="col-sm">{<Translation>{ t => <span>{t('LATEREPORTS')} </span>}</Translation>}</div>
                    <div className="col-sm"><input checked = {this.state.qsLateReports} onChange={() => {this.setState({qsLateReports: !this.state.qsLateReports})}} type="checkbox" name=""/></div>
                </div>
                <div className="row">
                  <div className="col-sm">                 
                    <MultiSelect value={this.state.selectedGroup} options={this.state.groups} optionLabel="name"
                  placeholder="Select a Group" filter="true" onChange = {(e) => this.updateDetailedSearchFilter("selectedGroup", e)} />
                  </div>
                </div>
                <br></br>
                <div hidden className="row">
                  <div className="col-sm">
                  </div>
                  <div className="col-sm">
                    <Button id="search-btn" onClick = {this.submitQuickSearch} label={<Translation>{ t => <span>{t('SUBMIT')} </span>}</Translation>} className="light-blue-bg" icon="pi pi-search" />
                  </div>
                </div>
            </div>
        )
    }

statusItemTemplate(option) {
    return (
        <span className={classNames('customer-badge', 'status-' + option)}>{option}</span>
    );
}

updateDetailedSearchFilter(field, event){
  let state = this.state
  state[field] = event.target.value
  this.setState(state)
}
renderDetailedSearch(){
  return(
    <div>
      <div className="component-box light-blue-bg">
        <div className="row">
          <div className="col-sm box-title">
            {<Translation>{ t => <span>{t('SEARCH')} </span>}</Translation>}
          </div>
        </div>
        <div className="row" id="searchFields">
          <div className="col-sm-2">
            <div className = "row">
              <input type = "text" value = {this.state.detailedSearchValue} className="form-control form-control-sm"
                  onChange = {(e) => {this.setState({detailedSearchValue: e.target.value})}}
                  style = {{'width': '100%','height': '30px'}}/>
            </div>
            <div className = "row">
              <Dropdown value = {this.state.detailedSearchField} options =  {['Company Name', 'Cristal ID', 'Client Reference', 'Registration ID', 'Officer']}
                  onChange = {(e) => {this.setState({detailedSearchField: e.target.value})}}
                  placeholder = "Select a search field" />
            </div>
            <div className = "row">
              <MultiSelect value={this.state.selectedCountries} options={this.state.countries} optionLabel="name"
                  placeholder="Select Countries" filter="true" onChange = {(e) => this.updateDetailedSearchFilter("selectedCountries", e)} />
            </div>
          </div>
          <div style = {{'borderLeft': '2px solid #C0C0C0'}}></div>
          <div className="col-sm-2">
            <div className = "row">
              <MultiSelect value={this.state.selectedAnalysts} options={this.state.users.sort()} optionLabel="username"
                  placeholder="Select Analysts" filter="true" selectAll="true" onChange = {(e) => this.updateDetailedSearchFilter("selectedAnalysts", e)}/>
            </div>
            <div className = "row">
              <MultiSelect value={this.state.selectedClients} options={this.state.clients} optionLabel="legal_name"
                  placeholder="Select Clients" filter="true" selectAll="true" onChange = {(e) => this.updateDetailedSearchFilter("selectedClients", e)} />
            </div>
            <div className = "row">
              <MultiSelect value={this.state.selectedSuppliers} options={this.state.suppliers} optionLabel="supplier_name"
                  placeholder="Select Suppliers" filter="true" selectAll="true" onChange = {(e) => this.updateDetailedSearchFilter("selectedSuppliers", e)} />
            </div>
          </div>
          <div style = {{'borderLeft': '2px solid #C0C0C0'}}></div>
          <div className="col-sm-2">
            <div className = "row">
              <MultiSelect value={this.state.selectedStatuses} options={this.state.statuses} onChange = {(e) => this.updateDetailedSearchFilter("selectedStatuses", e)}
                    placeholder="Select Stasuses" filter="true" optionlabel = "supplier_name" selectAll="true"/>
            </div>
            <div className = "row">
              <MultiSelect value={this.state.selectedDelays} options={this.state.delays} optionLabel="name"
                  placeholder="Select Delays" filter="true" onChange = {(e) => this.updateDetailedSearchFilter("selectedDelays", e)} />
            </div>
          </div>
          <div style = {{'borderLeft': '2px solid #C0C0C0'}}></div>
          <div className="col-md-5">
            <div className="row">
                <div className="col-md-4" style={{'marginTop': '10px'}}>
                    {<Translation>{ t => <span>{t('ORDEREDFROM')} </span>}</Translation>}
                </div>
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-12">
                             <div className="row">
                                <div className="col-5">
                                        <Calendar id="icon" value={this.state.orderDateFrom} onChange={(e) => this.setState({ orderDateFrom: e.value })}
                                            showIcon monthNavigator showButtonBar view="month"/>
                                        <Calendar  view="month" dateFormat="mm/yy" yearNavigator yearRange="2010:2030"/>
                                </div>
                                <div className="col-0">to</div>
                                <div className="col-5">
                                        <Calendar id="icon" value={this.state.orderDateTo} onChange={(e) => this.setState({ orderDateTo: e.value })}
                                            showIcon monthNavigator  showButtonBar/>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4" style={{'marginTop': '10px'}}>
                    <Translation>{ t => <span>{t('DUEDATEFROM')} </span>}</Translation>
                </div>
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-12">
                             <div className="row">
                                  <div className="col-5">
                                          <Calendar id="icon" value={this.state.dueDateFrom} onChange={(e) => this.setState({ dueDateFrom: e.value })} showIcon monthNavigator showButtonBar />
                                  </div>
                                    <div className="col-0">to</div>
                                  <div className="col-5">
                                      <Calendar id="icon" value={this.state.dueDateTo} onChange={(e) => this.setState({ dueDateTo: e.value })} showIcon monthNavigator showButtonBar/>
                                  </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4" style={{'marginTop': '10px'}}>
                    {<Translation>{ t => <span>{t('DELIVERYDATEFROM')} </span>}</Translation>}
                </div>
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-12">
                             <div className="row">
                                <div className="col-5">
                                        <Calendar id="icon" value={this.state.deliveryDateFrom} onChange={(e) => this.setState({ deliveryDateFrom: e.value })} showIcon monthNavigator showButtonBar/>
                                </div>
                                <div className="col-0">to</div>
                                <div className="col-5">
                                        <Calendar id="icon" value={this.state.deliveryDateTo} onChange={(e) => this.setState({ deliveryDateTo: e.value })} showIcon monthNavigator showButtonBar/>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className = "form-hr"> </div>
      <div className="light-blue-bg">
        <div className = "row">
          <div className="col-sm-1">
              <Button loading = {this.state.searchloading} id="search-btn" label={<Translation>{ t => <span>{t('SUBMIT')} </span>}</Translation>}
                    icon="pi pi-search" onClick = {this.handleSubmitSearch} />
          </div>
          <div className="col-md-0" style = {{'borderLeft': '2px solid #FFF'}}></div>
          <div className="col-sm-5">
          </div>
          <div className="col-md-0" style = {{'borderLeft': '2px solid #FFF'}}></div>
          <div className="col-sm-5">
                <div className="row">
                    <div className="col-md-6">
                        <div className="box-report-info bg-blue">{<Translation>{ t => <span>{t('REPORTTOSEND')} </span>}</Translation>} : 5</div>
                    </div>
                    <div className="col-md-6">
                        <div className="box-report-info bg-green">{<Translation>{ t => <span>{t('REPORTTOSEND')} </span>}</Translation>} : 5</div>
                    </div>
                </div>
          </div>
        </div>
      </div>
      </div>
    )
}

//DataTable Functions
orderNameBodyTemplate(rowData) {
  let orderData = rowData;
  let orderName = rowData.company_name;
  let report = rowData.report;
  let inquiryService = this.inquiryService
  const newTab = this.handleAddTab;
  function handleClick(e) {
      if (rowData.status === "PENDING"){
        alert("Report is still not created")
        return
      }
      if(!report){
        inquiryService.createReport(orderData.id).then(data =>{
          e.preventDefault();
          orderData.report = data.report
         newTab(orderData);
        })
      } else {
        e.preventDefault();
       newTab(orderData);
      }

  }
  return (
      <React.Fragment>
           <Tooltip target={"#order-info-tooltip-"+rowData.id}>{orderName}</Tooltip>
           <Button icon="pi pi-eject" iconPos="right" onClick={handleClick} />
           <span id={"order-info-tooltip-"+rowData.id} className = "order-info-tooltip"  style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{orderName}</span>
      </React.Fragment>
  );
}
companyNameBodyTemplate(rowData) {
  let orderData = rowData;
  let report = rowData.report;
  let inquiryService = this.inquiryService

  const newTab = this.handleAddTab;
  function handleClick(e) {
      if (rowData.status === "PENDING"){
        alert("Report is still not created")
        return
      }
      if(!report){
        inquiryService.createReport(orderData.id).then(data =>{
          e.preventDefault();
          orderData.report = data.report
         newTab(orderData);
        })
      } else {
        e.preventDefault();
       newTab(orderData);
      }

  }
  if (!orderData.company_info){
    return (

        <React.Fragment>
           <Tooltip target={"#company-info-tooltip-"+orderData.id}>
              {orderData.company_name}<br/>
              <span className="form-field-bold">Company Info</span><br/>
              <strong> This Order is Not ASsigned to Any Company </strong>
          </Tooltip>
          <span id ={"company-info-tooltip-"+orderData.id} style={{verticalAlign: 'middle', marginLeft: '.5em'}}><a id={report} href="#" onClick={handleClick}>{orderData.registered_name}</a>
          {/*
            <br/>TN : {(orderData.company_info.trade_names != null) ? orderData.company_info.trade_names : ''}
            <br/>PN : {(orderData.company_info.previous_names != null) ? orderData.company_info.previous_names : ''}
          */}
          </span>


        </React.Fragment>
    );
  } else {
      return (

          <React.Fragment>
             <Tooltip target={"#company-info-tooltip-"+orderData.id}>
                {orderData.company_name}<br/>
                <span className="form-field-bold">Company Info</span><br/>

                {<Translation>{ t => t('PREVIOUSNAMES')}</Translation>} : <strong> {orderData.company_info.previous_name} </strong><br/>
                {<Translation>{ t => t('TRADENAMES')}</Translation>} : <strong> {orderData.company_info.trade_names} </strong><br/>
                {<Translation>{ t => t('ADDRESS')}</Translation>}: <br/> <strong> {orderData.address} </strong><br/>
            </Tooltip>
            <span id ={"company-info-tooltip-"+orderData.id} style={{verticalAlign: 'middle', marginLeft: '.5em'}}><a id={report} href="#" onClick={handleClick}>{orderData.registered_name}</a>
            {/*
              <br/>TN : {(orderData.company_info.trade_names != null) ? orderData.company_info.trade_names : ''}
              <br/>PN : {(orderData.company_info.previous_names != null) ? orderData.company_info.previous_names : ''}
            */}
            </span>


          </React.Fragment>
      );
  }

}
addressBodyTemplate(rowData) {
    let address = rowData.address;
    /*if(rowData.address.full_address!=null || typeof(rowData.address.full_address)!="undefined"){
      address = rowData.address.full_address;
    }else{
      address = rowData.address.name + rowData.address.street;
    }*/

    if(address?.length >= 20){
      return (
          <React.Fragment>
              <Tooltip target={"#address-info-tooltip-"+rowData.id}>{address}</Tooltip>
              <span id={"address-info-tooltip-"+rowData.id}  style={{verticalAlign: 'middle', marginLeft: '.5em'}}> {address.substr(0,20) + "..."}</span>
          </React.Fragment>
      );
    }else{
      return (
        <React.Fragment>
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{address}</span>
        </React.Fragment>
      );
    }
}
countryBodyTemplate(rowData){
    return (
        <React.Fragment>
            {rowData.country}
        </React.Fragment>
    );
}
clientBodyTemplate(rowData){
  const client = (rowData.client!= null ) ? rowData.client : "";
  return (
    <React.Fragment>
        <Tooltip target={"#client-info-tooltip-"+rowData.id}>{client}</Tooltip>
        <span id={"client-info-tooltip-"+rowData.id}  style={{verticalAlign: 'middle', marginLeft: '.5em'}}> {client.substr(0,20) + "..."}</span>
    </React.Fragment>
  );

}
referenceBodyTemplate(rowData) {
    let reference = rowData.client_reference;
    const newTab = this.handleAddOrderEditTab;
    let userInfo = JSON.parse(sessionStorage.getItem('token'))
    let isAdmin = userInfo.user.group == 'Admin'
    function handleClick(e) {
        e.preventDefault();
       newTab(rowData);
    }
    if (isAdmin){
      return (
          <React.Fragment>
              <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}><a id={rowData.report} href="#" onClick={handleClick}>{reference}</a></span>
          </React.Fragment>
      );
    } else {
      return (
          <React.Fragment>
              <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{reference}</span>
          </React.Fragment>
      );
    }

}
delayBodyTemplate(rowData){
    return (
        <React.Fragment>
          {rowData.delay}
        </React.Fragment>
    );
}
orderDateBodyTemplate(rowData) {
    if(rowData.order_date){
      let order_date = rowData.order_date.split("-")
      let d = new Date(order_date[0], order_date[1] - 1, order_date[2]);
      return (
          <React.Fragment>
            <span>{formatDate(d)}</span>
          </React.Fragment>
      );
    } else {
      return (
          <React.Fragment>
            <span></span>
          </React.Fragment>
      );
    }
}
dueDateBodyTemplate(rowData) {
    if(rowData.due_date){
      let due_date = rowData.due_date.split("-")
      let d = new Date(due_date[0], due_date[1] - 1, due_date[2]);
      return (
          <React.Fragment>
            <span>{formatDate(d)}</span>
          </React.Fragment>
      );
    } else {
      return (
          <React.Fragment>
            <span></span>
          </React.Fragment>
      );
    }
}
deliveryDateBodyTemplate(rowData) {
    if(rowData.delivery_date){
      let delivery_date = rowData.delivery_date.split("-")
      let d = new Date(delivery_date[0], delivery_date[1] - 1, delivery_date[2]);
      return (
          <React.Fragment>
            <span>{formatDate(d)}</span>
          </React.Fragment>
      );
    } else {
      return (
          <React.Fragment>
            <span></span>
          </React.Fragment>
      );
    }
}

updateStatus(rowData, rowIndex, event){
  let order_status = event.target.value;
  let status = this.state.statuses.filter(status => status.value == order_status)[0]
  this.inquiryService.updateOrderStatus(rowData.id, order_status).then(data =>{
    this.state.toast.current.show({severity:'success', summary:'Updated !', detail:'Order Status Updated'});
    let inquiries = this.state.inquiries
    inquiries[rowIndex]["status"] = status.label
    this.setState({inquiries: inquiries})
  }).catch(err => {
    this.state.toast.current.show({severity:'error', summary:'Something Went Wrong !', detail:JSON.stringify(err)});
  })
}

statusBodyTemplate(rowData, props) {
    let token = JSON.parse(sessionStorage.getItem("token"))
    let statuses = this.state.statuses
    let status = statuses.filter(status => status.label == rowData.status)[0]
    return (
        <React.Fragment>
            <Dropdown tooltip = {status.label} disabled = {token.user.group == "Standard"} optionLabel="label" value={status.value} options={statuses} name={status.label}
              onChange ={(e)=> {this.updateStatus(rowData, props.rowIndex, e)}} style={{'width': '100%'}}/>
        </React.Fragment>
    );
}

updateInCharge(order, user, rowIndex){
  this.inquiryService.assignOrderResource(order.id, user.id).then(data =>{
    this.state.toast.current.show({severity:'success', summary:'Updated !', detail:'Order Resource Updated'});
    let inquiries = this.state.inquiries
    inquiries[rowIndex]["assigned_to"] = user
    inquiries[rowIndex]["status"] = "NEW"
    this.setState({inquiries: inquiries})
  }).catch(err => {
    this.state.toast.current.show({severity:'error', summary:'Something Went Wrong !', detail:JSON.stringify(err.response.data)});
  })
}
inChargeBodyTemplate(rowData, props) {
  let token = JSON.parse(sessionStorage.getItem("token"))
  return (
      <React.Fragment>
          <Dropdown tooltip = {rowData.assigned_to?.username} disabled = {token.user.group == "Standard"} optionLabel="username" value={rowData.assigned_to} options={this.state.users}
              onChange={(e) => this.updateInCharge(rowData, e.target.value, props.rowIndex)} style={{'width':'100%'}} filter />
      </React.Fragment>
  );

}
upDateBodyTemplate(rowData) {
  if (rowData.update == true){
    return (
        <React.Fragment>
            <span>Yes</span>
        </React.Fragment>
    )
  } else if (rowData.update == false) {
    return (
        <React.Fragment>
            <span>No</span>
        </React.Fragment>
    )
  } else {
    return (
        <React.Fragment>
          <span></span>
        </React.Fragment>
    )
  }
}

updatePriority(order_id, event, rowIndex){
  let value = event.target.value;
  let date = 'None';
  if(value){
    var d = new Date(event.target.value);
    date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
  }

  this.inquiryService.assignOrderPriority(order_id, date).then(data =>{
    this.state.toast.current.show({severity:'success', summary:'Updated !', detail:'Order Priority Updated'});
    let inquiries = this.state.inquiries
    inquiries[rowIndex]["priorities"] = date
    this.setState({inquiries: inquiries})
  }).catch(err => {
    this.state.toast.current.show({severity:'error', summary:'Something Went Wrong !', detail:JSON.stringify(err)});
  })
}

isSameDay(first, second){
  return first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();
}
prioritiesBodyTemplate(rowData, props) {
      let token = JSON.parse(sessionStorage.getItem("token"))
      function priorityClass(){
        return {"background": "green"}
      }
      let obj;
      let priority_date = null;
      if (rowData.priorities){
        let date = rowData.priorities.split("-")
        priority_date = new Date(date[0], date[1] - 1, date[2]);
      }

      if (priority_date) {
        let today = new Date();
        if(this.isSameDay(priority_date, today)){
          obj  = {"background": "#FFFD77"};
        } else {
          if( rowData.priorities == null){
            obj = {}
          }
          else if(priority_date.getTime() < today.getTime()){
            // in the past
            obj  = {"background": "#FF7777"};
          } else {
            // the future
            obj  = {"background": "#8AFF77"};
          }
        }
      }
      return (
          <React.Fragment>
              <Calendar disabled = {token.user.group == "Standard"} value = {priority_date} onChange= {(e) => this.updatePriority(rowData.id, e, props.rowIndex)}
                showButtonBar inputStyle={obj} />
          </React.Fragment>
      );

}
onTabChange(e){
  let tab_index = e.index;
  const tab_items = this.state.tabs;
  if(tab_items.length == 1){
      tab_index = 0;
  }
  return this.setState({ activeTabIndex: tab_index});
}
handleCloseTab(tab_id){
    const tab_toClose = tab_id.split("_")
    const id = tab_toClose[1];
    const tab_items = this.state.tabs;
    tab_items.map((tab,i) => {
        if(tab.id == id){
            tab_items.splice(i,1);
        }
    });
   this.setState({tabs:tab_items});
   this.setState({activeTabIndex : (tab_items.length - 1) });

}
handleAddOrderEditTab(orderData){
  let tab = this.state.tabs.slice();
  const closeTab = this.handleCloseTab;
  let count = this.state.tabs.length;

  function handleClose(e) {
      e.preventDefault();
      closeTab(e.currentTarget.id);
  }
  this.inquiryService.getOrder(orderData.id).then(data => {
    tab.push({
        id : count,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> {orderData.reference} <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
                </React.Fragment>,
       content: this.renderOrdertDetailsTab(data)
    })
   // count = count + 1;
    sessionStorage.setItem('activeTabIndex',count);
    this.setState({tabs:tab})
    this.setState({activeTabIndex : count });

  }).catch(err => {
    console.log(err)
  })
}
purgeReportCache(report_id){
  sessionStorage.removeItem("ses_managers_" + report_id);
  sessionStorage.removeItem("ses_banks_info_" + report_id);
  sessionStorage.removeItem("ses_credit_evaluation_" + report_id);
  sessionStorage.removeItem("ses_shareholding_" + report_id);
  sessionStorage.removeItem("ses_management_" + report_id);
  sessionStorage.removeItem("ses_affiliations_" + report_id);
  sessionStorage.removeItem("ses_contact_info_" + report_id);
  sessionStorage.removeItem("ses_registration_changes_" + report_id);
  sessionStorage.removeItem("ses_business_" + report_id);
  sessionStorage.removeItem("ses_clients_" + report_id);
  sessionStorage.removeItem("ses_suppliers_" + report_id);
  sessionStorage.removeItem("ses_registration_info_" + report_id);
  sessionStorage.removeItem("ses_financials_" + report_id);
  sessionStorage.removeItem("ses_company_info_" + report_id);
  sessionStorage.removeItem("ses_insurances_" + report_id);
  sessionStorage.removeItem("ses_payment_behavior_" + report_id);
  sessionStorage.removeItem("ses_interviews_" + report_id);
  sessionStorage.removeItem("ses_press_" + report_id);
  sessionStorage.removeItem("ses_legal_records_" + report_id);
}
handleAddTab(orderData){
    let tab = this.state.tabs.slice();
    const closeTab = this.handleCloseTab;
    let count = this.state.tabs.length;
    let purgeCache = this.purgeReportCache

    function handleClose(e) {
        e.preventDefault();
        closeTab(e.currentTarget.id);
        console.log("Purging Cache of report : " + orderData.report)
        purgeCache(orderData.report)
    }
    tab.push({
        id : count,
        title: <React.Fragment>
                        <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> {orderData.company_name} <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
                </React.Fragment>,
       content: this.renderInquiryDetails(orderData, count)
    })

    sessionStorage.setItem('activeTabIndex',count);
    this.setState({tabs:tab})
    this.setState({activeTabIndex : count });
}

onOrderDateFilterChange(stateField, filterField, event) {
  let state = this.state
    if (event.value !== null){
      this.dt.filter(formatDate(event.value), filterField, 'custom');
    }

    else{
      this.dt.filter(null, 'date', 'equals');

    }

    this.setState({orderDateFilter: formatDate(event.value)});
}
sortDateRange(){

}
filterDate(value, filter) {
    if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
        return true;
    }
    if (value === undefined || value === null) {
        return false;
    }
    return value === this.formatDate(filter);
}
statusItemTemplate(option) {
    return (
        <span className={classNames('customer-badge', 'status-' + option)}>{option}</span>
    );
}
onStatusFilterChange(selectedField , field, event) {
    let filters_arr = this.state.filters;
    let activatedFilters = this.state.activeFilters;

    if(event.target.value != "all"){
        this.dt.filter(event.target.value, field, 'equals');
        switch(selectedField){
          case "selectedCompany":
            this.setState({ selectedCompany: event.value});
            break;
          case "selectedClient":
            this.setState({ selectedClient: event.value});
            break;
          case "selectedDelay":
            this.setState({ selectedDelay: event.value});
            break;
          case "selectedInCharge":
            this.setState({ selectedInCharge: event.value});
            break;
          case "selectedCountry":
            this.setState({ selectedCountry: event.value});
            break;
          case "selectedStatus":
            this.setState({ selectedStatus: event.value});
            break;
          default:
            this.setState({ selectedField: event.value});
        }
        activatedFilters[selectedField] = event.target.value
        if(filters_arr.includes(event.target.value) == false) {
            filters_arr.push(event.target.value);
        }
    }else{
        let fieldValue = activatedFilters[selectedField];
        activatedFilters[selectedField] = null;
        filters_arr = filters_arr.filter(function(value, index, arr){
          return value != fieldValue;
        })
        this.dt.filter(null, field, 'equals');
        //this.setState({ selectedField: null });
    }
    this.setState({filters: filters_arr, activatedFilters: activatedFilters});
}
onGlobalSearch(e){
   this.setState({globalFilter : e.target.value});
}
renderTabContent(){
    const quickSearch = this.renderQuickSearch();
    const  detailedSearch = this.renderDetailedSearch();

    return (
        <div className="condiv">
            <OrderSearch ref={this.orderSearch} toast = {this.state.toast} readResults = {this.handleSearchResults} show_quick_search = {true} />
            <div className="clear"><br/></div>
            <div className="col-12" id="dataTable">
                <DataTable lazy ref={(el) => this.dt = el}
                    value={this.state.inquiriesLazy} loading={this.state.searchloading}
                    totalRecords={this.state.totalRecords} onPage={this.onPage}
                    first={this.state.lazyParams.first} onSort={this.onSort}
                    sortField={this.state.lazyParams.sortField} sortOrder={this.state.lazyParams.sortOrder}
                    className="p-datatable-striped"
                    dataKey="id"
                    rowHover
                    paginator rows={this.state.lazyParams.rows}
                    emptyMessage="No orders found"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[25, 50, 100, 200]}
                    //globalFilter={this.state.globalFilter}
                    >
                    <Column style={{width:'14em'}} sortField="company_name" filterField="company_name" field="company_name" header={<Translation>{ t => <span>{t('ORDERNAME')} </span>}</Translation>} body={this.orderNameBodyTemplate} sortable filterPlaceholder="Order Name"/>
                    <Column style={{width:'14em'}} sortField="company_name" filterField="company_name" field="company_name"  header={<Translation>{ t => <span>{t('REGISTEREDNAME')} </span>}</Translation>} body={this.companyNameBodyTemplate} sortable  filterPlaceholder="Search by Company Name"/>
                    <Column style={{width:'6em',textAlign:'center'}} sortField="country.name" filterField="country" field="country.name" header={<Translation>{ t => <span>{t('COUNTRY')} </span>}</Translation>} body={this.countryBodyTemplate}  filterPlaceholder="Search by Country"/>
                    <Column style={{width:'9em',textAlign:'center'}} sortField="client" filterField="client" field="client" header={<Translation>{ t => <span>{t('CLIENT')} </span>}</Translation>} body={this.clientBodyTemplate} sortable  filterPlaceholder="Search by Client"/>
                    <Column style={{width:'8em'}} sortField="reference" filterField="reference" header={<Translation>{ t => <span>{t('REFERENCE')} </span>}</Translation>} body={this.referenceBodyTemplate} sortable  filterMatchMode="contains"/>
                    <Column style={{width:'7em'}} sortField="delay.name" filterField="delay.name"  header={<Translation>{ t => <span>{t('DELAY')} </span>}</Translation>} body={this.delayBodyTemplate} sortable filterMatchMode="contains" filterPlaceholder="Search by Delay"/>
                    <Column style={{width:'5em',textAlign:'center'}} field="created_at" filtedField="created_at" header={<Translation>{ t => <span>{t('ORDERDATE')}  </span>}</Translation>} body={this.orderDateBodyTemplate} sortable filterMatchMode="custom"  />
                    <Column style={{width:'5em',textAlign:'center'}} field="due_date" header={<Translation>{ t => <span>{t('DUEDATE')} </span>}</Translation>} body={this.dueDateBodyTemplate} sortable />
                    <Column style={{width:'5em',textAlign:'center'}} field="delivery_date" header={<Translation>{ t => <span>{t('DELIVERYDATE')} </span>}</Translation>} body={this.deliveryDateBodyTemplate} sortable />
                    <Column style={{width:'6em',textAlign:'center'}} field="status" header={<Translation>{ t => <span>{t('STATUS')} </span>}</Translation>} body={this.statusBodyTemplate} sortable filterField="status" />
                    <Column style={{width:'5em',textAlign:'center'}} field="assigned_to.username" filterField="assigned_to.username" header={<Translation>{ t => <span>{t('ANALYST')} </span>}</Translation>} body={this.inChargeBodyTemplate} sortable />
                    <Column style={{width:'3em',textAlign:'center'}} field="updated_at" header={<Translation>{ t => <span>{t('UPDATE')} </span>}</Translation>} body={this.upDateBodyTemplate} sortable />
                    <Column style={{width:'5em',textAlign:'center'}} field="priorities" header={<Translation>{ t => <span>{t('PRIORITIES')} </span>}</Translation>} body={this.prioritiesBodyTemplate} sortable filterMatchMode="custom" filterFunction={this.filterDate}  />
                </DataTable>
            </div>
            </div>
          )
}
//Render Inquiry Details
renderInquiryDetails(orderInfo, tabIndex){
    return(
        <div><InquiriesDetails orderInfo={orderInfo} toast = {this.state.toast} new_company = {this.addNewCompanyTab} experian_html = {this.addNewExperianTab} tab_index = {tabIndex} /></div>
    )
}

renderOrdertDetailsTab(orderInfo){
  return(
    <div><OrderDetails orderInfo={orderInfo} toast = {this.state.toast}  customers = {this.state.clients} /></div>
  )
}

renderCompanyDetailsTab(companyInfo, reportTabIndex, orderId){
  return (
    <div><CompanyDetails companyInfo = {companyInfo} toast = {this.state.toast} after_save = {this.handleNewCompanySave} tab_index = {reportTabIndex} order_id = {orderId} /></div>
  )
}

handleNewCompanySave(orderId, companyId, reportTabIndex){
  this.inquiryService.assignOrderCompany(orderId, companyId).then(data => {
    this.state.toast.current.show({severity:'success', summary:'Assigned', detail: ""});
    this.setState({activeTabIndex: reportTabIndex})


  }).catch(err => {
    this.state.toast.current.show({severity:'error', summary:'Assignment Failed', detail: JSON.stringify(err.response.data)});
  })
}

addNewCompanyTab(orderId, reportTabIndex){

  let companyInfo = {}
  companyInfo["legal_name"] = "new company"
  companyInfo["type"] = "CLNT"
  companyInfo["legal_address"] = {}
  companyInfo["legal_address"]["country"] = {}
  companyInfo["legal_address"]["country"]["name"] = "United States"
  let count = this.state.tabs.length;
  const closeTab = this.handleCloseTab;
  function handleClose(e) {
      e.preventDefault();
      closeTab(e.currentTarget.id);
  }
  const new_company_tab = {
      id : count,
      title: <React.Fragment>
                      <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> New Company <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
              </React.Fragment>,
      content: this.renderCompanyDetailsTab(companyInfo, reportTabIndex, orderId)
  };

  const tabs_arr = this.state.tabs;
  tabs_arr.push(new_company_tab);
  this.setState({tabs : tabs_arr});
  this.setState({activeTabIndex: count});
}
// End Inquiry Details
addNewExperianTab(companyInfo, dataHtml, reportTabIndex){
 
  let count = this.state.tabs.length;
  const closeTab = this.handleCloseTab;
  function handleClose(e) {
      e.preventDefault();
      closeTab(e.currentTarget.id);
  }
  const experian_tab = {
      id : count,
      title: <React.Fragment>
                      <i className="pi pi-file-o" style={{'fontSize': '1em'}}></i> {companyInfo.businessName} HTML REPORT <span  className="close" id={'mainTab_'+count} title='Close tab' onClick={handleClose}>x</span>
              </React.Fragment>,
      content: Parser(dataHtml)
  };

  const tabs_arr = this.state.tabs;
  tabs_arr.push(experian_tab);
  this.setState({tabs : tabs_arr});
  this.setState({activeTabIndex: count});
}
    render() {
        return (

            <div className="condiv">
                  <MainHeader/>
                 <br></br>
                  <Toast ref={this.state.toast} />
                  <div className="col-12">
                    <TabView activeIndex={this.state.activeTabIndex}  onTabChange={this.onTabChange}>
                        {
                            this.state.tabs.map((tab,i) => (
                                i == 0 ? <TabPanel key="0" header={tab.title} headerStyle={tab.headerStyle}>{this.renderTabContent()}</TabPanel>:
                                <TabPanel key={i} header={tab.title} headerStyle={tab.headerStyle}>{tab.content}</TabPanel>
                            ))
                        }

                    </TabView>

                    </div>



            </div>
            )
        }
    }
//Experian Html Preview

export default Inquiries
