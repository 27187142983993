import axios from 'axios';
import { api } from './baseService'

export default class InquiryService {
    getRequestOption(){
      const tokenJSON = JSON.parse(sessionStorage.getItem("token"))
      const options = {
       headers: {
           'Content-Type' : 'application/json',
           'Accept': '*/*',
           'Authorization': 'JWT ' + tokenJSON.token
       }
     };
     return options
    }

    getRequestOption2(){
      const tokenJSON = JSON.parse(sessionStorage.getItem("token"))
      const options = {
       headers: {
           'Content-Type' : 'multipart/form-data',
           'Accept': '*/*',
           'Authorization': 'JWT ' + tokenJSON.token
       }
     };
     return options
    }

    uploadFinancialReport = async(order_id, report_id, load)=>{
     return axios.post(process.env.REACT_APP_HOST + '/wand2/order/' + order_id + "/report/" + report_id + "/financials/upload_report", load,
                this.getRequestOption2()).then(res => res.data);     
    }

    uploadGroupFinancialReport = async(order_id, report_id, load)=>{
      return axios.post(process.env.REACT_APP_HOST + '/wand2/order/' + order_id + "/report/" + report_id + "/financials/upload_group_report", load,
            this.getRequestOption2()).then(res => res.data);
    }
    

    calculateDueDate = async(body)=>{
      //return axios.post(process.env.REACT_APP_HOST + '/wand2/order/due_date', body, this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/due_date', "post",body, this.getRequestOption()).then(res => res.data);
    }

    submitQuickSearch = async (quickSearch)=>{
      //return axios.post(process.env.REACT_APP_HOST + '/wand2/order/quick_search', quickSearch, this.getRequestOption()).then(res => res.data);
      return  await api(process.env.REACT_APP_HOST + '/wand2/order/quick_search',"post", quickSearch, this.getRequestOption()).then(res => res.data);
    }
    
    submitSearch = async (search) => {
      //return axios.post(process.env.REACT_APP_HOST + '/wand2/order/lazy_search', search, this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/lazy_search', "post",search,this.getRequestOption()).then(res => res.data);
    }

    submitCsvExport = async(search)=>{
     // return axios.post(process.env.REACT_APP_HOST + '/wand2/order/csv_export', search, this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/csv_export', "post",search, this.getRequestOption()).then(res => res.data);
    }

    getLazyOrders = async (lazyParams) => {
      //return axios.post(process.env.REACT_APP_HOST + '/wand2/order/lazy', lazyParams, this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/lazy', "post",lazyParams, this.getRequestOption()).then(res => res.data);
    }

    getInquiries = async()=>{
      // return axios.get(process.env.REACT_APP_HOST + '/wand2/order/', this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/',  "get","",this.getRequestOption()).then(res => res.data);
    }
    
    createOrder = async(orderInfo)=>{
      return await api(process.env.REACT_APP_HOST + '/wand2/order/',"post", orderInfo, this.getRequestOption()).then(res => res.data);
    }
    updateOrder(order_id, order_data){
      return axios.patch(process.env.REACT_APP_HOST + '/wand2/order/' + order_id, order_data, this.getRequestOption2()).then(res => res.data);
    }
    updateNewCommentOrder(order_id){
      return axios.patch(process.env.REACT_APP_HOST + '/wand2/order/' + order_id+'/new_comment_update', this.getRequestOption()).then(res => res.data);
    }

    acceptOrder(orderId){
      return axios.put(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/accept',{} ,this.getRequestOption()).then(res => res.data);
    }
    assignOrderResource(orderId, resourceId){
      return axios.put(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/assign/resource/' + resourceId, {} ,this.getRequestOption()).then(res => res.data);
    }
    assignOrderCompany(orderId, companyId){
      return axios.put(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/assign/company/' + companyId, {} ,this.getRequestOption()).then(res => res.data);
    }

    assignOrderPriority(orderId, date){
      return axios.put(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/assign/priority/' + date, {} ,this.getRequestOption()).then(res => res.data);
    }

    updateOrderStatus(orderId, status){
      return axios.put(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/status/' + status, {} ,this.getRequestOption()).then(res => res.data);
    }

    getOrder = async(orderId)=>{
      //return axios.get(process.env.REACT_APP_HOST + '/wand2/order/'+orderId,this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/'+orderId,"get","",this.getRequestOption()).then(res => res.data);
    }

    deleteOrder(orderId){
      return axios.delete(process.env.REACT_APP_HOST + '/wand2/order/' + orderId, this.getRequestOption()).then(res => res.data);
    }

    deleteUploadedFle(deletePath, file_id){
      return axios.delete(process.env.REACT_APP_HOST + deletePath + file_id, this.getRequestOption()).then(res => res.data);
    }

    getOrderComunications =async(orderId)=>{
      //return axios.get(process.env.REACT_APP_HOST + '/wand2/order/'+orderId + '/communications',this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/'+orderId + '/communications',"get","",this.getRequestOption()).then(res => res.data);
    }


    getOrderProvidedFiles = async(orderId)=>{
    //  return await api(process.env.REACT_APP_HOST + '/wand2/order/'+orderId + '/provided_file',"get","",this.getRequestOption()).then(res => res.data);
      if(sessionStorage.getItem('order_file'+orderId) !== null){
        return JSON.parse(sessionStorage.getItem('order_file'+orderId))
      } else {
        let data = await api(process.env.REACT_APP_HOST + '/wand2/order/'+orderId + '/provided_file',"get","",this.getRequestOption()).then(res => res.data);
        sessionStorage.setItem('order_file'+orderId,JSON.stringify(data));
        return data;
      } 
    }

    createOrderCommunication = async(orderId, communication)=>{
     // return axios.post(process.env.REACT_APP_HOST + '/wand2/order/'+orderId + '/communications', communication,this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/'+orderId + '/communications', "post",communication,this.getRequestOption()).then(res => res.data);
    }

    setToWaiting = async(orderId)=>{
      //return axios.get(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/set_to_waiting',this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/set_to_waiting',"get","",this.getRequestOption()).then(res => res.data);
    }

    getCompanyReport = async(companyId)=>{
    //  return axios.get(process.env.REACT_APP_HOST + '/wand2/company/' + companyId + '/report',this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/company/' + companyId + '/report',"get","",this.getRequestOption()).then(res => res.data);
    }

    askFoReview = async(orderId)=>{
      //return axios.get(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/ask_for_review',this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/ask_for_review',"get","",this.getRequestOption()).then(res => res.data);
    }

    complete = async(orderId)=>{
      //return axios.get(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/complete',this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/complete',"get","",this.getRequestOption()).then(res => res.data);
    }
   
    updateCompanyFromReport = async(orderId)=>{
      //return axios.get(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/update_company',this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/update_company',"get","",this.getRequestOption()).then(res => res.data);
    }
    
    getLastReports = async(orderId)=>{
      //return axios.get(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/create_report',this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/create_report',"get","",this.getRequestOption()).then(res => res.data);
    }

    getCompanyReportF = async(orderId)=>{
      return await api(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/import_from_company',"get","",this.getRequestOption()).then(res => res.data);
    }

    createReport =async(orderId)=>{
     // return axios.get(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/create_report',this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/' + orderId + '/create_report',"get","",this.getRequestOption()).then(res => res.data);
    }
    getReport = async(report)=>{
      const options = {
          headers: {
              'Content-Type' : 'application/json',
              'Accept': '*/*'
          }
        };
        //return axios.get(process.env.REACT_APP_HOST + '/wand2/report/'+report,options).then(res => res.data);
        return await api(process.env.REACT_APP_HOST + '/wand2/report/'+report,"get","",options).then(res => res.data);
    }
    getCompany = async(company)=>{
      //return axios.get(process.env.REACT_APP_HOST + '/wand2/company/'+company,this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/company/'+company,"get","",this.getRequestOption()).then(res => res.data);
    }
    updateReport(orderId,reportData){
      return axios.patch(process.env.REACT_APP_HOST + '/wand2/order/'+orderId+'/report',reportData,this.getRequestOption()).then(res => res.data);
    }
    //** TODO need to check on update company endpoint */
    updateCompany(companyId,reportData){
      const options = {
          headers: {
              'Content-Type' : 'application/json',
              'Accept': '*/*'
              }
      };
      return axios.patch(process.env.REACT_APP_HOST + '/wand2/company/'+companyId,reportData,options).then(res => res.data);
    }

    generateReport = async(companyId,reportData)=>{
      const options = {
          headers: {
              'Content-Type' : 'application/json',
              'Accept': '*/*'
              }
      };
      reportData.i18nextLng = localStorage.getItem("i18nextLng");
     // return axios.post(process.env.REACT_APP_HOST + '/wand2/order/'+companyId+'/report/update_and_generate',reportData,options).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/'+companyId+'/report/update_and_generate',"post",reportData,options).then(res => res.data);
    }

    getXMLReport = async(orderId)=>{
      return await api(process.env.REACT_APP_HOST + '/wand2/order/'+orderId+'/report/xml/generate',"get",localStorage.getItem("i18nextLng"),this.getRequestOption()).then(res => res.data);
    }
    getWordReport = async(orderId)=>{
      //return axios.get(process.env.REACT_APP_HOST + '/wand2/order/'+orderId+'/report/word/generate', this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/'+orderId+'/report/word/generate',"get","",this.getRequestOption()).then(res => res.data);
    }
    updateGenerateReport = async(orderId,reportData)=>{
      reportData.i18nextLng = localStorage.getItem("i18nextLng");
      //return axios.post(process.env.REACT_APP_HOST + '/wand2/order/'+orderID+'/report/update_and_generate',reportData,this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/'+orderId+'/report/update_and_generate',"post",reportData,this.getRequestOption()).then(res => res.data);
    }
    updateGenerateWordReport = async(orderID,reportData)=>{
      reportData.i18nextLng = localStorage.getItem("i18nextLng");
     // return axios.post(process.env.REACT_APP_HOST + '/wand2/order/'+orderID+'/report/word/update_and_generate',reportData,this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/'+orderID+'/report/word/update_and_generate',"post",reportData,this.getRequestOption()).then(res => res.data);
    }
    


    reportSearch = async(body)=>{
     // return axios.post(process.env.REACT_APP_HOST + '/wand2/report/search', body, this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/report/search',"post", body, this.getRequestOption()).then(res => res.data);
    }
    

    getStatisticsDetails = async(data)=> {
      //return axios.post(process.env.REACT_APP_HOST + '/wand2/statistics/details',  data,this.getRequestOption()).then(res => res.data);
      if(sessionStorage.getItem('api_StatsDetails') !== null){
        return JSON.parse(sessionStorage.getItem('api_StatsDetails'))
      } else {
        let data2 = await api(process.env.REACT_APP_HOST + '/wand2/statistics/details', "post", data,this.getRequestOption()).then(res => res.data);
        sessionStorage.setItem('api_StatsDetails',JSON.stringify(data2));
        return data2;
      } 

    
    }
    sendEmail = async(data) =>{
      //return axios.post(process.env.REACT_APP_HOST + '/wand2/send_email/', data, this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/send_email/', "post",data, this.getRequestOption()).then(res => res.data);
    }
    /*getPreviousComment(order_id){
      return axios.get(process.env.REACT_APP_HOST + '/wand2/order/'+order_id+'/report/previous_comment', this.getRequestOption()).then(res => res.data);
    }*/
    updatePreviousComment = async(order_id, body)=>{
     // return axios.post(process.env.REACT_APP_HOST + '/wand2/order/' + order_id + '/report/previous_comment', body, this.getRequestOption()).then(res => res.data);
      return await api(process.env.REACT_APP_HOST + '/wand2/order/' + order_id + '/report/previous_comment',"post",body, this.getRequestOption()).then(res => res.data);
    }


//---------------------- Added Session Storage Function ---------------------------//
//************** Comments on Clients ***********/
  getCommentsOnClients = async()=>{
    if(sessionStorage.getItem('api_clientComments') !== null){
      return JSON.parse(sessionStorage.getItem('api_clientComments'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/clients/comments', "get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_clientComments',JSON.stringify(data));
      return data;
    } 
  }
  createCommentsOnClients = async(commentsInfo)=>{
    sessionStorage.removeItem("api_clientComments");
    return await api(process.env.REACT_APP_HOST + '/wand2/clients/comments', "post",commentsInfo, this.getRequestOption()).then(res => res.data);
  }
  updateCommentsOnClients(id, commentsInfo){
    sessionStorage.removeItem("api_clientComments");
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/clients/comments/' + id, commentsInfo, this.getRequestOption()).then(res => res.data);
  }
//************** END Comments on Clients ***********/ 

//************** Comments on Suppliers ***********/
  getCommentsOnSuppliers = async()=>{
    if(sessionStorage.getItem('api_supplierComments') !== null){
      return JSON.parse(sessionStorage.getItem('api_supplierComments'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/suppliers/comments', "get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_supplierComments',JSON.stringify(data));
      return data;
    } 
  }
  createCommentsOnSuppliers = async(commentsInfo)=>{
    sessionStorage.removeItem("api_supplierComments");
    return await api(process.env.REACT_APP_HOST + '/wand2/suppliers/comments', "post",commentsInfo, this.getRequestOption()).then(res => res.data);
  }
  updateCommentsOnSuppliers(id, commentsInfo){
    sessionStorage.removeItem("api_supplierComments");
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/suppliers/comments/' + id, commentsInfo, this.getRequestOption()).then(res => res.data);
  }
//************** END Comments on Suppliers ***********/

//************** Admin Notes ************************/
  getAdminNotes = async(report_id)=>{
      if(sessionStorage.getItem('api_adminNotes_'+report_id) !== null){
        return JSON.parse(sessionStorage.getItem('api_adminNotes_'+report_id))
      } else {
        let data = await api(process.env.REACT_APP_HOST + '/wand2/report/'+report_id+'/notes',"get","",this.getRequestOption()).then(res => res.data);
        sessionStorage.setItem('api_adminNotes_'+report_id,JSON.stringify(data));
        return data;
      } 
    }
  createAdminNotes = async(report_id,data)=>{
     sessionStorage.removeItem('api_adminNotes_'+report_id);
     return await api(process.env.REACT_APP_HOST + '/wand2/report/'+report_id+'/notes', "post",data, this.getRequestOption()).then(res => res.data);
  }
   //TODO pass report_id refresh the records
  deleteAdminNote(note_id,report_id){
    sessionStorage.removeItem('api_adminNotes_'+report_id);
    return axios.delete(process.env.REACT_APP_HOST + '/wand2/report/notes/'+note_id, this.getRequestOption()).then(res => res.data);
  }
//************** END Admin Notes ************************/

//************** Countries ************************/
  getCountries = async()=>{
    if(sessionStorage.getItem('api_countries') !== null){
     return JSON.parse(sessionStorage.getItem('api_countries'))
    } else {
     let data = await api(process.env.REACT_APP_HOST + '/wand2/country/', "get","",this.getRequestOption()).then(res => res.data);
     sessionStorage.setItem('api_countries',JSON.stringify(data));
     return data;
   } 
  }
  createCountry = async(countryInfo)=>{
    sessionStorage.removeItem("api_countries")
    return await api(process.env.REACT_APP_HOST + '/wand2/country/', "post",countryInfo, this.getRequestOption()).then(res => res.data);
  }
  updateCountry(id, countryInfo){
    sessionStorage.removeItem("api_countries")
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/country/' + id, countryInfo, this.getRequestOption()).then(res => res.data);
  }
  deleteCountry(countryId){
    sessionStorage.removeItem("api_countries")
    return axios.delete(process.env.REACT_APP_HOST + '/wand2/country/' + countryId, this.getRequestOption()).then(res => res.data);
  }
 //************** END Countries ************************/
//************** LOGS ************************/
  getLogs = async()=>{
    if(sessionStorage.getItem('api_logs') !== null){
      return JSON.parse(sessionStorage.getItem('api_logs'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/logs/',"get","", this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_logs',JSON.stringify(data));
      return data;
    } 

  }
  //TODO
  searchLogs = async(searchParams)=>{
    return await api(process.env.REACT_APP_HOST + '/wand2/logs/search',"post", searchParams, this.getRequestOption()).then(res => res.data);
  }
  createLog = async()=>{
    sessionStorage.removeItem("api_logs");
    return await api(process.env.REACT_APP_HOST + '/wand2/logs/', "post","",this.getRequestOption()).then(res => res.data);
  }

//************** END LOGS ************************/

//************** Country Overview ************************/
  getCountryOverviews = async()=>{
    if(sessionStorage.getItem('api_countryOverview') !== null){
      return JSON.parse(sessionStorage.getItem('api_countryOverview'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/countryOverview/', "get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_countryOverview',JSON.stringify(data));
      return data;
    } 
  }
  createCountryOverview = async(countryInfo)=>{
    sessionStorage.removeItem('api_countryOverview');
    return await api(process.env.REACT_APP_HOST + '/wand2/countryOverview/', "post",countryInfo, this.getRequestOption()).then(res => res.data);
  }
  getCountryOverview = async(country_id)=>{
    if(sessionStorage.getItem('api_countryOverview_'+country_id) !== null){
      return JSON.parse(sessionStorage.getItem('api_countryOverview_'+country_id))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/countryOverview/' + country_id, "get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_countryOverview_'+country_id,JSON.stringify(data));
      return data;
    } 
  }
  updateCountryOverview(id, countryOverViewInfo){
    sessionStorage.removeItem('api_countryOverview');
    sessionStorage.removeItem('api_countryOverview_'+id);
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/countryOverview/' + id, countryOverViewInfo, this.getRequestOption()).then(res => res.data);
  }

//************** END Country Overview ************************/

//************** Registration Status ************************/
  getRegStatuses = async()=>{
    if(sessionStorage.getItem('api_regStatus') !== null){
      return JSON.parse(sessionStorage.getItem('api_regStatus'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/registration/statuses', "get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_regStatus',JSON.stringify(data));
      return data;
    } 
  }
  createRegStatus = async(statusInfo)=>{
    sessionStorage.removeItem('api_regStatus');
    return await api(process.env.REACT_APP_HOST + '/wand2/registration/statuses', "post", statusInfo, this.getRequestOption()).then(res => res.data);
  }
  updateRegStatus(id, statusInfo){
    sessionStorage.removeItem('api_regStatus');
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/registration/statuses/' + id, statusInfo, statusInfo, this.getRequestOption()).then(res => res.data);
  }
//************** END Registration Status ************************/

//************** Delays ************************/
  getDelays =async()=>{
    if(sessionStorage.getItem('api_delays') !== null){
      return JSON.parse(sessionStorage.getItem('api_delays'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/order/delay/', "get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_delays',JSON.stringify(data));
      return data;
    } 
  }
  createDelay = async(delayInfo)=>{
    sessionStorage.removeItem('api_delays');
    return await api(process.env.REACT_APP_HOST + '/wand2/order/delay/', "post",delayInfo,this.getRequestOption()).then(res => res.data);
  }
  updateDelay(id, delayInfo){
    sessionStorage.removeItem('api_delays');
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/order/delay/' + id, delayInfo, this.getRequestOption()).then(res => res.data);
  }
//************** END Delays ************************/

//************** Affiliation Types ************************/
  getAffiliationTypes = async()=>{  
    if(sessionStorage.getItem('api_affiliationTypes') !== null && sessionStorage.getItem('api_affiliationTypes') !== undefined){
      return JSON.parse(sessionStorage.getItem('api_affiliationTypes'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/affiliations/types',"get","", this.getRequestOption()).then(res => res.data).catch(err=> console.log(err));
      sessionStorage.setItem('api_affiliationTypes',JSON.stringify(data));
      return data;
    } 
  }
  createAffiliationType = async(affiliationTypeInfo)=>{
    sessionStorage.removeItem('api_affiliationTypes');
    return await api(process.env.REACT_APP_HOST + '/wand2/affiliations/types', "post",affiliationTypeInfo, this.getRequestOption()).then(res => res.data);
  }
  updateAffiliationTypes(id, affiliationTypeInfo){
    sessionStorage.removeItem('api_affiliationTypes');
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/affiliations/types/' + id, affiliationTypeInfo, this.getRequestOption()).then(res => res.data);
  }

//************** Affiliation Types ************************/

//************** Currencies ************************/
  getCurrencies = async()=>{
    if(sessionStorage.getItem('api_currencies') !== null){
      return JSON.parse(sessionStorage.getItem('api_currencies'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/currency', "get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_currencies',JSON.stringify(data));
      return data;
    } 
  }
  createCurrencies =async(currencyInfo) => {
    sessionStorage.removeItem('api_currencies');
    return await api(process.env.REACT_APP_HOST + '/wand2/currency' ,"post", currencyInfo, this.getRequestOption()).then(res => res.data);
  }
  updateCurrencies(id, currencyInfo){
    sessionStorage.removeItem('api_currencies');
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/currency/' + id , currencyInfo, this.getRequestOption()).then(res => res.data);
  }
//************** END Currencies ************************/

//************** Business Types ************************/
  getBusinessTypes = async()=>{
    if(sessionStorage.getItem('api_businessTypes') !== null){
      return JSON.parse(sessionStorage.getItem('api_businessTypes'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/business/types', "get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_businessTypes',JSON.stringify(data));
      return data;
    } 
  }
  createBusinessType = async(businessTypeInfo)=>{
    sessionStorage.removeItem("api_businessTypes");
    return await api(process.env.REACT_APP_HOST + '/wand2/business/types', "post",businessTypeInfo, this.getRequestOption()).then(res => res.data);
  }
  updateBusinessTypes(id, businessTypeInfo){
    sessionStorage.removeItem("api_businessTypes");
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/business/types/' + id, businessTypeInfo, this.getRequestOption()).then(res => res.data);
  }
//************** END Business Types ************************/

//************** Registration Types ************************/
  getRegTypes = async()=>{
    if(sessionStorage.getItem('api_registrationTypes') !== null){
      return JSON.parse(sessionStorage.getItem('api_registrationTypes'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/registration/types', "get","", this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_registrationTypes',JSON.stringify(data));
      return data;
    }
  }
  createRegType = async(regTypeInfo)=>{
    sessionStorage.removeItem("api_registrationTypes");
    return await api(process.env.REACT_APP_HOST + '/wand2/registration/types', "post", regTypeInfo, this.getRequestOption()).then(res => res.data);
  }
  updateRegType(id, regTypeInfo){
    sessionStorage.removeItem("api_registrationTypes");
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/registration/types/' + id, regTypeInfo, this.getRequestOption()).then(res => res.data);
  }
//************** Registration Types ************************/

//************** Manager Position ************************/
  getManagerPositions = async()=>{
    if(sessionStorage.getItem('api_managerPosition') !== null){
      return JSON.parse(sessionStorage.getItem('api_managerPosition'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/managers/positions', "get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_managerPosition',JSON.stringify(data));
      return data;
    } 
  }
  createManagerPositions = async(positionInfo)=>{
    sessionStorage.removeItem('api_managerPosition');
    return await api(process.env.REACT_APP_HOST + '/wand2/managers/positions', "post",positionInfo, this.getRequestOption()).then(res => res.data);
  }
  updateManagerPositions(id, positionInfo){
    sessionStorage.removeItem('api_managerPosition');
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/managers/positions/' + id, positionInfo, this.getRequestOption()).then(res => res.data);
  }
//************** END Manager Position ************************/

//************** Legal Forms ************************/
  getRegLegalForms = async()=>{
   if(sessionStorage.getItem('api_legalForms') !== null){
      return JSON.parse(sessionStorage.getItem('api_legalForms'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/registration/legal_forms',"get","", this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_legalForms',JSON.stringify(data));
      return data;
    } 
  }
  createLegalForm = async(legalFormInfo) => {
    sessionStorage.removeItem('api_legalForms')
    return await api(process.env.REACT_APP_HOST + '/wand2/registration/legal_forms', "post",legalFormInfo, this.getRequestOption()).then(res => res.data);
  }
  updateLegalForm(id, legalFormInfo){
    sessionStorage.removeItem('api_legalForms')
    return axios.patch(process.env.REACT_APP_HOST + '/wand2/registration/legal_forms/' + id, legalFormInfo, this.getRequestOption()).then(res => res.data);
  }
//************** END Legal Forms ************************/
  getOverviewStatistics = async()=> {
    if(sessionStorage.getItem('api_OverviewStats') !== null){
      return JSON.parse(sessionStorage.getItem('api_OverviewStats'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/statistics/',"get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('api_OverviewStats',JSON.stringify(data));
      return data;
    } 
  }

  getStaffs = async()=>{
     if(sessionStorage.getItem('api_userStaffs') !== null){
       return JSON.parse(sessionStorage.getItem('api_userStaffs'))
     } else {
        let data = await api(process.env.REACT_APP_HOST + '/wand2/users/staff', "get","",this.getRequestOption()).then(res => res.data);
        sessionStorage.setItem('api_userStaffs',JSON.stringify(data));
        return data;
     } 
  }

  getSuppliers = async()=>{
    if(sessionStorage.getItem('api_suppliers') !== null){
      return JSON.parse(sessionStorage.getItem('api_suppliers'))
    } else {
       let data = await api(process.env.REACT_APP_HOST + '/wand2/company/suppliers',"get","",this.getRequestOption()).then(res => res.data);
       sessionStorage.setItem('api_suppliers',JSON.stringify(data));
       return data;
    } 
  }

  getSics = async()=>{
    if(sessionStorage.getItem('api_sics') !== null){
      return JSON.parse(sessionStorage.getItem('api_sics'))
    } else {
       let data = await api(process.env.REACT_APP_HOST + '/wand2/sics/', "get","",this.getRequestOption()).then(res => res.data);
       sessionStorage.setItem('api_sics',JSON.stringify(data));
       return data;
    } 
  }

  getOrderStatusList=async()=>{
    if(sessionStorage.getItem('order_status') !== null){
      return JSON.parse(sessionStorage.getItem('order_status'))
    } else {
      let data = await api(process.env.REACT_APP_HOST + '/wand2/order/status',"get","",this.getRequestOption()).then(res => res.data);
      sessionStorage.setItem('order_status',JSON.stringify(data));
      return data;
    } 
  }
}
