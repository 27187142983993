import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Translation } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressBar } from 'primereact/progressbar';
import creditSafeService from './creditSafeService';
import { Toast } from 'primereact/toast';
import {formatDate} from '../../components/Utils';
class Creditsafe extends Component {
    constructor(props) {
       // const tokenJSON = JSON.parse(sessionStorage.getItem("token"));

        super(props)
        this.state = {
            report : this.props.report,
            displayReportImportFromCreditSafe : this.props.displayReportImportFromCreditSafe,
            searchStatusTxt : "",
            searchCompany: [],
            toast: this.props.toast ,
            dialogToClose : this.props.closeDialog,
            importBtnDisable : false,
            coverage: this.props.coverage,
            creditsafe_countries : [],
            selectedCountries: '',
            searchFields:[],
            searchValue : '',
            disableBtn: true,
            showLoader : false,          
        }
        this.creditSafeService = new creditSafeService();
        this.submitSearchCompany = this.submitSearchCompany.bind(this);        
        this.handleSearchCompany = this.handleSearchCompany.bind(this);
        this.importAddressTemplate =  this.importAddressTemplate.bind(this);       
        this.importCreditSafeBodyTemplate = this.importCreditSafeBodyTemplate.bind(this);
        this.updateCountrySearchFilter = this.updateCountrySearchFilter.bind(this);
        //this.handleSearchValueChange = this.handleSearchValueChange.bind(this);
       // this.renderImport = this.renderImport.bind(this);
        //this.clearToast = this.clearToast.bind(this);
        this.toast = React.createRef();
        this.initToken();   
      
    }
    render() {       
        return (this.state.coverage === "NonUS")  ? this.renderImport() : this.renderImportUS()
     }
     componentDidMount () { 
      //  this.toast.current.show({severity:'error', summary:'Something Wrong happend', detail: ''});
     }
     
     initToken(){     
      /* TODO : token must be generated only if it expires */
        if(sessionStorage.getItem("creditsafe_tokens"))
            return true;
        this.creditSafeService.initRefreshToken().then(response => {            
            if(response.token) {
                sessionStorage.setItem("creditsafe_tokens" , JSON.stringify(response));           
                this.creditSafeService.getCountries().then(data=>{           
                    //console.log(data['countryAccess'][0]['creditsafeConnectOnlineReports'])
                    sessionStorage.setItem("creditsafe_countries", JSON.stringify(data['countryAccess'][0]['creditsafeConnectOnlineReports']));                    
                    this.setState({creditsafe_countries: data['countryAccess'][0]['creditsafeConnectOnlineReports']})                 
                });     
            }
       }).catch(err =>{
          console.log(err);
            //this.toast.current.show({severity:'error', summary:'Something Wrong happend', detail: err.response.data});
        }) 
      }
      handleSearchCompany(e){
        var field = e.target.name;
        var val = e.target.value;
        Object.assign(this.state.searchCompany, {[field]: val});    
        //console.log(this.state.searchCompany)
      }
      updateCountrySearchFilter(field, event){
        let state = this.state
        state[field] = event.target.value
        this.setState(state);   
        this.setState({showLoader:true})   
        this.creditSafeService.searchCriteria(state['selectedCountries']).then(data=>{    
            if(data === "failed"){
                sessionStorage.removeItem("creditsafe_tokens");          
                this.initToken();               
             } else {      
                this.setState({searchFields: data.criteriaSets})                      
                this.setState({showLoader:false})   
                this.setState({disableBtn:false})   
             }
        });
      }
    submitSearchCompany(){          
        var selectedCountries = this.state.selectedCountries.map((country)=> (country.code));       
        const nonUSCountries =  selectedCountries.toString();
        var companySearch = this.state.searchCompany;      
        companySearch.countries = (this.state.coverage === "US") ? "US" : nonUSCountries;
        this.setState({showLoader:true})
        this.creditSafeService.searchCompany({...companySearch}).then(data=>{               
           if(data === "failed"){
              sessionStorage.removeItem("creditsafe_tokens"); 
              this.toast.current.show({severity:'error', summary:'API Token has expired, Please try again.', detail: ''});         
              this.initToken();             
              this.submitSearchCompany();           
           } else {      
              this.setState({searchCreditSafeResults: data.companies})     
              this.setState({showLoader:false})
              console.log(companySearch);
              let logData = {"log_details" : "Imported Company : "}
             /* this.creditSafeService.logApiRequest(logData).then(data=>{             
                console.log("LOGGINGS");
             });*/
           }
        })                      
    }
    clearToast(){
       // if(this.toast.current){
       // this.toast.current.clear();
       // }  
    }   
    fetchAddress(address){
        let api_countries = JSON.parse(sessionStorage.getItem('api_countries'));

        var assignedAddress = {};
        assignedAddress.city = address?.city;
        assignedAddress.postal_code = address?.postalCode;
        assignedAddress.street = address?.street;
        assignedAddress.state = address?.province;
        assignedAddress.full_address = address?.simpleValue;              
        assignedAddress.country = api_countries.filter((c)=> c.country_iso === address?.country)[0];  
        return assignedAddress;
    }       
    importReportFromCreditSafe(companyInfo, event){
       // this.setState({importBtnDisable: true})
        this.setState({showLoader:true})
        sessionStorage.setItem("creditSafeImport" , true);
        companyInfo.includeIndicators = true;
        
        let newreport = this.state.report;
        let company_info = newreport.company_info;
        let registration_info = newreport.registration_info;
        let business = newreport.business;
        let financials = newreport.financials;
        let management = newreport.management;
        let affiliations = newreport.affiliations;
        let contact_info = newreport.contact_info;
        let legal_records = newreport.legal_fillings;
        let credit_evaluation = newreport.credit_evaluation;
        let payment_behavior = newreport.payment_behavior;
        let shareholders = newreport.shareholding;
        
        console.log("LL",newreport)
        this.creditSafeService.searchCreditReport({...companyInfo}).then(data=>{             
            // this.setState({searchCreditSafeResults: data.companies})       
            if(this.state.coverage === "NonUS"){
                if(data.report === undefined) {
                    console.log("error",data);
                    this.toast.current.show({severity:'error', summary:'Import Failed !', detail: JSON.stringify(data?.details) + '\n From the API Try Importing Again.'});
                    this.setState({showLoader:false})
                    this.setState({importBtnDisable: false})                  
                    return null;
                }

                console.log("TOIMPORT",data.report)     
                //COMPANY INFO               
                company_info.registred_name = data.report?.companyIdentification?.basicInformation?.registeredCompanyName;
                company_info.trade_names  =  data.report?.additionalInformation?.misc?.tradeNames;                
                company_info.headquarters = this.fetchAddress(data.report?.contactInformation?.mainAddress);
                var contactAddress = data.report.companyIdentification?.basicInformation?.contactAddress;
                //contactAddress.country = data.report.companyIdentification?.basicInformation?.country;
                company_info.mailing_address = this.fetchAddress(contactAddress);
                sessionStorage.setItem('ses_company_info_'+this.state.report.id, JSON.stringify(company_info));
                newreport.company_info = company_info;    

                //Registration Info
                var legal_form = (data.report.companyIdentification?.basicInformation?.legalForm?.description !==undefined) ? JSON.parse(sessionStorage.getItem('api_legalForms')).filter((form)=> form.name.toLowerCase().trim() === data.report.companyIdentification.basicInformation?.legalForm?.description.toLowerCase())[0] : "";                  
                registration_info.legal_form = legal_form;
                var startDate = formatDate(data.report.companyIdentification?.basicInformation.operationsStartDate).split("-");
                registration_info.start_date = {year:startDate[0],month:startDate[1],day:startDate[2],type:"DATE"};              
                sessionStorage.setItem('ses_registration_info_'+this.state.report.id, JSON.stringify(registration_info));
                newreport.registration_info = registration_info;              

                //Business
              
                var CC_Branches = (data.report.additionalInformation?.branchOffices !==undefined) ? data.report.additionalInformation?.branchOffices : data.report.additionalInformation?.branches;
                console.log("CC",CC_Branches )
                var branch_arr = []
                if(CC_Branches !== undefined){
                    CC_Branches.forEach((branch,i) => {
                        branch_arr[i] = {address : this.fetchAddress(branch.address)}
                    });
                }
                business.branches = branch_arr;   
                business.line_of_business =  data.report?.companySummary?.mainActivity?.industrySector;
                business.staff_number =  data.report.otherInformation?.employeesInformation[0].numberOfEmployees;    
                business.main_product =  data.report?.companySummary?.mainActivity?.description;
                sessionStorage.setItem('ses_business_'+this.state.report.id, JSON.stringify(business));            
                newreport.business = business;

                //Clients


                //Contact Info
                var CC_Contacts = data.report?.contactInformation;                
                var websites_txt = ""
                if(CC_Contacts?.websites !== undefined){
                    CC_Contacts?.websites.forEach((contact,i) =>{                                 
                        websites_txt+=contact+",";
                    })                     
                }
                var phone_arr = []
                if(CC_Contacts?.mainAddress !== undefined)
                    phone_arr[0] = {country_code: "", number : CC_Contacts.mainAddress.telephone}
                
                if(CC_Contacts?.otherAddresses !== undefined){                   
                   CC_Contacts?.otherAddresses.forEach((contact,i) =>{                       
                        if(contact?.telephone !== undefined || contact?.telephone !==""){                               
                            phone_arr[(i + 1)] = {country_code: 0, number : contact.telephone}
                        }
                    })
                }
                contact_info.telephones = phone_arr;
                contact_info.websites = websites_txt.substring(0, websites_txt.length - 1);
                contact_info.faxes[0] = {number : data.report?.additionalInformation?.misc?.faxNumber};
                sessionStorage.setItem('ses_contact_info_'+this.state.report.id, JSON.stringify(contact_info));            
                newreport.contact_info = contact_info;

                //Financials               
                var CC_financials = data.report?.financialStatements;
                var financial_date;
                var yearly_financials = [];
                var CC_currency = "";
                if(CC_financials!== undefined){
                    CC_financials.forEach((yearly , i) =>{
                        financial_date = formatDate(yearly.yearEndDate).split("-");                         
                        console.log(financial_date,yearly);
                        yearly_financials[i] = {                            
                            financial_report : {
                                year : financial_date[0],
                                day: financial_date[2],
                                month: financial_date[1],
                                financial_bs : {
                                    intangible_assets: yearly.balanceSheet?.totalIntangibleAssets,
                                    other_assets : yearly.balanceSheet?.totalOtherFixedAssets,
                                    total_assets : yearly.balanceSheet?.balanceSheet?.totalAssets,
                                    inventory : yearly.balanceSheet?.totalInventories,
                                    net_receivables : yearly.balanceSheet?.totalReceivables,
                                    cash_and_bank: yearly.balanceSheet?.cash,
                                    other_current_assets: yearly.balanceSheet?.otherCurrentAssets,
                                    total_current_assets: yearly.balanceSheet?.totalCurrentAssets,
                                    total_current_liabilities: yearly.balanceSheet?.totalCurrentLiabilities,
                                    other_current_liabilities : yearly.balanceSheet?.miscellaneousLiabilities,
                                    account_payables : yearly.balanceSheet?.tradePayables,
                                    total_stockholders_equity: yearly.balanceSheet?.totalShareholdersEquity,
                                    total_liabilities: yearly.balanceSheet?.totalLiabilities,
                                    total_longterm_liabilities : yearly.balanceSheet?.totalLongTermLiabilities,
                                    property_plant_equipment_net : yearly.balanceSheet?.plantAndMachinery,
                                    longterm_investments : yearly.balanceSheet?.investments,
                                                                                                    
                                },
                                financial_pl:{
                                    revenue : yearly.profitAndLoss?.revenue,
                                    cost_of_revenue : yearly.profitAndLoss?.operatingCosts,
                                    operating_income : yearly.profitAndLoss?.operatingProfit,
                                    selling_general_and_admin_expenses : yearly.profitAndLoss?.wagesAndSalaries,
                                    total_operating_expenses : yearly.profitAndLoss?.financialExpenses,
                                    gross_profit : yearly.profitAndLoss?.profitAfterTax,
                                    earnings_per_share : yearly.profitAndLoss?.dividends,  
                                },
                                financial_ratio:{                                   
                                    profit_margin:yearly.ratios?.preTaxProfitMargin,
                                    return_on_equity:yearly.ratios?.returnOnCapitalEmployed,
                                    return_on_assets:yearly.ratios?.returnOnTotalAssetsEmployed,
                                    gross_margin:yearly.ratios?.salesOrNetWorkingCapital,
                                    current_ratio:yearly.ratios?.currentRatio,
                                    debt_to_asset_ratio:yearly.ratios?.currentDebtRatio,
                                    debt_to_equity_ratio:yearly.ratios?.totalDebtRatio
                                }

                            }
                        }
                    });
                }
                financials.currency = {code:CC_financials[0]?.currency}
                financials.yearly_financials = yearly_financials;  
                newreport.financials = financials;
                sessionStorage.setItem('ses_financials_'+this.state.report.id, JSON.stringify(financials));            

                /*var quarterly_financials = data.report.financialStatements?.quarterly_financials;
                //var yearly_financials = data.report.financialStatements?.quarterly_financials;

                if(quarterly_financials!== undefined){
                    quarterly_financials.forEach((quarterly , i) =>{
                        console.log("XX",i,quarterly)
                    });
                }
                */
                

               

                //Banks
                var CC_bankInfo = data.report?.additionalInformation?.bankingData;
                var bank_arr = []
                var banks_info = [];
                if(CC_bankInfo!== undefined){
                    CC_bankInfo.forEach((bank , i) =>{
                        if(bank?.bank !== undefined){
                            bank_arr[i]={
                                name : bank?.bank,    
                                loans_and_creditLines : [],
                                country: {name:""},                                                  
                            }
                        }
                    });
                }
           
                banks_info.banks = bank_arr;
                newreport.banks_info = banks_info;
                sessionStorage.setItem('ses_banks_info_'+this.state.report.id, JSON.stringify(banks_info));     


                //Insurance
                
                //Legal Records
                console.log("LR",legal_records);
                legal_records.ucc_files =  parseInt(data.report?.negativeInformation?.legalFilingSummary?.uccFilings) > 0 ? true : false;
                legal_records.bankruptcy =  data.report?.negativeInformation?.legalFilingSummary?.bankruptcy;
                legal_records.lawsuits =  parseInt(data.report?.negativeInformation?.legalFilingSummary?.lawsuits) > 0 ? true : false;
                legal_records.liens =  parseInt(data.report?.negativeInformation?.legalFilingSummary?.taxLienFilings) > 0 ? true : false;
                var taxLienDetails = ""
                if(legal_records.liens){                   
                    data.report?.negativeInformation?.taxLienDetails.forEach((tax,i) =>{                        
                        taxLienDetails+="Filed Date : "+ formatDate(tax.filedDate)+"\n";
                        taxLienDetails+="Filing Number : "+ tax.filingNumber+"\n";
                        taxLienDetails+="Filing Type : "+ tax.filingType+"\n";
                        taxLienDetails+="Jurisdiction : "+ tax.jurisdiction+"\n";
                        taxLienDetails+="Amount : "+ tax.currency+" "+tax.amount+"\n";
                        taxLienDetails+="Status : "+ tax.status+"\n--------------------------------\n";
                    })
                }
                legal_records.liens_comments = taxLienDetails;
                sessionStorage.setItem('ses_legal_records_'+this.state.report.id, JSON.stringify(legal_records));    
                newreport.legal_fillings = legal_records; 

                //Payment Behaviour
                var CC_monthly_trends = data.report.additionalInformation?.extendedPaymentData?.tpdMonthlyBalanceHistories;
                var monthly_trends = [];
                var month_date = ""
                if(CC_monthly_trends !== undefined){
                    CC_monthly_trends.forEach((mtrend,i) => {
                        month_date = formatDate(mtrend.date).split("-");                                               
                        console.log("CC",JSON.parse(sessionStorage.getItem('ses_currency').filter((c)=> c.code === mtrend.currency))[0]);  
                        monthly_trends[0]= {year : month_date[0], month: month_date[1],is_monthly : true, dbt : 0, balance: mtrend.totalBalance, balance_currency : ""}
                    });
                }   
                /*
                var CC_paymentData = data.report.paymentData;
                var CC_paymentDataMonthly = CC_paymentData?.lastMonthlyBalanceHistory;
                var paymentMonthly_arr = [];
                var dateArr = [];
                if(CC_paymentDataMonthly !== undefined){
                    CC_paymentDataMonthly.forEach((payment,i)=>{
                        console.log("PM",payment);
                        formatDate
                        paymentMonthly_arr[i] = {
                            balance : payment?.balance,
                            balance_currency : {code : CC_paymentData?.balance.codeCountryCode},
                            is_monthly : true,
                            month:

                        }
                    });
                } */


                payment_behavior.payments = ""

                //Press
                //Registration Changes
                //Suppliers

                //Management
                var CC_Directors = data.report?.directors?.currentDirectors;                        
                var directors_arr = []
                if(CC_Directors !== undefined){
                    CC_Directors.forEach((director,i)=>{
                        directors_arr[i] = { 
                            id_number: director.id, 
                            full_name : director.name, 
                            position: director.positions[0].positionName,
                            address : this.fetchAddress(director.address)                            
                        }                      
                    });
                }
                //console.log("MM",directors_arr)
                management.managers = directors_arr;
                sessionStorage.setItem('ses_management_'+this.state.report.id, JSON.stringify(management));            
                newreport.management = management;



                //Affiliations
                var CC_Affiliated = data.report?.groupStructure?.affiliatedCompanies;            
                var affiliates_arr = [];
                var amongOthers = false;
                if(CC_Affiliated !== undefined){
                    CC_Affiliated.forEach((affiliate,i)=>{
                        if( i <= 9 ){
                            affiliates_arr[i] = {
                                registration_id : affiliate.registrationNumber,
                                company_name : affiliate.name,
                                type: {id : 3, name :"Affiliated company"},
                                address : this.fetchAddress({
                                    country : affiliate.country, 
                                    simpleValue: affiliate.address?.simpleValue,
                                    postalCode: affiliate.address?.postalCode
                                })
                            }
                        }else{
                            amongOthers = true;                          
                            return true;
                        }
                    });
                }
                //console.log(affiliates_arr)
                affiliations.affiliated_companies = affiliates_arr;
                if(amongOthers){
                    affiliations.comments = "Among Others"; 
                }


                //Credit Evaluation
                credit_evaluation.credit_risk_analysis = data.report?.creditScore?.currentCreditRating?.assessment;
                sessionStorage.setItem('ses_credit_evaluation_'+this.state.report.id, JSON.stringify(credit_evaluation));     
                newreport.credit_evaluation = credit_evaluation;
                                           

                //Shareholdings / Shareholders
                var CC_shareholders = data.report?.shareCapitalStructure?.shareHolders;
                console.log(CC_shareholders);
                
                var shareholders_arr = []
                if(CC_shareholders !== undefined){
                    CC_shareholders.forEach((shareholder,i)=>{
                        shareholders_arr[i] = {
                            shareholder_name : shareholder?.name,
                            shareholder_type : shareholder?.shareholderType,
                            shares_percentage :  shareholder?.percentSharesHeld,
                            registration_id : shareholder?.id                            
                        }
                    });
                }
                shareholders.shareholders = shareholders_arr;
                sessionStorage.setItem('ses_shareholding_'+this.state.report.id, JSON.stringify(shareholders));    
                newreport.shareholding = shareholders;

                this.setState({report : newreport});
               
            }else{
                console.log("CR",data)      
            }  
            this.setState({importBtnDisable: false})
            this.setState({showLoader:false})
            this.toast.current.show({severity:'success', summary:'Updated', detail:'Creditsafe Report Imported.'});             
            
            let logData = {"log_details" : "Imported Company : " + company_info?.registred_name }
            this.creditSafeService.logApiRequest(logData).then(data=>{             
                console.log("LOGGINGS");
            });
            this.props.closeDialog();
        })  
       
    }    
    importAddressCountry(rowData){
        return (<React.Fragment>{rowData.address['street'] + "," + rowData.address['city'] +" " + rowData.address['simpleValue'] + " " + rowData.address['postCode']} </React.Fragment> );
    }
    importAddressTemplate(rowData){      
        return (<React.Fragment>{rowData.address['street'] + "," + rowData.address['city'] +" " + rowData.address['simpleValue'] + " " + rowData.address['postCode']}</React.Fragment>);
    }
    importCreditSafeBodyTemplate(rowData){
        return (<React.Fragment><Button icon="pi pi-upload" label = "Import"  onClick={(e) => this.importReportFromCreditSafe(rowData, e)} disabled={this.state.importBtnDisable}/></React.Fragment>);
    }
    createField(fields){       
        var field_arr;
        var fields_arr2 = [];
        var excludeFields = ["searchMode","officeType","exact","status","incTradingNames","incTradingAddresses"]
        if(fields === undefined) return [];

        fields.forEach((field,i)=>{
            field_arr = Object.keys(field);
            if(field_arr.length >=2){                    
                for (let index = 0; index < field_arr.length; ++index) {
                    if(!excludeFields.includes(field_arr[index]) && !fields_arr2.includes(field_arr[index]))
                        fields_arr2.push(field_arr[index])
                }
            } else {
                if(!excludeFields.includes(field_arr) && !fields_arr2.includes(field_arr[0]))
                    fields_arr2.push(field_arr[0])
            }                
        });
        return fields_arr2
    }
    /** Render Import Dialog */
    renderImport(){
        var React = require('react');
        var createReactClass  = require('create-react-class');
        var searchFields = this.createField(this.state.searchFields);              
        var showFields = (searchFields !== undefined && searchFields.length > 0) ? true : false;  
        var handleSearchCompany = this.handleSearchCompany;
        var searchValue = this.state.searchCompany;
        const disableBtn = this.state.disableBtn;        
        const showLoader = this.state.showLoader;        
        var countries = (sessionStorage.getItem('creditsafe_countries') ? sessionStorage.getItem('creditsafe_countries') : this.state.creditsafe_countries ) 
        if(countries.length === 0) {
            console.log("CCC",countries)
            return "";
        }
        countries = JSON.parse(countries).map((row,i)=>({name: row.countryName,code : row.countryIso2}));   

        var FieldChildComponent = createReactClass({           
            render: function() {               
                var field = this.props.name;                            
                return (
                    <>
                    <div className="col-sm-1">
                        <label className="form-field-bold">{<Translation>{ t => <span>{t(field)} </span>}</Translation>}:</label>
                    </div> 
                    <div className="col-sm-2">
                        <input type = "text" value={searchValue[field]} name={field} onChange={handleSearchCompany} className="form-control form-control-sm" />
                    </div>  
                    </>                              
                );              
            }
        });                     
        return(          
          <div>
            <div className="form-group col-12">
            <div className="row"><Toast ref={this.toast} />
                <div className="col-4"></div>
                <div className="col-4"></div>
                <div className="col-4"></div>
            </div>
            <div className='row'>    
                   <div className='col-4'><h6>Search {(this.state.coverage === "US") ? "US" :""} Companies<span className='required'>*</span> are required</h6></div>
                   <div className='col-8'><strong>{this.state.searchStatusTxt}</strong></div>
              </div>
              <div className="row searchHeaders">
                <div className="col-sm-1">
                      <label className="form-field-bold">Countries<span className='required'>*</span></label>
                  </div>
                  <div className="col-sm-2 multiSelect" >
                    <MultiSelect value = {this.state.selectedCountries}  options={countries}
                                    onChange={(e) => this.updateCountrySearchFilter('selectedCountries',e)} filter="true"                                     
                                    optionLabel="name" placeholder="Select Countries" filterBy="name"/>
                  </div>
                  <div className="col-sm-12">&nbsp;</div>
                    {showFields && searchFields.map((item,i) => (                                  
                            <FieldChildComponent key={i} name={item}/> 
                    ))}                                      
                  <div className="col-sm-11">{showLoader && <ProgressBar mode="indeterminate" style={{ height: '6px',marginTop:'20px' }}/> }</div>
                  <div className="col-sm-1">
                       {disableBtn
                         ?  <Button icon="pi pi-search" label = "Search" disabled/>
                         : <Button icon="pi pi-search" label = "Search"  onClick={this.submitSearchCompany} />
                         } 
                  </div>
              </div>
            </div>
            <div className="col-12" id="dataTable">
            <DataTable ref={(el) => this.dt = el}
                  value={this.state.searchCreditSafeResults}
                  className="p-datatable-striped"
                  rowHover
                  paginator rows={10}
                  emptyMessage="No Company Found"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[10,25,50]}
                  globalFilter={this.state.globalFilter}>
                  <Column field="country" header={<Translation>{ t => <span>{t('country')} </span>}</Translation>}/>
                  <Column field="name" header={<Translation>{ t => <span>{t('COMPANY_NAME')} </span>}</Translation>}/>
                  <Column field="safeNo" header={<Translation>{ t => <span>{t('SafeNo')} </span>}</Translation>}/>               
                  <Column field="address" body = {this.importAddressTemplate} header={<Translation>{ t => <span>{t('Address')} </span>}</Translation>}/>    
                  <Column field="dateOfLatestAccounts" header={<Translation>{ t => <span>{t('DateOfLatestAccounts')} </span>}</Translation>}/>          
                  <Column field="status" header={<Translation>{ t => <span>{t('CompanyStatus')} </span>}</Translation>}/>                       
                  <Column body = {this.importCreditSafeBodyTemplate}  header={<Translation>{ t => <span>{t('Action')} </span>}</Translation>}/>        
              </DataTable>
            </div>
          </div>
        )
    } /** End Render Dialog */
    renderImportUS(){
      const disableBtn = false;// this.state.disableBtn;
      return(
        
        <div>
          <div className="form-group col-12"> 
          <div className='row'>
                 <div className='col-4'><h6>Search US Companies<span className='required'>*</span> are required</h6></div>
                 <div className='col-8'><strong>{this.state.searchStatusTxt}</strong></div>
            </div>
            <div className="row searchHeaders">
                <div className="col-sm-1">
                    <label className="form-field-bold">Company Name:<span className='required'>*</span></label>
                </div>
                <div className="col-sm-11">
                    <input type = "text" value={this.searchValue} name="name" onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div>

                <div className="col-sm-1">
                    <label className="form-field-bold">ID:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="id" onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                    <label className="form-field-bold">Reg No:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="regNo"   onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                    <label className="form-field-bold">Vat No:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="vatNo"   onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                    <label className="form-field-bold">Safe No:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="safeNo"   onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                    <label className="form-field-bold">Website:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="website"  onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                    <label className="form-field-bold">Post Code:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="postCode"   onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                    <label className="form-field-bold">Province:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="province"   onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                    <label className="form-field-bold">City:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="city" onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-1">
                    <label className="form-field-bold">Street:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="street" onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div>
                {/*<div className="col-sm-1">
                    <label className="form-field-bold">Inc Trading Addresses:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="inc_trading_address" onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div> */}
                <div className="col-sm-1">
                    <label className="form-field-bold">Name:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="name" onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div> {/*
                <div className="col-sm-1">
                    <label className="form-field-bold">Inc Trading Names:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="inc_trading_names" onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div> */}
                <div className="col-sm-1">
                    <label className="form-field-bold">Phone No:<span className='required'></span></label>
                </div>
                <div className="col-sm-1">
                    <input type = "text" value={this.searchValue} name="phone_no" onChange={this.handleSearchCompany} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-11">&nbsp;</div>
                <div className="col-sm-1">
                     {disableBtn
                       ?  <Button icon="pi pi-search" label = "Search" disabled/>
                       : <Button icon="pi pi-search" label = "Search"  onClick={this.submitSearchCompany} />
                       } 
                </div>
            </div>
          </div>
          <div className="col-12" id="dataTable">
          <DataTable ref={(el) => this.dt = el}
                value={this.state.searchCreditSafeResults}
                className="p-datatable-striped"
                rowHover
                paginator rows={10}
                emptyMessage="No Company Found"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10,25,50]}
                globalFilter={this.state.globalFilter}>
                <Column field="country" header={<Translation>{ t => <span>{t('country')} </span>}</Translation>}/>
                <Column field="name" header={<Translation>{ t => <span>{t('COMPANY_NAME')} </span>}</Translation>}/>
                <Column field="safeNo" header={<Translation>{ t => <span>{t('SafeNo')} </span>}</Translation>}/>               
                <Column field="address" body = {this.importAddressTemplate} header={<Translation>{ t => <span>{t('Address')} </span>}</Translation>}/>    
                <Column field="dateOfLatestAccounts" header={<Translation>{ t => <span>{t('DateOfLatestAccounts')} </span>}</Translation>}/>          
                <Column field="status" header={<Translation>{ t => <span>{t('CompanyStatus')} </span>}</Translation>}/>                       
                <Column body = {this.importCreditSafeBodyTemplate}  header={<Translation>{ t => <span>{t('Action')} </span>}</Translation>}/>        
            </DataTable>
          </div>
        </div>
      )
    }
}

export default Creditsafe
