import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import UserService from '../service/UserService';
import { classNames } from 'primereact/utils';

class Users extends Component {
  constructor(props){
    super(props);
    this.state = {
      users: [],
      loading: true,
      selectedUser: {
        id: 0,
        username: ''
      },
      selectedisStaff : null,
      selectedUserCompany:{
        id: 0,
        legal_name: ''
      },
      button:{
        label:'Add',
        icon: 'pi pi-plus'
      },
      booleanOption:[
        {label: 'Yes', value: 'true'},
        {label: 'No', value: 'false'},     
      ],      
      lazyParams: {
        first: 0,
        rows: 50,
        page: 1,
        filters: {
          'username': { value: '', matchMode: 'contains' },
          'user.is_staff' : {value : '', matchMode : 'equal'}, 
          'user.email' : {value : '', matchMode : 'contains'},    
          'user.company.legal_name': {value : '', matchMode : 'contains'}      
        }
      }
    };
    this.userService = new UserService();
    this.usernameBodyTemplate = this.usernameBodyTemplate.bind(this);
    this.lastnameBodyTemplate = this.lastnameBodyTemplate.bind(this);
    this.firstnameBodyTemplate = this.firstnameBodyTemplate.bind(this);
    this.isStaffBodyTemplate = this.isStaffBodyTemplate.bind(this);
    this.emailBodyTemplate = this.emailBodyTemplate.bind(this);
    this.userCompanyNameBodyTemplate = this.userCompanyNameBodyTemplate.bind(this);
    this.handleAddNewUser = this.handleAddNewUser.bind(this);    
    this.loadUsersLazy = this.loadUsersLazy.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  componentDidMount(){  
    this.loadUsersLazy();
  }

  handleAddNewUser(){
    let userData = {}
    userData.is_staff = false;
    userData.is_admin = false;
    userData.user = {}
    this.props.addUserTab(userData)
  }

  handleSelectedUser(rowData){
    this.props.addUserTab(rowData)
  }

  usernameBodyTemplate(rowData){
    let user_id = rowData.id;
    return (
        <React.Fragment>
            <a href="#" onClick={() => this.handleSelectedUser(rowData)} id={user_id} >{rowData.username}</a>
        </React.Fragment>
    );
  }

  lastnameBodyTemplate(rowData){
    if(rowData.user){
      return (
          <React.Fragment>
              {rowData.user.first_name}
          </React.Fragment>
      );
    }
  }

  firstnameBodyTemplate(rowData){
    if(rowData.user){
      return (
          <React.Fragment>
              {rowData.user.last_name}
          </React.Fragment>
      );
    }
  }

  isStaffBodyTemplate(rowData){
    //if(rowData.is_staff){
      return (
          <React.Fragment>
             <i className={classNames('pi', {'true-icon pi-check-circle': rowData.is_staff, 'false-icon pi-times-circle': !rowData.is_staff})}></i>
          </React.Fragment>
          
      );
  }

  emailBodyTemplate(rowData){
    if(rowData.user){
      return (
          <React.Fragment>
              {rowData.user.email}
          </React.Fragment>
      );
    }
  }

  userCompanyNameBodyTemplate(rowData){
    if(rowData.user && rowData.user.company){
      return (
          <React.Fragment>
              {rowData.user.company.legal_name}
          </React.Fragment>
      );
    }

  }
  loadUsersLazy(){   
    this.setState({loading: true})
    let searchBody = {lazy: this.state.lazyParams }

   // if(!sessionStorage.getItem('AdminUsers')){
      this.userService.getLazyUsers(searchBody).then(data => {     
        let filter_users = data.users.filter(user=> user.user['user_deleted'] === 0)
        this.setState({users: filter_users, totalRecords: filter_users.length, loading: false});
        //sessionStorage.setItem('AdminUsers',JSON.stringify(data.users))
        //sessionStorage.setItem('UsersTotalRecords',data.total_records)
      })
  //  }else{
  /*   let ses_users = JSON.parse(sessionStorage.getItem('AdminUsers'));
      let filters = searchBody.lazy.filters;
      let totalRecords =  parseInt(sessionStorage.getItem('UsersTotalRecords'));
     
      if(filters['username']){
        if(filters['username'].value !="")
        ses_users =  ses_users.filter(user => (user.username).includes(filters['username'].value))
      }
      if(filters['user.is_staff']){
        if(filters['user.is_staff'].value !='')
          ses_users = ses_users.filter(user => String(user.is_staff) == String(filters['user.is_staff'].value))
      }
      if(filters['user.email']){
        if(filters['user.email'].value !='')
          ses_users = ses_users.filter(user => String(user.user.email).includes(filters['user.email'].value))
      }
     
      if(filters['user.company.legal_name']){
         if(filters['user.company.legal_name'].value !=''){
          ses_users = ses_users.filter(user => (user.user.company));
          ses_users = ses_users.filter(user => String(user.user.company.legal_name).includes(filters['user.company.legal_name'].value));
         }
      }
      
      totalRecords = ses_users.length;
      this.setState({users : ses_users,totalRecords : totalRecords, loading:false})  
    } */

  }

  onFilter(event) {
    let is_staff = ""
    if(event.is_staff){
      if(String(event.is_staff)!=="undefined")
        is_staff = event.is_staff;
    } 

    this.state.lazyParams.filters['user.is_staff'].value = is_staff;
    this.state.selectedisStaff = is_staff;
    event['first'] = 0;
    let lazyParams = { ...this.state.lazyParams, ...event };
    this.setState({ lazyParams },function(){
        this.loadUsersLazy();
    });
 
  }
  onPage(event) {
    this.setState({loading: true});
    let lazyParams = { ...this.state.lazyParams, ...event };
    this.setState({ lazyParams },function(){
      this.loadUsersLazy();
    });
  }
  onSort(event) {
    this.setState({loading: true});
    let lazyParams = { ...this.state.lazyParams, ...event };
    this.setState({ lazyParams },function(){
      this.loadUsersLazy();
    });
  }



  render(){
  
    const filterIsStaff = <Dropdown value={this.state.selectedisStaff} options={this.state.booleanOption} onChange={(e) => this.onFilter({is_staff:e.value})}  placeholder="Select Staff" showClear />
 
   
    return (
      <div className="condiv">
        <div className = "row">
          <div className="col-sm-9" style={{'margin': '5px'}}>
            <Button onClick={(e) => this.handleAddNewUser(e)} label="New User" className="p-button-sm" icon="pi pi-plus"/>
          </div>
          <div className="col-12" id="dataTable">
          <DataTable lazy ref={(el) => this.dt = el}
                value={this.state.users} loading = {this.state.loading}
                totalRecords={this.state.totalRecords} onPage={this.onPage}
                first={this.state.lazyParams.first} onSort={this.onSort}
                sortField={this.state.lazyParams.sortField} sortOrder={this.state.lazyParams.sortOrder}
                className="p-datatable-striped"
                onFilter={this.onFilter}
                filters={this.state.lazyParams.filters} 
                dataKey="id"
                rowHover
                paginator rows={this.state.lazyParams.rows}
                emptyMessage="No Users found"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10,25,50]}>
            
                <Column sortField="username" filterField="username" field="username" header="User Name" body={this.usernameBodyTemplate} sortable filter filterPlaceholder="Search by username"/>               
                <Column header="is Staff?" filterField="is_staff" showFilterMenu={false} filterMenuStyle={{ width: '14rem'}} style={{ minWidth: '14rem', textAlign:'center'}} body={this.isStaffBodyTemplate}
                        sortable filter filterElement={filterIsStaff } />
                <Column sortField="user.email" filterField="user.email" field="user.email" header="Email" body={this.emailBodyTemplate} sortable filter filterPlaceholder="Search by email"/>
                <Column sortField="user.company.legal_name" filterField="user.company.legal_name" field="user.company.legal_name" header="Company Name" body={this.userCompanyNameBodyTemplate} sortable filter filterPlaceholder="Search by company Name"/>
            </DataTable>
          </div>
        </div>
      </div>
    )
  }
}

export default Users
